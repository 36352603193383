export const color = {
    bg_login: '#DEDEDE',
    rizz_main: '#D41B24',//D41B24
    rizz_secondary: '#24231D',
    rizz_primary: '#D41B24',
    rizz_ems: '#EE220C',
    rizz_process: '#FF9300',
    rizz_complete: '#60D936',

    dplus_primary: '#0783c1',
    dplus_main: '#3782BC',
    dplus_secondary: '#24231D',

    focus_waitpay: '#FF9300',
    focus_waittocheck: '#EE220C',
    focus_successpay: '#FFFB01',
    focus_complete: '#60D936'
}