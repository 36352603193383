import React, { Component } from "react";
import { observer } from "mobx-react";
import QRCode from "qrcode.react";
import autobind from "autobind-decorator";
import { decryptParameter } from "../../../functions/functions";
import { POST, GET_COVER_SHEET, FOCUS_GETREPORT } from "../../../service_focus";
import { Spin, Modal } from "antd";
import { image, columnTableReport } from "../../../resource";
import { Button } from "../../../components/Button";
import Barcode from "react-barcode";
const fn = require("../../../functions/functions");
@observer
class FocusBillShippingScene extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      print: false,
      data_section1: [],
      data_section2: [],
      page: [],
    };
  }

  componentDidMount() {
    window.onbeforeprint = () => {
      this.setState({ print: true });
    };

    window.onafterprint = () => {
      this.setState({ print: false });
    };

    if (this.props.location.state) {
      this.onFetchReport();
    }
  }

  @autobind
  async onFetchReport() {
    try {
      const { claim_id } = JSON.parse(
        decryptParameter(this.props.location.state.id)
      );

      const res = await POST(FOCUS_GETREPORT, { no_claim: claim_id });
      if (res.success) {
        this.setState({ loading: false, data: res.result });
      }
    } catch (error) {
      Modal.error({ title: error.message });
    }
  }

  render() {
    const { loading, data, print } = this.state;

    if (loading) {
      return (
        <div className="example">
          <Spin />
        </div>
      );
    }

    return (
      <div className={"body-print"}>
        {data.map((el, i) => {

          const { no_claim, tracking, sorting_code, name, address, tel, nameproduct, brandmobile, modelmobile,shipping_type } = el
          return (
            <div className={"container-bill"} key={i}>
              <div className={"row"} style={{ fontSize: "0.4cm", marginTop: 50 }}>
                <img src={image.focus_brand_black} className={'image-logo'} />
                <div className={'column'} style={{ marginLeft: '0.5cm' }}>
                  <font style={{ fontSize: "0.4cm" }}>บริษัท ดีพลัสอินเตอร์เทรด จำกัด</font>
                  <font style={{ fontSize: "0.4cm" }}>123/20-22 ถ.นนทรี แขวงช่องนนทรี</font>
                  <font style={{ fontSize: "0.4cm" }}>เขตยานนาวา กทม 10120 โทร: 02-294484-8</font>
                </div>
                <div style={{ marginTop: 0,marginLeft:'2cm' }}>
                  <div style={{ alignSelf: "flex-end" }}>
                    <QRCode value={`${no_claim}`} size={120} />
                  </div>
                </div>
              </div>
              <div className={"row"} style={{ fontSize: "0.4cm", marginTop: 30 }}>
                {shipping_type === "SYSTEM" ? (
                  <div className={"column barcode-jt"}>
                    <div style={{ textAlign: "center" }}>
                      <img
                        src={image.logo_jt}
                        style={{ height: "1.5cm", width: "151px" }}
                      />
                    </div>
                    <div>
                      <Barcode value={tracking} />
                      <p style={{ textAlign: "center" }}>{sorting_code}</p>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div style={{ marginTop: 30 }} className={'content-section1'}>
                  <font>{`ชื่อที่อยู่/ผู้รับ`}</font>
                  <font>{`จัดส่งคุณ: ${name}`}</font>
                  <font>{`ที่อยู่: ${address}`}</font>
                  <font>{`เบอร์โทรศัพท์: ${tel}`}</font>
                  <font>{`เลขเอกสารการเคลม: ${no_claim}`}</font>
                  <font>{`${nameproduct} ${brandmobile} ${modelmobile}`}</font>
                </div>
              </div>
            </div>
          );
        })}

        {!print && (
          <div
            style={{
              textAlign: "center",
              marginTop: "1cm",
              marginBottom: "1cm",
            }}
          >
            <Button
              title={"ยกเลิก"}
              buttonStyle={{ marginRight: "12px" }}
              type={"secondary"}
              onPress={() => this.props.history.goBack()}
            />
            <Button
              title={"พิมพ์"}
              onPress={() =>
                this.setState({ print: true }, () => window.print())
              }
            />
          </div>
        )}
      </div>
    );
  }
}

export default FocusBillShippingScene;
