import React, { Component } from 'react'
import { observer } from 'mobx-react';
import { Table, Input } from 'antd';
import { columnTableHistory } from '../../../resource';
import autobind from 'autobind-decorator';
import { GET, RIZZ_GETHISTORY } from '../../../service';
import dayjs from 'dayjs';

let defaultData = []

@observer
class HistoryScene extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoadingData: false,
            searchText: '',
        }
    }

    componentDidMount() {
        //     this.onFetchHistory()
    }

    @autobind
    async onFetchHistory() {
        try {
            const res = await GET(RIZZ_GETHISTORY);
            let data = [];
            if (res.success) {
                console.log(res)
                data = res.respon.map((el, i) => {
                    const { product, customer, data } = el;
                    return {
                        key: i + 1,
                        ...el,
                        no: i + 1,
                        name: customer.name,
                        telephone: customer.telephone,
                        product_name: product.product_name,
                        product_register: product.product_id,
                        last_date: dayjs(el.create_at).add(543, 'y').format('DD/MM/YYYY'),
                        data
                    }
                })
            }

            defaultData = data;

            this.setState({ isLoadingData: false, data })
        } catch (error) {
            console.log(error)
            this.setState({ isLoadingData: false })
        }
    }

    @autobind
    onChange(e, ref) {
        this.setState({ [ref]: e })
    }

    @autobind
    onKeyPressSearch(event) {
        if (event.key === 'Enter') {
            this.onSearch()
        }
    }

    @autobind
    onSearch() {
        const { searchText } = this.state;
        if (!searchText) {
            return this.setState({ data: defaultData })
        }

        const d = defaultData.filter(el => {
            return Object.values(el).some(val => {
                return val ? val.toString().toLowerCase().includes(searchText.toLowerCase()) : false
            })
        })

        this.setState({ data: d })
    }


    render() {
        const { data, isLoadingData, searchText } = this.state;

        return (
            <div>
                <div className={'column'}>
                    {/*###################### HEAD ######################*/}
                    <div className={'row-between'}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', alignItems: 'center' }}>
                            <Input.Search
                                style={{ width: '20vw', borderRadius: '10vw', paddingLeft: '1vw' }}
                                placeholder={'ค้นหาข้อมูล'}
                                value={searchText}
                                onKeyPress={e => this.onKeyPressSearch(e)}
                                onSearch={() => this.onSearch()}
                                onChange={e => this.onChange(e.target.value, 'searchText')}
                            />
                        </div>
                    </div>

                    {/*###################### TABLE ######################*/}
                    <Table
                        title={() => <h2>ประวัติการเคลม</h2>}
                        // rowSelection={rowSelection}
                        columns={columnTableHistory}
                        rowClassName={(record, i) => i % 2 === 1 ? 'even' : ''}
                        dataSource={data}
                        expandedRowRender={record => this.renderExpand(record)}
                        bordered
                        pagination={{ pageSize: 15 }}
                        loading={isLoadingData}
                        size="small"
                    />
                </div>
            </div>
        )
    }


    renderExpand(record) {
        return record.data.map((el, i) => {
            return <div style={{ padding: '1vw', display: 'flex', alignItems: 'center' }} key={`${el}${i}`}>
                <img src={el.claim_image} style={{ width: 100, height: 100 }} className={'contain'} />
                <div style={{ marginLeft: '1vw' }}>
                    <div style={{ display: 'flex', marginBottom: '-12px' }}>
                        <p style={{ margin: 0, width: '100px' }} >เลขที่การเคลม</p>
                        <p>{el.claim_id}</p>
                    </div>
                    <div style={{ display: 'flex', marginBottom: '-12px' }}>
                        <p style={{ margin: 0, width: '100px' }} >วันที่แจ้งเคลม</p>
                        <p>{el.create_at}</p>
                    </div>
                    <div style={{ display: 'flex', marginBottom: '-12px' }}>
                        <p style={{ margin: 0, width: '100px' }} >อาการเสีย</p>
                        <p>{el.description}</p>
                    </div>
                </div>
            </div>
        })
    }
}

export default HistoryScene