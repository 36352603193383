import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Form, Icon, Upload, Input, Row, Col, Button as BT, DatePicker, Spin, Modal, message } from 'antd'
import autobind from 'autobind-decorator';
import moment from 'moment'
import { GET, RIZZ_GETUSERALL, UPLOAD, RIZZ_REGIS_WARRANTY } from '../../../service'
import { BtnGoback } from '../../../components/Button'
const fn = require('../../../functions/functions')

@Form.create({ name: 'RegisterWarranty' })
@observer
class RegisterWarranty extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            product_name: '',
            register_date: moment(new Date()),
            imageUrl: '',
            name: '',
            telephone: '',
            lineid_colect: '',
            warranty_month: 12,
            checkNick: false,
            error_hidden: true,
            line_error: 'พบข้อมูลผู้ใช้',
            error_color: 'red'


        }
    }


    componentDidMount() {
        this.getUser()
    }

    handleChange = info => {
        console.log(info.file.originFileObj)
        fn.getBase64(info.file.originFileObj, imageUrl =>
            this.setState({
                fileProduct: info.file.originFileObj,
                imageUrl,
                loading: false,
            }),
        );

    };
    handleChangeSlip = info => {
        console.log(info.file.originFileObj)
        fn.getBase64(info.file.originFileObj, imageUrlSlip =>
            this.setState({
                fileSlip: info.file.originFileObj,
                imageUrlSlip,
                loading: false,
                warranty_month: 14
            }),
        );
    };
    @autobind
    async getUser() {
        try {
            const result = await GET(RIZZ_GETUSERALL)
            if (result.success) {
                console.log(result.result)
                this.setState({
                    loading: false,
                    line: result.result,

                })

            }
        } catch (error) {
            // <ModelError title={'ระบบขัดคล่อง'} detail={error}></ModelError>
            Modal.error({
                title: 'ระบบขัดคล่อง',
                content: { error },

            });
        }
    }
    @autobind
    async createWarranty() {
        this.setState({
            loading: true
        })
        const { fileProduct, fileSlip, line_id, name, telephone, register_date, product_name, warranty_month } = this.state
        let arr = { fileProduct, fileSlip, line_id, name, telephone, register_date, product_name, warranty_month }
        let formdata = fn.appen_register(arr)
        try {
            let res = await UPLOAD(RIZZ_REGIS_WARRANTY, formdata)
            if (res.success) {

                this.setState({
                    loading: false
                })
                this.clear()
                Modal.success({
                    title: 'บันทึกข้อมูลสำเร็จ',
                    content: `ชุดเอกสารลงทะเบียน ${res.result}`,

                });

            }


            console.log(res)
        } catch (error) {
            console.log(error)
            Modal.error({
                title: 'ระบบขัดคล่อง',
                content: { error },
            });
        }
    }

    @autobind
    onChange(e, ref) {
        this.setState({
            [ref]: e,

        })
        if (ref === 'line_id') {
            this.setState({
                lineid_colect: '',
                error_hidden: true,

            })
        }
        console.log(ref)
    }

    @autobind
    onKeyPressSearch(event) {
        if (event.key === 'Enter') {
            this.onSearch()
        }
    }
    @autobind
    onSearch() {
        const { line_id } = this.state
        const item = this.state.line.find(el => el.userId === line_id);
        if (item) {
            this.setState({

                lineid_colect: 'check-circle',
                lineid_colect_color: 'certain-category-icon-colect',
                error_hidden: false,
                error_color: 'green'
            })
        }
        else {
            this.setState({
                lineid_colect: 'close-circle',
                lineid_colect_color: 'certain-category-icon-uncolect',
                error_hidden: false,
                error_color: 'red'
            })
        }
    }
    @autobind
    clear() {

        this.setState({
            line_id: '',
            product_name: '',
            register_date: moment(new Date()),
            imageUrl: '',
            imageUrlSlip: '',
            name: '',
            telephone: '',
            lineid_colect: '',
            warranty_month: 12,
            error_hidden: true,
        })
    }
    @autobind
    phonValidate() {
        fn.phonNumberValidate(this.state.telephone)
    }
    @autobind
    goBack() {
        this.props.history.goBack()

    }
    @autobind
    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (!fn.phonNumberValidate(this.state.telephone)) {
                    message.error('เบอร์โทรของท่านไม่ถูกต้อง');
                }
                else {

                    this.createWarranty()
                }


            }
            else {
                console.log('error')
            }
        });
    }


    render() {
        const { error_hidden, line_error, loading, product_name, register_date, imageUrl, name, line_id, telephone, imageUrlSlip, lineid_colect, lineid_colect_color, warranty_month } = this.state

        const uploadButton = (
            <div>
                <Icon style={{ width: '6cm', height: '7cm' }} type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        if (loading) {
            return <div className="example" style={{ width: '100%' }}>
                <Spin />
            </div>
        }
        const { getFieldDecorator } = this.props.form;
        getFieldDecorator('RegisterWarranty', { initialValue: [] })
        return (
            <Form labelCol={{ span: 5 }} wrapperCol={{ span: 12 }} onSubmit={this.handleSubmit}>
                <div>
                    <BtnGoback title={'กลับ'} onClick={() => this.goBack()}  ></BtnGoback>

                    <Row>

                        <Col span={10} push={14}>
                            <div className={'border-claim'} style={{ margin: 15, marginBottom: 30 }}>
                                <div className={'column-claim-cr'}  >
                                    <font style={{ fontSize: '0.4cm' }}>สินค้าลงทะเบียนรับประกัน</font>

                                </div>
                                <div className={'column-claim-cr'} style={{ marginTop: -10 }}  >

                                    <Form.Item label="ชื่อสินค้า">
                                        {getFieldDecorator('product_name', {
                                            rules: [{ required: true, message: 'กรุณาใส่ชื่อสินค้า' }],
                                        })(<Input style={{ width: '95%', marginTop: -20 }} defaultValue={product_name}
                                            value={product_name}
                                            onChange={e => this.onChange(e.target.value, 'product_name')}

                                        />)}
                                    </Form.Item>

                                </div>
                                <div className={'column-claim-cr'} style={{ marginTop: -20 }} >
                                    <font style={{ fontSize: '0.4cm' }}>วันที่ซื้อสินค้า</font>

                                    <DatePicker style={{ width: '80%', marginTop: 10 }}
                                        placeholder={'กรุณาเลือกวันจัดส่ง'}
                                        value={register_date}
                                        format={'DD/MM/YYYY'}
                                        onChange={(date) => this.onChange(date, 'register_date')}
                                    />


                                </div>
                                <div className={'column-claim-cr'} >
                                    <font style={{ fontSize: '0.4cm' }}>รูปภาพสินค้า</font>

                                </div>
                                <div className={'column-claim-cr'} style={{ justifyContent: 'center', alignItems: 'center' }}>

                                    <Form.Item >
                                        {getFieldDecorator('imageUrl', {
                                            rules: [{ required: true, message: 'รูปภาพสินค้า' }],
                                        })(<Upload
                                            name="avatar"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            onChange={this.handleChange}
                                        >
                                            {imageUrl ? <img src={imageUrl} alt="new" style={{ width: '6cm', height: '7cm' }} /> : uploadButton}
                                        </Upload>)}
                                    </Form.Item>

                                </div>

                            </div>

                            <div style={{ flex: 1, display: 'flex', alignItems: 'flex-end', justifyContent: 'center', margin: 15 }}>
                                <BT style={{ width: '10vw', height: '20vw', background: '#FFFFFF' }} onClick={() => this.clear()} hidden={!line_id} >
                                    เคลียร์ข้อมูล
                            </BT>
                                <BT htmlType="submit" style={{ width: '10vw', height: '20vw', background: 'red', color: '#FFFFFF' }}   >
                                    ลงทะเบียน
                            </BT>


                            </div>

                        </Col>
                        <Col span={14} pull={10}>
                            <div className={'border-claim'} style={{ margin: 15, height: '60%' }}>
                                <div className={'column-claim-cr'}  >
                                    <font style={{ fontSize: '0.4cm' }}>ลูกค้าลงทะเบียนรับประกัน</font>

                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: -35 }}>
                                    {/* <div className={'column-claim-cr'} style={{ flex: 0.5 }} > */}
                                    <div style={{ flex: 1 }}>
                                        <div className={'column-claim-cr'} >
                                            <Form.Item label="Line ผู้ใช้">
                                                {getFieldDecorator('line_id', {
                                                    rules: [{ required: true, message: 'กรุณาใส่ Line ผู้ใช้' }],
                                                })(<Input suffix={<Icon type={lineid_colect} style={{ fontSize: '16px' }} className={lineid_colect_color} />} style={{ width: '100%' }}
                                                    placeholder="Line ID"
                                                    value={line_id}
                                                    onKeyPress={e => this.onKeyPressSearch(e)}
                                                    onChange={e => this.onChange(e.target.value, 'line_id')}
                                                />)}
                                            </Form.Item>
                                        </div>

                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <div className={'column-claim-cr'} style={{ marginTop: 70, marginLeft: -10 }} >
                                            <font style={{ fontSize: '0.3cm', color: this.state.error_color }} hidden={error_hidden} >{line_error}</font>
                                        </div>

                                    </div>
                                    {/* </div> */}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: -50 }}>
                                    <div style={{ flex: 1, }}>
                                        <div className={'column-claim-cr'} >

                                            <Form.Item label="ชื่อ-นามสกุล">
                                                {getFieldDecorator('name', {
                                                    rules: [{ required: true, message: 'กรุณาใส่ ชื่อ-นามสกุล' }],
                                                })(<Input style={{ width: '95%' }} defaultValue={name}
                                                    value={name}

                                                    onChange={e => this.onChange(e.target.value, 'name')}
                                                />)}

                                            </Form.Item>

                                        </div>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <div className={'column-claim-cr'} >
                                            <Form.Item label="เบอร์โทรศัพท์">
                                                {getFieldDecorator('telephone', {
                                                    rules: [{ required: true, message: 'กรุณาระบุเบอร์โทร' }],
                                                })(<Input style={{ width: '95%' }} defaultValue={telephone}
                                                    value={telephone}
                                                    onChange={e => this.onChange(e.target.value, 'telephone')}
                                                />)}

                                            </Form.Item>
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </Col>

                        <Col span={14} pull={10}>
                            <div className={'border-claim'} style={{ margin: 15 }}>
                                <div className={'column-claim-cr'}  >
                                    <font style={{ fontSize: '0.4cm' }}>ใบเสร็จสินค้า</font>

                                </div>
                                <div style={{ display: 'flex', margin: 15, flexDirection: 'row' }} >
                                    <div style={{ flex: 1, justifyContent: 'center', marginLeft: 30, marginBottom: 30 }}>
                                        <Upload
                                            name="avatar"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            onChange={this.handleChangeSlip}
                                        >
                                            {imageUrlSlip ? <img src={imageUrlSlip} alt="new" style={{ width: '6cm', height: '7cm' }} /> : uploadButton}
                                        </Upload>
                                    </div>
                                    <div style={{ flex: 2, marginLeft: 10 }}>
                                        <font style={{ fontSize: '0.4cm', marginLeft: 20 }}>ระยะเวลารับประกันสินค้า {warranty_month} เดือน</font>
                                    </div>
                                </div>

                            </div>

                        </Col>


                    </Row>

                </div >
            </Form>
        )
    }
}
// Form.create({ name: 'dynamic_rule' })(RegisterWarranty);
export default RegisterWarranty
