import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Layout, Menu, Icon, Avatar } from "antd";
import { menu, color, image } from "../resource";
import user from "../mobx/user";
import { Observer } from "mobx-react";
import { Label } from "../styles/styled";
import { VERSION } from "../service_focus";
const { Header, Content, Sider } = Layout;

const styles = {
  defaultHeader: {
    background: color.rizz_secondary,
    padding: "0px 20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
};

export default withRouter((props) => {
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    if (!user.role && !user.auth) {
      return props.history.replace("/login");
    }

    const findPath = menu[user.role].routes.findIndex((el) =>
      props.location.pathname.includes(el.path)
    );
    if (findPath === -1) return props.history.replace("/");

  }, [props.location.pathname]);

  function onClick(path) {
    props.history.push(path);
  }

  function onLogout() {
    user.logout();
    props.history.push("/login");
  }

  if (user.role === "rizz") {
    // require("../styles/rizz_bar.css");

    return (
      <Observer>
        {() =>
          user.role && (
            <Layout style={{ minHeight: "100vh" }}>
              <Layout>
                <Sider
                  breakpoint="lg"
                  collapsible
                  collapsed={collapsed}
                  onCollapse={(collapsed) => setCollapsed(collapsed)}
                >
                  <img
                    src={image.brandwhite}
                    style={{ width: "100%" }}
                    alt={""}
                  />
                  <Menu
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={[
                      (
                        menu[user.role].menu.findIndex((el) =>
                          props.location.pathname.includes(el.path)
                        ) + 1
                      ).toString(),
                    ]}
                  >
                    {menu[user.role].menu.map((el, i) => {
                      return (
                        <Menu.Item
                          key={i + 1}
                          onClick={(e) => onClick(el.path)}
                        >
                          <Icon type={el.icon} />
                          <span className="nav-text">{el.name}</span>
                        </Menu.Item>
                      );
                    })}

                    <Menu.Item
                      style={{
                        position: "fixed",
                        bottom: "50px",
                        width: collapsed ? "80px" : "200px",
                      }}
                      // className={'ant-layout-sider-trigger'}
                      onClick={(_) => onLogout()}
                    >
                      <Icon type={"logout"} />
                      <span className="nav-text">ออกจากระบบ</span>
                    </Menu.Item>
                  </Menu>
                </Sider>
                <Layout>
                  <Header style={styles.defaultHeader}>
                    <div />
                    {/* <h2 style={{ color: color.rizz_primary }}>{menu[user.role].menu[index - 1].name}</h2> */}
                    <Avatar
                      style={{ color: "#fff", backgroundColor: "#F40011" }}
                      size={"large"}
                    >
                      Rizz
                    </Avatar>
                  </Header>
                  <Content
                    style={{
                      margin: "24px 16px",
                      background: "#fff",
                      minHeight: 280,
                    }}
                  >
                    {props.children}
                  </Content>
                </Layout>
              </Layout>
            </Layout>
          )
        }
      </Observer>
    );
  } else {
    require("../styles/focus_bar.css");
    return (
      <Observer>
        {() =>
          user.role && (
            <Layout style={{ minHeight: "100vh" }}>
              <Layout>
                <Sider
                  breakpoint="lg"
                  collapsible
                  collapsed={collapsed}
                  onCollapse={(collapsed) => setCollapsed(collapsed)}
                >
                  <img
                    src={image.focus_brand}
                    style={{ width: "100%" }}
                    alt={""}
                  />
                  <Menu
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={[
                      (
                        menu[user.role].menu.findIndex((el) =>
                          props.location.pathname.includes(el.path)
                        ) + 1
                      ).toString(),
                    ]}
                  >
                    {menu[user.role].menu.map((el, i) => {
                      return (
                        <Menu.Item
                          key={i + 1}
                          onClick={(e) => onClick(el.path)}
                        >
                          <Icon type={el.icon} />
                          <span className="nav-text">{el.name}</span>
                        </Menu.Item>
                      );
                    })}

                    <Menu.Item
                      style={{
                        position: "fixed",
                        bottom: "90px",
                        width: collapsed ? "80px" : "200px",
                      }}
                      // className={'ant-layout-sider-trigger'}
                      onClick={(_) => onLogout()}
                    >
                      <Icon type={"logout"} />
                      <span className="nav-text">
                        ออกจากระบบ
                        <br />
                      </span>
                    </Menu.Item>
                    <Menu.Item
                      style={{
                        position: "fixed",
                        bottom: "50px",
                        width: collapsed ? "80px" : "200px",
                      }}
                    >
                      <span style={{ marginLeft: '20px' }} className="nav-text">{`${VERSION}`}</span>
                    </Menu.Item>
                  </Menu>
                </Sider>
                <Layout>
                  <Header style={styles.defaultHeader}>
                    <Label style={{ color: "#fff" }}>
                      <Icon type={"user"} /> {user.name} [{user.role}]
                    </Label>
                    <div />

                    <Avatar
                      style={{
                        color: "#fff",
                        backgroundColor: color.dplus_primary,
                        transform: "translateX(-50%)",
                      }}
                      size={"large"}
                    >
                      Focus
                    </Avatar>
                  </Header>
                  <Content
                    style={{
                      margin: "24px 16px",
                      background: "#fff",
                      minHeight: 280,
                    }}
                  >
                    {props.children}
                  </Content>
                </Layout>
              </Layout>
            </Layout>
          )
        }
      </Observer>
    );
  }
});
