import React, { Component } from "react";
import { observer } from "mobx-react";
import {
  Form,
  Icon,
  Upload,
  Input,
  Row,
  Col,
  Button as BT,
  DatePicker,
  Spin,
  Modal,
  message,
  Select,
} from "antd";
import autobind from "autobind-decorator";
import {
  GET,
  POST,
  FOCUS_WARRANTY_ID_ADMIN,
  FOCUS_FIND_CUSTOMER,
  FOCUS_REGIS_WARRANTY,
  FOCUS_REGISTER_SENDNOTI,
  FOCUS_GETBRAND_CATEGORY,
  FOCUS_GETCHANNELBUY,
  FOCUS_GETSTORE,
} from "../../../service_focus";
import { BtnGoback } from "../../../components/Button";
import { color } from "../../../resource";
const fn = require("../../../functions/functions");

@Form.create({ name: "RegisterWarranty" })
@observer
class RegisterWarranty extends Component {
  constructor(props) {
    super(props);
    this.props.form.setFieldsValue({ name: "aaa" });
    this.state = {
      loading: true,
      product_name: "",
      name: "",
      telephone: "",
      lineid_colect: "",
      decode_colect: "",
      error_hidden: true,
      line_error: "ยังไม่มีเบอร์โทรในระบบ",
      error_color: "red",
      product: [],
      brand: [],
      model: [],
      model_name: "กรุณาเลือกรุ่น",
      channel_type_store: "",
      channel_buy: [],
      data_model: [],
      namedis: false,
      errordecode_detail: "",
      errordecode: false,
      user_id: "",
      store_no: "",
      store_name: "",
      store_id: "",
      branch_no: "",
      channel_buy_name: "",
      brand_id: "",
      brand_name: "",
      focus_store_no: "",
      focus_store_name: "",
    };
  }

  componentDidMount() {
    this.getChannelBuy();
  }
  @autobind
  async getTel(tel) {
    const obj = { telephone: tel };
    try {
      const res = await POST(FOCUS_FIND_CUSTOMER, obj);
      const { success, result } = res;
      if (success) {
        this.setState({
          loading: false,
          data_telephone: result.telephone,
          line_id: result.line_id,
          lineid_colect: result.has_customer ? "check-circle" : "close-circle",
          lineid_colect_color: result.has_customer
            ? "certain-category-icon-colect"
            : "certain-category-icon-uncolect",
          error_hidden: false,
          error_color: result.has_customer ? "green" : "red",
          line_error: result.has_customer
            ? "พบเบอร์โทรในระบบ"
            : "ไม่พบเบอร์โทรในระบบ",
          name: result.has_customer ? result.name : "",
          namedis: result.has_customer,
          user_id: result.has_customer ? result.user_id : "",
        });
        this.props.form.setFieldsValue({
          name: result.has_customer ? result.name : "",
        });
      }
    } catch (error) {
      Modal.error({
        title: "ระบบขัดคล่อง",
        content: "กรุณาลองใหม่ภายหลัง",
      });
    }
  }

  @autobind
  async getChannelBuy() {
    try {
      const r = await GET(FOCUS_GETCHANNELBUY);
      if (r.success === true) {
        // console.log('FOCUS_GETCHANNELBUY',r.result)
        this.setState({ channel_buy: r.result, loading: false });
      }
    } catch (err) {
      console.log("getChannelBuy()=>", err.message);
    }
  }

  @autobind
  async getStoreDetail(no) {
    try {
      const r = await GET(FOCUS_GETSTORE + no);
      if (r.success === true) {
        this.setState({
          store_no: r.result.store_no,
          store_name: r.result.store_name,
          store_id: r.result.store_id,
          branch_no: r.result.branch_no,
          focus_store_no: r.result.store_no,
          focus_store_name: r.result.store_name,
        });
      } else {
        this.setState({
          store_no: no,
          namestore: "",
          branch_no: "",
          store_id: "",
          branch_name: "",
        });
      }
    } catch (err) {
      console.log("getStoreDetail(no)=>", err.message);
    }
  }

  @autobind
  async createWarranty() {
    this.setState({
      loading: true,
    });
    const {
      name,
      telephone,
      product_name,
      model_name,
      brand_name,
      category,
      code,
      decode,
      user_id,
      product_id,
      channel_type_store,
      store_id,
      store_no,
      branch_no,
      line_id,
      channel_buy_name,
      brand_id,
      model_id,
      focus_store_no,
      store_name,
    } = this.state;
    let obj = {
      userId: user_id || "",
      category,
      qrcode: code,
      nameproduct: product_name,
      brandmobile: brand_name,
      modelmobile: model_name,
      name,
      tel: telephone,
      register_type: "WEB",
      type_store: channel_type_store,
      tmp_wid: "",
      decode,
      namestore: store_name || channel_buy_name,
      store_id,
      store_no:
        channel_type_store === "FOCUS" || channel_type_store === "STORE"
          ? store_no
          : "",
      branch_no,
      product_id,
      brand_id,
      model_id,
      line_id,
    };
    let obj_noti = {
      user_id,
      telephone,
      name,
      line_id,
      nameproduct: product_name,
      brandmobile: brand_name,
      modelmobile: model_name,
    };
    if (store_no.trim()) {
      console.log({ obj });
      try {
        let res = await POST(FOCUS_REGIS_WARRANTY, obj);
        if (res.success) {
         // await POST(FOCUS_REGISTER_SENDNOTI, obj_noti);
          this.setState({
            loading: false,
          });
          this.clear();
          Modal.success({
            title: "บันทึกข้อมูลสำเร็จ",
            content: `ชุดเอกสารลงทะเบียน ${res.result.no_warranty}`,
          });
        }
      } catch (error) {
        Modal.error({
          title: "ระบบขัดคล่อง",
          content: { error },
        });
      }
    } else {
      Modal.error({
        title: "กรุณากรอกข้อมูลให้ครบถ้วน",
      });
    }
  }
  @autobind
  async getbrand(product_type) {
    try {
      let res = await GET(`${FOCUS_GETBRAND_CATEGORY}/${product_type}`);
      if (res.success) {
        return res.result;
      } else {
        return [];
      }
    } catch (error) {
      Modal.error({
        title: "ระบบขัดคล่อง",
        content: "ไม่พบยี่ห้อมือถือของชื่อสินค้านี้",
      });
      return [];
    }
  }
  @autobind
  async checkWarranty() {
    const { decode } = this.state;
    let strdecode = decode;

    if (decode) {
      try {
        if (strdecode.length >= 58) {
          strdecode = decode.substring(decode.length - 11, decode.length);
        }
        let res = await GET(`${FOCUS_WARRANTY_ID_ADMIN}/${strdecode}`);
        if (res.success) {
          if (res.result !== 0) {
            this.setState({
              decode: strdecode,
              product: res.result.product_name,
              model: res.result.model,
              brand:res.result.brand,
              data_model: res.result.model,
              category: res.result.codegenerate[0].category,
              code: res.result.codegenerate[0].code,
              errordecode_detail: "รหัสสินค้ารับประกันถูกต้อง",
              errordecode: true,
              error_color_decode: "green",
              decode_colect: "check-circle",
              decode_colect_color: "certain-category-icon-colect",
            });
            this.props.form.setFieldsValue({
              decode: strdecode,
            });
            this.getChannelBuy();
          } else {
            let obj = {
              product: [],
              brand: [],
              model: [],
              data_model: [],
              brand_name: "",
              product_name: "",
              model_name: "",
              channel_type_store: "",
              channel_buy: [],
              store_no: "",
              store_name: "",
              store_id: "",
              branch_no: "",
              channel_buy_name: "",
              brand_id: "",
              brand_name: "",
              focus_store_no: "",
              focus_store_name: "",
            };
            this.setState({
              decode: strdecode,
              errordecode_detail: "ไม่พบรหัสรับประกันดังกล่าว",
              errordecode: true,
              error_color_decode: "red",
              decode_colect: "close-circle",
              decode_colect_color: "certain-category-icon-uncolect",
              namedis: false,

              ...obj,
            });
            this.props.form.setFieldsValue({
              namedis: false,
              decode: strdecode,
              ...obj,
            });
          }
        }
      } catch (error) {
        Modal.error({
          title: "ระบบขัดคล่อง",
          content: "กรุณาลองใหม่ภายหลัง",
        });
      }
    }
  }

  @autobind
  async onChange(e, ref) {
    let { product } = this.state;
    if (ref === "product_name") {
      let findproduct_id = product.find((el) => el.product_name === e);
      let brands = await this.getbrand(findproduct_id.product_type);
      this.setState(
        {
          [ref]: e,
          product_id: findproduct_id.product_id,
          brand: brands,
        },
        () => console.log("brand=>", this.state.brand)
      );
    } else if (ref === "channel_type_store") {
      let channel_type_store = e.split("|")[0];
      let channel_buy_name = e.split("|")[1];
      console.log(channel_type_store, channel_buy_name);
      this.setState({ channel_type_store, channel_buy_name }, () =>
        console.log("ch=>", this.state.channel_buy_name)
      );
    } else if (ref === "model_name") {
      let id = e.split("|")[0];
      let name = e.split("|")[1];
      this.setState({ model_id: id, model_name: name }, () =>
        console.log(this.state.model_id, this.state.model_name)
      );
    } else {
      console.log(ref, e);
      this.setState({
        [ref]: e,
      });
    }

    let obj = {
      product: [],
      brand: [],
      model: [],
      data_model: [],
      brand_name: "",
      product_name: "",
      model_name: "",
      channel_type_store: "",
      channel_buy: [],
      store_no: "",
      store_name: "",
      store_id: "",
      branch_no: "",
      channel_buy_name: "",
      brand_id: "",
      brand_name: "",
      focus_store_no: "",
      focus_store_name: "",
    };
    switch (ref) {
      case "telephone":
        this.setState({
          lineid_colect: "",
          error_hidden: true,
          name: "",
          namedis: false,
        });
        break;
      case "decode":
        this.setState({
          errordecode: false,
          ...obj,
        });
        this.props.form.setFieldsValue({
          namedis: false,
          ...obj,
        });
      case "channel_type_store":
        this.setState({
          store_name: "",
          branch_no: "",
          store_id: "",
          store_no: "",
          focus_store_no: "",
          focus_store_name: "",
        });
      case "store_no":
        // if (e.length === 14) {
        this.getStoreDetail(e);
      // } else {
      //   this.setState({
      //     store_name: "",
      //     branch_no: "",
      //     store_id: "",
      //     focus_store_no: "",
      //     focus_store_name: "",
      //   });
      // }
      case "focus_store_no":
        if (e.length === 14) {
          this.getStoreDetail(e);
        } else {
          this.setState({
            store_name: "",
            branch_no: "",
            store_id: "",
            focus_store_no: "",
            focus_store_name: "",
          });
        }
    }
  }

  @autobind
  onKeyPressSearch(event, type) {
    if (event.key === "Enter") {
      type === "telephone"
        ? this.getTel(event.target.value)
        : this.checkWarranty();
    }
  }
  // @autobind
  // onSearch() {
  //   const { telephone } = this.state;
  //   const item = this.state.data_telephone.find(
  //     (el) => el.telephone === telephone
  //   );

  //   if (item) {
  //     this.setState({
  //       line_id: item.line_id,
  //       lineid_colect: "check-circle",
  //       lineid_colect_color: "certain-category-icon-colect",
  //       error_hidden: false,
  //       error_color: "green",
  //       line_error: "พบเบอร์โทรในระบบ",
  //       name: item.name,
  //       namedis: true,
  //       user_id: item.user_id,
  //     });
  //     this.props.form.setFieldsValue({
  //       name: item.name,
  //     });
  //   } else {
  //     this.setState({
  //       lineid_colect: "close-circle",
  //       lineid_colect_color: "certain-category-icon-uncolect",
  //       error_hidden: false,
  //       error_color: "red",
  //       line_error: "ไม่พบเบอร์โทรในระบบ",
  //       name: "",
  //       namedis: false,
  //       user_id: "",
  //     });
  //     this.props.form.setFieldsValue({
  //       name: "",
  //     });
  //   }
  // }
  @autobind
  clear() {
    this.setState({
      product_name: "",
      imageUrlSlip: "",
      name: "",
      telephone: "",
      lineid_colect: "",
      error_hidden: true,
      namedis: false,
      product: [],
      brand: [],
      model: [],
      data_model: [],
      brand_name: "",
      model_name: "",
      channel_type_store: "",
      channel_buy: [],
      errordecode: false,
      decode_colect: "",
      user_id: "",
      store_no: "",
      store_name: "",
      store_id: "",
      branch_no: "",
      channel_buy_name: "",
      brand_id: "",
      brand_name: "",
      focus_store_no: "",
      focus_store_name: "",
    });
    this.props.form.setFieldsValue({
      name: "",
      telephone: "",
      decode: "",
      product: [],
      brand: [],
      model: [],
      data_model: [],
      namedis: false,
      brand_name: "",
      product_name: "",
      model_name: "",
      channel_type_store: "",
      channel_buy: [],
      store_no: "",
      store_name: "",
      store_id: "",
      branch_no: "",
      channel_buy_name: "",
      brand_id: "",
      brand_name: "",
      focus_store_no: "",
      focus_store_name: "",
    });
  }
  @autobind
  phonValidate() {
    fn.phonNumberValidate(this.state.telephone);
  }
  @autobind
  goBack() {
    this.props.history.goBack();
  }
  onChangeSelectBrand = (value) => {
    let id = value.split("|")[0];
    let name = value.split("|")[1];
    const model = this.state.data_model.filter((el) => el.brand_name === name);
    this.setState(
      {
        model,
        brand_name: name,
        brand_id: id,
      },
      () => console.log("model=>", model)
    );
  };

  @autobind
  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (!fn.phonNumberValidate(this.state.telephone)) {
          message.error("เบอร์โทรของท่านไม่ถูกต้อง");
        } else {
          this.createWarranty();
        }
      }
    });
  }

  render() {
    const {
      error_hidden,
      line_error,
      loading,
      product_name,
      brand_name,
      name,
      telephone,
      lineid_colect,
      lineid_colect_color,
      decode,
      store_no,
      store_name,
      model,
      model_name,
      product,
      brand,
      namedis,
      errordecode,
      errordecode_detail,
      decode_colect,
      decode_colect_color,
      channel_type_store,
      channel_buy,
      focus_store_no,
      focus_store_name,
    } = this.state;

    console.log({ channel_type_store });
    if (loading) {
      return (
        <div className="example" style={{ width: "100%" }}>
          <Spin />
        </div>
      );
    }
    const { getFieldDecorator } = this.props.form;
    getFieldDecorator("RegisterWarranty", { initialValue: [] });
    return (
      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 12 }}
        onSubmit={this.handleSubmit}
      >
        <div>
          <BtnGoback title={"กลับ"} onClick={() => this.goBack()} />

          <Row>
            <Col span={10} push={14}>
              <div
                className={"border-claim"}
                style={{ margin: 15, marginBottom: 30 }}
              >
                <div className={"column-claim-cr"}>
                  <font style={{ fontSize: "0.4cm" }}>
                    สินค้าลงทะเบียนรับประกัน
                  </font>
                </div>
                <div className={"column-claim-cr"} style={{ marginTop: -10 }}>
                  <Form.Item label="รหัสรับประกันสินค้า">
                    {getFieldDecorator("decode", {
                      rules: [
                        {
                          required: true,
                          message: "กรุณาใส่รหัสรับประกันสินค้า",
                        },
                      ],
                    })(
                      <Input
                        suffix={
                          <Icon
                            type={decode_colect}
                            style={{ width: "100%", marginTop: -20 }}
                            className={decode_colect_color}
                          />
                        }
                        defaultValue={decode}
                        value={decode}
                        onKeyPress={(e) => this.onKeyPressSearch(e, "decode")}
                        onChange={(e) =>
                          this.onChange(e.target.value, "decode")
                        }
                      />
                    )}
                  </Form.Item>

                  <div
                    className={"column-claim-cr"}
                    style={{ marginTop: -20, marginLeft: 5 }}
                  >
                    <font
                      style={{
                        fontSize: "0.3cm",
                        color: this.state.error_color_decode,
                      }}
                      hidden={!errordecode}
                    >
                      {errordecode_detail}
                    </font>
                  </div>
                </div>
                <div className={"column-claim-cr"} style={{ marginTop: -20 }}>
                  <Form.Item label="ชื่อสินค้า">
                    {getFieldDecorator("product_name", {
                      rules: [{ required: true, message: "เลือกชื่อสินค้า" }],
                    })(
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="ชื่อสินค้า"
                        optionFilterProp="children"
                        onChange={(e) => this.onChange(e, "product_name")}
                        //onSearch={this.onSearchProduct}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        value={product_name}
                      >
                        {product.map((item) => (
                          <Select.Option value={item.product_name}>
                            {item.product_name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </div>
                <div className={"column-claim-cr"} style={{ marginTop: -20 }}>
                  <Form.Item label="ยี่ห้อมือถือ">
                    {getFieldDecorator("brand_name", {
                      rules: [{ required: true, message: "เลือกยี่ห้อมือถือ" }],
                    })(
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="เลือกยี่ห้อ"
                        optionFilterProp="children"
                        onChange={this.onChangeSelectBrand}
                        //onSearch={this.onSearchProduct}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        value={brand_name}
                      >
                        {brand.map((item) => (
                          <Select.Option
                            value={item.brand_id + "|" + item.brand_name}
                          >
                            {item.brand_name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </div>
                <div className={"column-claim-cr"} style={{ marginTop: -20 }}>
                  <Form.Item label="รุ่นมือถือ">
                    {getFieldDecorator("model_name", {
                      rules: [{ required: true, message: "เลือกรุ่นมือถือ" }],
                    })(
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="เลือกรุ่นมือถือ"
                        optionFilterProp="children"
                        onChange={(e) => this.onChange(e, "model_name")}
                        //onSearch={this.onSearchProduct}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        value={model_name}
                      >
                        {model.map((item) => (
                          <Select.Option
                            value={item.model_id + "|" + item.model_name}
                          >
                            {item.model_name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </div>
                <div className={"column-claim-cr"} style={{ marginTop: -20 }}>
                  <Form.Item label="ช่องทางการซื้อ">
                    {getFieldDecorator("channel_type_store", {
                      rules: [
                        { required: true, message: "เลือกช่องทางการซื้อ" },
                      ],
                    })(
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="เลือกช่องทางการซื้อ"
                        optionFilterProp="children"
                        onChange={(e) => this.onChange(e, "channel_type_store")}
                        //onSearch={this.onSearchProduct}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        value={channel_type_store}
                      >
                        {/* {model.map(item => <Select.Option value={item.model_name}>{item.model_name}</Select.Option>)} */}
                        {channel_buy.map((item) => (
                          <Select.Option
                            value={
                              item.type_store + "|" + item.channel_buy_name
                            }
                          >
                            {item.channel_buy_name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </div>
                {channel_type_store === "STORE" ||
                channel_type_store === "FOCUS" ? (
                  <React.Fragment>
                    <div
                      className={"column-claim-cr"}
                      style={{ marginTop: -20 }}
                    >
                      <Form.Item label="รหัสร้านค้า">
                        {getFieldDecorator("store_no", {
                          rules: [
                            {
                              required: true,
                              message: "กรุณาใส่รหัสร้านค้า XX-XXX-XX-XXXX",
                            },
                          ],
                        })(
                          <Input
                            // defaultValue={store_no}
                            placeholder="XX-XXX-XX-XXXX"
                            maxLength={20}
                            value={this.state.store_no}
                            onChange={(e) =>
                              this.onChange(e.target.value, "store_no")
                            }
                          />
                        )}
                      </Form.Item>
                    </div>
                    <div
                      className={"column-claim-cr"}
                      style={{ marginTop: -20 }}
                    >
                      <Form.Item label="ชื่อร้านค้า">
                        <Input
                          // defaultValue={store_no}
                          value={store_name}
                          disabled={true}
                          onChange={(e) =>
                            this.onChange(e.target.value, "store_name")
                          }
                        />
                      </Form.Item>

                      {/* <div className={'column-claim-cr'} style={{ marginTop: -20, marginLeft: 5 }} >
                                        <font style={{ fontSize: '0.3cm', color: this.state.error_color_decode }} hidden={!errordecode} >{errordecode_detail}</font>
                                    </div> */}
                    </div>
                  </React.Fragment>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col span={14} pull={10}>
              <div className={"border-claim"} style={{ margin: 15 }}>
                <div className={"column-claim-cr"}>
                  <font style={{ fontSize: "0.4cm" }}>
                    ลูกค้าลงทะเบียนรับประกัน
                  </font>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: -30,
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <div className={"column-claim-cr"}>
                      <Form.Item label="เบอร์โทรศัพท์">
                        {getFieldDecorator("telephone", {
                          rules: [
                            { required: true, message: "กรุณาระบุเบอร์โทร" },
                          ],
                        })(
                          <Input
                            suffix={
                              <Icon
                                type={lineid_colect}
                                style={{ fontSize: "16px" }}
                                className={lineid_colect_color}
                              />
                            }
                            style={{ width: "95%" }}
                            defaultValue={telephone}
                            placeholder="เบอร์โทร"
                            value={telephone}
                            onKeyPress={(e) =>
                              this.onKeyPressSearch(e, "telephone")
                            }
                            onChange={(e) =>
                              this.onChange(e.target.value, "telephone")
                            }
                          />
                        )}
                      </Form.Item>

                      <div
                        className={"column-claim-cr"}
                        style={{ marginTop: -20, marginLeft: 5 }}
                      >
                        <font
                          style={{
                            fontSize: "0.3cm",
                            color: this.state.error_color,
                          }}
                          hidden={error_hidden}
                        >
                          {line_error}
                        </font>
                      </div>
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div className={"column-claim-cr"}>
                      <Form.Item label="ชื่อ-นามสกุล">
                        {getFieldDecorator("name", {
                          rules: [
                            {
                              required: true,
                              message: "กรุณาใส่ ชื่อ-นามสกุล",
                            },
                          ],
                        })(
                          <Input
                            style={{ width: "95%" }}
                            defaultValue={name}
                            value={name}
                            // disabled={namedis}
                            onChange={(e) =>
                              this.onChange(e.target.value, "name")
                            }
                          />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col span={14} pull={10}>
              <div style={{ margin: 15, marginTop: 250 }}>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                    margin: 15,
                  }}
                >
                  <BT
                    style={{
                      width: "10vw",
                      height: "20vw",
                      background: "#FFFFFF",
                    }}
                    onClick={() => this.clear()}
                    hidden={!telephone}
                  >
                    เคลียร์ข้อมูล
                  </BT>
                  <BT
                    htmlType="submit"
                    style={{
                      width: "10vw",
                      height: "20vw",
                      background: color.dplus_main,
                      color: "#FFFFFF",
                    }}
                  >
                    ลงทะเบียน
                  </BT>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    );
  }
}
// Form.create({ name: 'dynamic_rule' })(RegisterWarranty);
export default RegisterWarranty;
