import styled from 'styled-components';
import { color } from '../resource';

export const BtnStyleConfirm = styled.button`
    min-width: 8vh;
    padding: 0.6vh;
    font-size: 0.6vw;
    background-color: #EE220C;
    border-radius: 4vh;
    border: 1px solid #EE220C;
    color: #fff;
    cursor: pointer;
    outline: none;

    :hover {
        background-color: #fff;
        color: #EE220C;
    }
`

export const ColumnStyleStatus = styled.div`
    width: 100%;
    background-color: ${props =>
		props.status === 0 ? color.rizz_ems :
			props.status === 1 ? color.rizz_process :
				props.status === 2 ? color.rizz_complete : 'transparent'}
`

export const Label = styled.div`
    font-size: 1vw;
    width: 20%;
    display: inline-block;
`

export const ColumnStylestatusOfFocus = styled.div`
    width: 100%;
    background-color: ${props =>
		props.status === 0 ? 'transparent' :   // ลงทะเบียน
			props.status === 1 ? color.focus_waitpay :   //รอชำระเงิน
				props.status === 2 ? color.focus_waittocheck :  //รอการตรวจสอบ
					props.status === 3 ? color.focus_successpay :  //ชำระเงินเรียบร้อย
						props.status === 4 ? color.focus_complete : 'transparent'//จัดส่งเรียบร้อย

	} 
`
export const ColumnStylestatusOfDealer = styled.div`
    width: 100%;
    background-color: ${props =>
		props.status === 0 ? color.focus_waitpay :   // ลงทะเบียน
			props.status === 1 ? color.focus_complete : 'transparent'//จัดส่งเรียบร้อย

	} 
`
export const ColumnStylestatusOfStore = styled.div`
    width: 100%;
    background-color: ${props =>
		props.status === 0 ? color.dplus_primary :   // DPLUS
			props.status === 1 ? color.focus_complete :   //STORE
				props.status === 2 ? color.focus_waitpay :   //ONLINE
					props.status === 3 ? color.focus_successpay :   //FOCUS SHOP
						'transparent'

	} 
`
export const ColumnStylestatusOfFocusImportAx = styled.div`
    width: 100%;
    background-color: ${props =>
		props.status === 99 ? color.focus_waitpay : // รอการดำเนินการ
			props.status === 1 ? color.focus_complete :
				props.status === 2 ? color.focus_waittocheck : 'transparent'  //Importเสร็จสิ้น

	} 
`
export const ColumnStylestatusOfFocusTypeCliam = styled.div`
    width: 100%;
    background-color: ${props =>
		props.status === 'COMPANY' ? 'transparent' : color.focus_successpay   //ชำระเงินเรียบร้อย

	} 
`
export const Font = styled.div.attrs(props => ({
	// hidden: props.theme.hidden
	children: !props.theme.hidden ? '-' : props.text
}))(props => ({ ...styles.font, opacity: +props.theme.hidden }))




export const styles = {
	font: {
		fontSize: '0.4cm',
		marginTop: 3
	},
	border: {
		border: '0.5px',
		borderStyle: 'solid',
		borderColor: '#CCCCCC',


	},
	border_part1: {
		border: '0.5px',
		borderStyle: 'solid',
		borderColor: '#CCCCCC',
		marginTop: 15
	}


}

