import React, { Component } from "react";
import { observer } from "mobx-react";
import moment from "moment";
import {
  Form,
  Icon,
  Upload,
  Input,
  Row,
  Col,
  Content,
  Button as BT,
  DatePicker,
  Spin,
  Modal,
  message,
  Select,
} from "antd";
import autobind from "autobind-decorator";
import {
  GET,
  POST,
  FOCUS_WARRANTY_ACTIVE_ID,
  FOCUS_CUSTOMER,
  FOCUS_GETCHANNELBUY,
  FOUCUS_WARRANTYALL,
  FOCUS_GETSTORE,
  FOCUS_UPDATE_WARRANTY,
} from "../../../service_focus";
import { BtnGoback } from "../../../components/Button";
import { color } from "../../../resource";
import {
  CUSTOMER_WARRANTY_FORM,
  PRODUCTS_WARRANTY_FORM,
} from "../../../resource/static";
const fn = require("../../../functions/functions");

@observer
class EditWarrantyScene extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    this.state = {
      loading: true,
      warranty_doc: query.get("id"),
      data: [],
      dataCustomer: [],
      error_hidden: true,
      line_error: "",
      error_color: "",
      lineid_colect: "",
      lineid_colect_color: "",
      user_role:
        !!JSON.parse(localStorage.getItem("DkdL8OVPdp")) &&
        JSON.parse(localStorage.getItem("DkdL8OVPdp")).role,
      // value:'',
    };
  }

  async componentDidMount() {
    await this.onFetchWarranty();
    await this.getChannelBuy();
     this.getTel();
  }

  onFetchWarranty = async () => {
    const { warranty_doc, user_role } = this.state;
    let obj = {
      date_start: "",
      date_end: "",
      fullTextSearch: warranty_doc === "" ? "" : warranty_doc,
      role: user_role,
    };
    try {
      const res = await POST(FOUCUS_WARRANTYALL, obj);
      if (res.success) {
        const {
          decode,
          store_no,
          store_id,
          branch_no,
          type_store,
          namestore,
          // focus_store_no: r.result.store_no
          branch_name,
          no_warranty,
          product_id,
        } = res.result[0];

        Array.isArray(res.result) &&
          res.result.map((val) => {
            this.setState(
              {
                dataCustomer: [
                  { ...CUSTOMER_WARRANTY_FORM[0], value: val.tel },
                  { ...CUSTOMER_WARRANTY_FORM[1], value: val.name },
                ],
                dataProducts: [
                  { ...PRODUCTS_WARRANTY_FORM[0], value: val.decode },
                  {
                    ...PRODUCTS_WARRANTY_FORM[1],
                    value: `${val.product_id}|${val.nameproduct}`,
                  },
                  { ...PRODUCTS_WARRANTY_FORM[2], value: val.brandmobile },
                  { ...PRODUCTS_WARRANTY_FORM[3], value: val.modelmobile },
                  { ...PRODUCTS_WARRANTY_FORM[4], value: `${val.type_store}` },
                ],
                loading: false,
                decode,
                store_no,
                store_id,
                branch_no,
                type_store,
                namestore,
                // focus_store_no: r.result.store_no
                branch_name,
                no_warranty,
                product_id,
                dateexp: moment(res.result[0].dateexp).format("YYYY-MM-DD"),
                datestart: moment(res.result[0].datestart).format("YYYY-MM-DD"),
              },
              () => this.checkWarranty()
            );
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  getTel = async () => {
    try {
      const res = await GET(FOCUS_CUSTOMER);
      if (res.success) {
        this.setState({
          data_telephone: res.result,
        });
      }
    } catch (error) {
      console.log(error)
      // Modal.error({
      //   title: "ระบบขัดคล่อง",
      //   content: "กรุณาลองใหม่ภายหลัง",
      // });
    }
  };

  getStoreDetail = async (no) => {
    try {
      const r = await GET(`${FOCUS_GETSTORE}${no}`);
      if (r.success) {
        this.setState({
          store_no: r.result.store_no,
          namestore: r.result.store_name,
          store_id: r.result.store_id,
          branch_no: r.result.branch_no,
          // focus_store_no: r.result.store_no
          branch_name: r.result.branch_name,
        });
      } else {
        this.setState({
          store_no: no,
          namestore: "",
          branch_no: "",
          store_id: "",
          branch_name: "",
        });
      }
    } catch (err) {
      console.log("getStoreDetail(no)=>", err.message);
    }
  };

  onChange = (value, fieldName) => {
    const {
      dataCustomer,
      dataProducts,
      data_products,
      data_brand,
      data_model,
    } = this.state;
    if (fieldName === "tel" || fieldName === "fullname") {
      Array.isArray(dataCustomer) &&
        dataCustomer.map((val) => {
          if (val.fieldName === fieldName) {
            val.value = value;
          }
        });
    } else if (fieldName === "store_no") {
      // if (value.length === 14 || value.length === 15) {
      this.getStoreDetail(value);
      // } else {
      //   this.setState({
      //     namestore: "",
      //     branch_no: "",
      //     store_no: value,
      //     store_id: "",
      //     branch_name: "",
      //   });
      // }
    } else {
      Array.isArray(dataProducts) &&
        dataProducts.map((val) => {
          const fieldNameNew = !fieldName ? val.fieldName : fieldName;
          const valueNew = !value ? val.value : value;
          
          if (fieldNameNew === "nameproduct") {
            let product_id = valueNew.split("|")[0];
            let product_name = valueNew.split("|")[1];



            // console.log("1111111 : ",data_products);
          

            
            let findProduct_type = data_products.find((el) =>
              el.product_name === product_name ? product_name : ""
            );


            const product_type = findProduct_type.product_type;
            const brands = data_brand.filter(
              (el) => el.product_type === product_type
            );

            const optionBrands =
              Array.isArray(brands) &&
              brands.map((el) => {
                return (
                  <Select.Option value={el.brand_name}>
                    {el.brand_name}
                  </Select.Option>
                );
              });
            this.setState({ optionBrands, product_id, product_name });
          } else if (fieldNameNew === "brandmobile") {
            const models = data_model.filter(
              (el) => el.brand_name === valueNew
            );

           
            const optionModels =
              Array.isArray(models) &&
              models.map((el) => {
                return (
                  <Select.Option value={el.model_name}>
                    {el.model_name}
                  </Select.Option>
                );
              });

            if (val.fieldName === "modelmobile") {
              val.value = "---กรุณาเลือกรุ่นมือถือ---";
            }

            this.setState({ optionModels });
          }

          if (val.fieldName === fieldName) {
            if (val.fieldName === "type_store") {
              this.setState({
                namestore: "",
                branch_no: "",
                store_no: "",
                store_id: "",
                branch_name: "",
              });
              let type_store = value;
              // let namestore = value.split('|')[1]
              this.setState({ type_store });
            }
            val.value = value;
          }
        });
    }

    this.setState({ dataCustomer, dataProducts });
  };

  onKeyPressSearch = (e, type) => {
    const { dataCustomer } = this.state;
    const tel = e.target.value;
    const item = this.state.data_telephone.find((el) => el.telephone === tel);
    console.log("555", item);
    if (e.key === "Enter") {
      Array.isArray(dataCustomer) &&
        dataCustomer.map((val) => {
          if (val.fieldName === "fullname") {
            if (item && !!item.telephone) {
              val.value = item.name;
              this.setState({
                lineid_colect: "check-circle",
                lineid_colect_color: "certain-category-icon-colect",
                error_hidden: false,
                error_color: "green",
                line_error: "พบเบอร์โทรในระบบ",
              });
            } else {
              val.value = "";
              this.setState({
                lineid_colect: "close-circle",
                lineid_colect_color: "certain-category-icon-uncolect",
                error_hidden: false,
                line_error: "ไม่พบเบอร์โทรในระบบ",
                error_color: "red",
              });
            }
          }
        });
    }

    this.setState({ dataCustomer });
  };

  checkWarranty = async () => {
    const { decode, dataProducts } = this.state;
    let strdecode = decode;
    if (decode) {
      try {
        if (strdecode.length >= 58) {
          strdecode = decode.substring(decode.length - 11, decode.length);
        }
        let res = await GET(`${FOCUS_WARRANTY_ACTIVE_ID}/${strdecode}`);
        if (res.success) {
          const optionProducts = res.result.product_name.map((el) => {
            return (
              <Select.Option value={`${el.product_id}|${el.product_name}`}>
                {el.product_name}
              </Select.Option>
            );
          });

          this.setState(
            {
              decode: strdecode,
              optionProducts,
              data_products: res.result.product_name,
              data_brand: res.result.brand,
              data_model: res.result.model,
            },
            () => this.onChange("", "")
          );
        }
      } catch (error) {
        Modal.error({
          title: "ระบบขัดคล่อง",
          content: "กรุณาลองใหม่ภายหลัง",
        });
      }
    }
  };

  getChannelBuy = async () => {
    try {
      const res = await GET(FOCUS_GETCHANNELBUY);
      console.log("data", res.result);
      if (res.success) {
        const optionChanelBuy = res.result.map((el) => {
          return (
            <Select.Option value={`${el.type_store}`}>
              {el.channel_buy_name}
            </Select.Option>
          );
        });
        this.setState({ optionChanelBuy });
      }
    } catch (err) {
      console.log("getChannelBuy()=>", err.message);
    }
  };

  handleSubmit = () => {
    const {
      dataCustomer,
      dataProducts,
      no_warranty,
      namestore,
      store_id,
      store_no,
      branch_name,
      branch_no,
      product_id,
      product_name,
      type_store,
      dateexp,
      datestart,
    } = this.state;
    const dataCus = {};
    const dataProd = {};
    // const send_data ={}
    if (Array.isArray(dataCustomer) && Array.isArray(dataProducts)) {
      dataCustomer.forEach((el) => {
        dataCus[el.fieldName] = el.value;
      });
      dataProducts.forEach((el) => {
        dataProd[el.fieldName] = el.value;
      });
    }
    const data_form = [{ ...dataCus, ...dataProd }];
    data_form.map((el) => {
      const { brandmobile, modelmobile, fullname, tel } = el;

      console.log({ el });
      this.setState(
        {
          no_warranty,
          tel,
          name: fullname,
          product_id,
          dateexp,
          datestart,
          nameproduct: product_name,
          brandmobile: brandmobile,
          modelmobile: modelmobile,
          namestore,
          type_store,
          store_id: !!store_id ? store_id : "",
          store_no: !!store_no ? store_no : "",
          branch_no: !!branch_no ? branch_no : "",
          branch_name: !!branch_name ? branch_name : "",
        },
        () => this.sendForm()
      );
    });

    console.log(this.state.name);

    //console.log('this.state',this.state)
  };

  sendForm = async () => {
    try {
      const {
        no_warranty,
        name,
        product_id,
        dateexp,
        nameproduct,
        brandmobile,
        modelmobile,
        namestore,
        type_store,
        store_id,
        store_no,
        branch_no,
        branch_name,
        tel,
      } = this.state;

      console.log("this.state", this.state);

      let obj = {
        no_warranty,
        name,
        product_id,
        dateexp,
        nameproduct,
        brandmobile,
        modelmobile,
        namestore,
        type_store,
        store_id,
        store_no,
        branch_no,
        branch_name,
        tel,
      };

      console.log({ obj });

      let res = await POST(FOCUS_UPDATE_WARRANTY, obj);
      if (res.success) {
        Modal.success({
          title: "บันทึกข้อมูลสำเร็จ",
          // content: `ชุดเอกสารลงทะเบียน ${res.result.no_warranty}`,
        });
      }
    } catch (error) {
      Modal.error({
        title: "ระบบขัดคล่อง",
        content: { error },
      });
    }
  };

  onChangeDate(date, dateString) {
    this.setState({ dateexp: dateString });
    console.log("datae", date, dateString);
  }

  render() {
    const {
      dataCustomer,
      dataProducts,
      loading,
      error_color,
      error_hidden,
      line_error,
      lineid_colect,
      lineid_colect_color,
      optionProducts,
      optionChanelBuy,
      optionBrands,
      optionModels,
      type_store,
      store_no,
      branch_name,
      datestart,
      dateexp,
      product_type,
    } = this.state;

    const dateFormat = "YYYY-MM-DD";
    console.log({ datestart });
    console.log({ dateexp });
    console.log({ optionModels });
    console.log({ dataCustomer });
    console.log({ product_type });
    if (loading) {
      return (
        <div className="example" style={{ width: "100%" }}>
          <Spin />
        </div>
      );
    }
    return (
      <React.Fragment>
        <div>
          <h2>{"แก้ไขข้อมูลรับประกัน"}</h2>
          <Row>
            <Col span={14} className="bg-warranty">
              <h3>{"ลูกค้าลงทะเบียนรับประกัน"}</h3>
              <Row>
                {dataCustomer.map((val, i) => {
                  return (
                    <Col
                      key={`input_${i}`}
                      span={val.fieldName === "tel" ? 10 : 13}
                      className="col-warranty"
                    >
                      <label className="label">{val.label}</label>
                      <Input
                        suffix={
                          val.fieldName === "tel" ? (
                            <Icon
                              type={lineid_colect}
                              style={{ width: "100%", marginTop: -20 }}
                              className={lineid_colect_color}
                            />
                          ) : (
                            ""
                          )
                        }
                        type={val.type}
                        className="input-warranty"
                        value={val.value}
                        onKeyPress={
                          val.fieldName === "tel"
                            ? (e) => this.onKeyPressSearch(e, val.type)
                            : ""
                        }
                        onChange={(e) =>
                          this.onChange(e.target.value, val.fieldName)
                        }
                      />
                      {val.fieldName === "tel" ? (
                        <div
                          className={"column-claim-cr"}
                          style={{ marginTop: 1, marginLeft: 5 }}
                        >
                          <font
                            style={{ fontSize: "0.3cm", color: error_color }}
                            hidden={error_hidden}
                          >
                            {line_error}
                          </font>
                        </div>
                      ) : (
                        ""
                      )}
                    </Col>
                  );
                })}
              </Row>
              <Row>
                <Col span={10} className="col-warranty">
                  <label className="label">วันที่เริ่มรับประกัน :</label>
                  <br />
                  <DatePicker
                    style={{ width: "100%" }}
                    defaultValue={moment(datestart, dateFormat)}
                    format={dateFormat}
                    disabled
                  />
                </Col>
                <Col span={13} className="col-warranty">
                  <label className="label">วันที่สิ้นสุดรับประกัน :</label>
                  <br />
                  <DatePicker
                    style={{ width: "100%" }}
                    defaultValue={moment(dateexp, dateFormat)}
                    onChange={(date, dateString) =>
                      this.onChangeDate(date, dateString)
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col span={8} className="bg-warranty">
              <h3>{"สินค้าลงทะเบียนรับประกัน"}</h3>
              <Row>
                {dataProducts.map((val, i) => {
                  let data_option = [];
                  switch (val.fieldName) {
                    case "nameproduct":
                      data_option = optionProducts;
                      break;
                    case "brandmobile":
                      data_option = optionBrands;
                      break;
                    case "modelmobile":
                      data_option = optionModels;
                      break;
                    case "type_store":
                      data_option = optionChanelBuy;
                      break;

                    default:
                      break;
                  }
                  return (
                    <Col span={23} className="col-warranty">
                      <label className="label">{val.label}</label>
                      {val.fieldName === "qrcode" ? (
                        <Input
                          type={val.type}
                          className="input-warranty"
                          value={val.value}
                          disabled
                        />
                      ) : (
                        <Select
                          showSearch
                          className="select-warranty"
                          value={val.value}
                          onChange={(value) =>
                            this.onChange(value, val.fieldName)
                          }
                        >
                          {data_option}
                        </Select>
                      )}
                    </Col>
                  );
                })}
              </Row>

              {type_store === "STORE" || type_store === "FOCUS" ? (
                <React.Fragment>
                  <Row>
                    <Col span={23} className="col-warranty">
                      <label className="label">{"รหัสร้านค้า :"}</label>
                      <Input
                        placeholder="XX-XXX-XX-XXXX"
                        maxLength={20}
                        className="input-warranty"
                        value={store_no}
                        onChange={(e) =>
                          this.onChange(e.target.value, "store_no")
                        }
                      />
                    </Col>
                    <Col span={23} className="col-warranty">
                      <label className="label">{"ชื่อร้านค้า :"}</label>
                      <Input
                        maxLength={20}
                        className="input-warranty"
                        value={branch_name}
                        onChange={(e) =>
                          this.onChange(e.target.value, "branch_name")
                        }
                        disabled
                      />
                    </Col>
                  </Row>
                </React.Fragment>
              ) : (
                ""
              )}

              <Row style={{ float: "right" }}>
                <BT
                  style={{ background: color.dplus_main, color: "#FFFFFF" }}
                  onClick={() => this.handleSubmit()}
                >
                  {"บันทึก"}
                </BT>
              </Row>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}
export default EditWarrantyScene;
