import React, { Component } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Table, Button as BT, Modal, Row, Col, Input, DatePicker } from "antd";
import { ColumnQRCode, color } from "../../../resource";
import { GET, GET_QR_CODE_WARRANTY } from "../../../service_focus";
import "react-image-lightbox/style.css";
import { ContainerButton } from "../../../styles/styledComponentGlobal";
import { GenQRCodeModal } from "./Modal";
const page_name = "คิวอาร์โค้ดรับประกัน";



class GenQRCodeScene extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isLoadingData: false,
      visibleQrCode: false,
      dataSource: [],
      actionBtn: "",
    };
  }

  componentDidMount() {
    this.onGetQrCodeWarranty();
  }

  async onGetQrCodeWarranty() {
    try {
      this.setState({ isLoadingData: true });
      const res = await GET(GET_QR_CODE_WARRANTY);
      if (res.success) {
        this.setState({
          dataSource: res.result.map((e, i) => {
            return {
              key: i + 1,
              ...e,
            };
          }),
        });
      }
    } catch (error) {
      Modal.error({ title: error.message });
    } finally {
      this.setState({ isLoadingData: false });
    }
  }

  onClose = () => {
    this.setState({ visibleQrCode: false });
    this.onGetQrCodeWarranty();
  };

  onClickBtn = (name) => {
    this.setState({ visibleQrCode: true, actionBtn: name });
  };

  render() {
    const { dataSource, isLoadingData, visibleQrCode, actionBtn } = this.state;

    return (
      <div>
        <div className={"column"}>
          {/*###################### TABLE ######################*/}
          <Table
            title={() => (
              <div>
                <h3>{page_name}</h3>
                <ContainerButton right>
                  <BT
                    style={{
                      margin: "10px",
                      background: "#1890ff",
                      color: "#fff",
                    }}
                    onClick={() => this.onClickBtn("GEN")}
                  >
                    {"Gen QRCode"}
                  </BT>
                  <BT
                    style={{
                      margin: "10px",
                      background: "#2d8700",
                      color: "#fff",
                    }}
                    onClick={() => this.onClickBtn("DOWNLOAD")}
                  >
                    {"DownLoad QRCode"}
                  </BT>
                </ContainerButton>
              </div>
            )}
            columns={ColumnQRCode()}
            dataSource={dataSource}
            bordered
            pagination={{ pageSize: 20 }}
            loading={isLoadingData}
            scroll={{ x: true }}
          />
          <GenQRCodeModal
            visible={visibleQrCode}
            onClose={() => this.onClose()}
            actionBtn={actionBtn}
          />
        </div>
      </div>
    );
  }
}


export default GenQRCodeScene;

