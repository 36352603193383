export const image = {
  rizz_logo: require("./rizz_logo.jpg"),
  brandblack: require("./brandblack.jpg"),
  brandwhite: require("./brandwhite.jpg"),
  calendar: require("./calendar.png"),
  focus_brand: require("./focus_logo.png"),
  focus: require("./logo.png"),
  logo_focusform: require("./focus_logoreport.png"),
  focus_brand_black: require("./focus_black.png"),
  logo_jt: require("./logo_jt.jpg"),
  kerry_logo: require("./kerry_logo.png"),
};
