import React from 'react';
import { Input as In, Row } from 'antd';
import { Label } from '../styles/styled';

export const Input = ({ placeholder, label, error, name, value, onChange = () => { }, width = '30%', onKeyPress = e => { } }) => {
    return <Row style={{ flexDirection: 'row', marginBottom: '10px' }}>
        <Label
            //    style={{ color: '#fff', width: '34%', textAlign: 'left' }}>
            style={{ color: '#24231D', width: '34%', textAlign: 'left' }}>
            {placeholder}
        </Label>

        {name === 'password' ?
            <In.Password style={{ width }}
                placeholder={placeholder}
                value={value}
                onChange={e => onChange(e)}
                onKeyPress={e => onKeyPress(e)}
            /> :
            <In
                style={{ width }}
                placeholder={placeholder}
                value={value}
                onChange={e => onChange(e)}
                onKeyPress={e => onKeyPress(e)}
            />
        }
        {error && <div>Error</div>}

    </Row>
}