import React, { Component } from "react";
import { observer } from "mobx-react";
import {
  Row,
  Col,
  Timeline,
  Icon,
  Button as BT,
  Modal,
  Spin,
  Input,
  message,
  Empty,
} from "antd";
import autobind from "autobind-decorator";
import moment from "moment";
import { BtnGoback } from "../../../components/Button";
import {
  POST,
  FOCUS_DETAILCLAIM,
  FOCUS_UPDATECLAIM_STATUS,
} from "../../../service_focus";
import { withRouter } from "react-router-dom";
import { color, STORE_FOCUS } from "../../../resource";
import { ModalReject } from "../../../components/Modal";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const fn = require("../../../functions/functions");

const { confirm } = Modal;
const styles = {
  font: {
    fontSize: "0.4cm",
    marginTop: 3,
  },
  border: {
    border: "0.5px",
    borderStyle: "solid",
    borderColor: "#CCCCCC",
  },
  border_part1: {
    border: "0.5px",
    borderStyle: "solid",
    borderColor: "#CCCCCC",
    marginTop: 15,
  },
};

@observer
class DetailClaimScene extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    this.state = {
      claim_doc: query.get("id"),
      loading: true,
      user: [],
      address: [],
      claim: [],
      product: [],
      visible: false,
      reject_detail: "",
      vb_reject: false,
      isLoadingReject: false,
      isOpen: false,
    };
  }

  componentDidMount() {
    this.onFetchClaimById();
  }

  @autobind
  async onFetchClaimById() {
    const { claim_doc } = this.state;

    try {
      let res = await POST(FOCUS_DETAILCLAIM, { no_claim: claim_doc });

      if (res.success) {
        let { user, address, product, pay, no_warranty, claim } = res.result;

        this.setState({
          loading: false,
          user,
          address,
          product,
          status: user.status,
          claim_id: claim_doc,
          reject_detail: user.reject_detail,
          pay,
          no_warranty,
          user_id: user.user_id,
          type: claim.type,
          item_code: product.itemcode,
          claim,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  @autobind
  showModal() {
    this.setState({
      visible: true,
    });
  }
  autobind;
  rejectModal() {
    this.setState({
      vb_reject: true,
    });
  }
  @autobind
  onChange(e, ref) {
    this.setState({
      [ref]: e,
    });
  }
  @autobind
  async handleOk() {
    const { no_warranty, user_id,claim_id } = this.state;
    this.setState({ loading: true });

    try {
      let res = await POST(FOCUS_UPDATECLAIM_STATUS, {
        no_claim:claim_id,
        no_warranty,
        status: "USED",
        user_id,
        ems_tracting: "",
        reject_detail: "",
      });
      if (res.success) {
        this.onFetchClaimById();
        this.setState({
          visible: false,
        });
        // push history
        this.props.history.push("/focus/claim");
      }
    } catch (error) {
      console.log(error);
    }
  }
  @autobind
  handleCancel() {
    this.setState({
      visible: false,
    });
  }
  @autobind
  gotoEdit(claim_doc) {
    this.props.history.push(`/focus/claim_edit?id=${claim_doc}`);
  }
  @autobind
  goBack() {
    this.props.history.goBack();
  }

  @autobind
  async handlereject() {
    const { no_warranty, reject_detail, user_id } = this.state;
    if (reject_detail === "") {
      message.error("กรุณาใส่เหตุผลที่ไม่อนุมัติ");
    } else {
      this.setState({
        isLoadingReject: true,
      });
      try {
        let res = await POST(FOCUS_UPDATECLAIM_STATUS, {
          no_warranty,
          status: "REJECT",
          reject_detail,
          user_id,
          ems_tracting: "",
        });
        if (res.success) {
          this.onFetchClaimById();
          this.setState({
            vb_reject: false,
            isLoadingReject: false,
          });
          // push history
          this.props.history.push("/focus/claim");
        }
      } catch (error) {}
    }
  }
  @autobind
  cancelreject() {
    this.setState({
      vb_reject: false,
    });
  }
  @autobind
  clickImage(src_image) {
    console.log(src_image);
    this.setState({
      zoomImage: src_image,
      isOpen: true,
    });
  }

  @autobind
  renderTypeClaim(type) {
    return STORE_FOCUS.map((val) => {
      if (val.original_name === type) {
        return val.thai_name;
      }
    });
  }

  render() {
    const {
      isOpen,
      reject_detail,
      claim_id,
      user,
      address,
      product,
      status,
      visible,
      loading,
      pay,
      type,
      item_code,
      claim,
    } = this.state;

    const handler =
      status === 1 || status === 3 || status === 4 || type === "STORE"
        ? false
        : true;

    if (loading) {
      return (
        <div className="example" style={{ width: "100%" }}>
          <Spin />
        </div>
      );
    }
    return (
      <div>
        <BtnGoback title={"กลับ"} onClick={() => this.goBack()}></BtnGoback>
        <Modal
          title={<p style={{ textAlign: "center" }}>เหตุผลที่ไม่อนุมัติ</p>}
          visible={this.state.vb_reject}
          onOk={this.handlereject}
          onCancel={this.cancelreject}
          width={"30vw"}
          confirmLoading={this.state.isLoadingReject}
          cancelText={<font style={{ fontSize: "1vw" }}>ยกเลิก</font>}
          okText={<font style={{ fontSize: "1vw" }}>ยืนยัน</font>}
          okButtonProps={{
            style: {
              backgroundColor: color.rizz_primary,
              color: "#fff",
              borderColor: color.rizz_primary,
            },
          }}
        >
          <ModalReject
            obj={{ reject_detail }}
            onChange={(e, ref) => this.onChange(e, ref)}
          />
        </Modal>
        {isOpen && (
          <Lightbox
            mainSrc={this.state.zoomImage}
            onCloseRequest={() => this.setState({ isOpen: false })}
          />
        )}
        <Modal
          title="ยืนยันการอนุมัติยอดการโอน"
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={"30vw"}
          cancelText={<font style={{ fontSize: "1vw" }}>ยกเลิก</font>}
          okText={<font style={{ fontSize: "1vw" }}>ยืนยัน</font>}
        >
          <p>กรุณากดยืนยันเพื่ออนุมัติยอดเงิน {claim_id}</p>
        </Modal>
        {/* <Edit address={address.zipcode}></Edit> */}
        {/* #################################   สถานะ  #################################*/}
        <Row>
          <Col span={6} push={18}>
            <div
              style={{
                border: "0.5px",
                borderStyle: "solid",
                borderColor: "#FFFFFF",
                marginLeft: "0.8vw",
              }}
            >
              <div
                className={"column"}
                style={{
                  margin: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h3>สถานะ</h3>
              </div>
              {type === "STORE" ? (
                <div
                  className={"column"}
                  style={{
                    margin: 10,
                    marginTop: -20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Timeline>
                    <Timeline.Item
                      dot={
                        <Icon
                          type="check-circle"
                          style={{
                            fontSize: status === 2 ? "26px" : "20px",
                            color: status === 2 ? color.dplus_main : "black",
                          }}
                        />
                      }
                      style={{ marginTop: 20 }}
                    >
                      <font className={"text-status"}>รอรับบริการ</font>
                    </Timeline.Item>

                    <Timeline.Item
                      dot={
                        <Icon
                          type="check-circle"
                          style={{
                            fontSize: status === 3 ? "26px" : "20px",
                            color: status === 3 ? color.dplus_main : "black",
                          }}
                        />
                      }
                      style={{ marginTop: 15 }}
                    >
                      <font className={"text-status"}>เคลมเสร็จเรียบร้อย</font>
                    </Timeline.Item>
                  </Timeline>
                </div>
              ) : (
                <div
                  className={"column"}
                  style={{
                    margin: 10,
                    marginTop: -20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Timeline>
                    <Timeline.Item
                      dot={
                        <Icon
                          type="check-circle"
                          style={{
                            fontSize: status === 1 ? "26px" : "20px",
                            color: status === 1 ? color.dplus_main : "black",
                          }}
                        />
                      }
                      style={{ marginTop: 20 }}
                    >
                      <font className={"text-status"}>รอชำระเงิน</font>
                    </Timeline.Item>
                    <Timeline.Item
                      dot={
                        <Icon
                          type="check-circle"
                          style={{
                            fontSize: status === 2 ? "26px" : "20px",
                            color: status === 2 ? color.dplus_main : "black",
                          }}
                        />
                      }
                      style={{ marginTop: 15 }}
                    >
                      <font className={"text-status"}>รอการตรวจสอบ</font>
                    </Timeline.Item>
                    <Timeline.Item
                      dot={
                        <Icon
                          type="check-circle"
                          style={{
                            fontSize: status === 3 ? "26px" : "20px",
                            color: status === 3 ? color.dplus_main : "black",
                          }}
                        />
                      }
                      style={{ marginTop: 15 }}
                    >
                      <font className={"text-status"}>ชำระเงินเรียบร้อย</font>
                    </Timeline.Item>
                    <Timeline.Item
                      dot={
                        <Icon
                          type="check-circle"
                          style={{
                            margintop: 5,
                            fontSize: status === 4 ? "26px" : "20px",
                            color: status === 4 ? "green" : "black",
                          }}
                        />
                      }
                      style={{ marginTop: 15 }}
                    >
                      <font className={"text-status"}>จัดส่งเรียบร้อย</font>
                    </Timeline.Item>
                    <Timeline.Item
                      dot={
                        <Icon
                          type="check-circle"
                          style={{
                            margintop: 5,
                            fontSize: status === 99 ? "26px" : "20px",
                            color: status === 99 ? color.dplus_main : "black",
                          }}
                        />
                      }
                      style={{ marginTop: 15 }}
                    >
                      <font className={"text-status"}>ไม่อนุมัติ</font>
                    </Timeline.Item>
                  </Timeline>
                </div>
              )}
            </div>
          </Col>
          {/* #################################   ข้อมูลส่วนตัว  #################################*/}
          <Col span={18} pull={6}>
            <div
              style={{
                border: "0.5px",
                borderStyle: "solid",
                borderColor: "#CCCCCC",
                margin: 15,
              }}
            >
              <div className={"column"} style={{ margin: 10 }}>
                <h3>ข้อมูลส่วนตัว</h3>
              </div>

              <div
                className={"column"}
                style={{ margin: 15, marginTop: -15, marginLeft: 25 }}
              >
                <div className={"row-between"}>
                  {/* <font style={styles.font}>Line Name</font> */}
                  {/* <font style={styles.font}>{user.line_name}</font> */}
                </div>
                <div className={"row-between"} style={{ marginTop: 10 }}>
                  <font style={styles.font}>ชื่อ-นามสกุล</font>
                  <font style={styles.font}>{user.name}</font>
                </div>
                <div className={"row-between"} style={{ marginTop: 10 }}>
                  <font style={styles.font}>หมายเลขโทรศัพท์</font>
                  <font style={styles.font}>{user.telephone}</font>
                </div>
                <div className={"row-between"} style={{ marginTop: 10 }}>
                  <div>
                    <font style={styles.font}>ที่อยู่</font>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>
                      <font style={{ ...styles.font, marginLeft: 10 }}>ต.</font>
                      <font style={{ ...styles.font, marginLeft: 3 }}>
                        {address.district}
                      </font>
                    </div>
                    <div>
                      <font style={{ ...styles.font, marginLeft: 10 }}>อ.</font>
                      <font style={{ ...styles.font, marginLeft: 3 }}>
                        {address.amphure}
                      </font>
                    </div>
                    <div>
                      <font style={{ ...styles.font, marginLeft: 10 }}>จ.</font>
                      <font style={{ ...styles.font, marginLeft: 3 }}>
                        {address.province}
                      </font>
                    </div>
                    <div>
                      <font style={{ ...styles.font, marginLeft: 10 }}>
                        รหัสไปรษณีย์
                      </font>
                      <font style={{ ...styles.font, marginLeft: 3 }}>
                        {address.zipcode}
                      </font>
                    </div>
                  </div>
                </div>
                <div className={"row-between"} style={{ marginTop: 10 }}>
                  <font style={styles.font}>เพิ่มเติม</font>
                  <font style={styles.font}>{address.other}</font>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {/* #################################   button  #################################*/}
        <Row>
          <Col span={6} push={18}></Col>
          {/* #################################   ข้อมูลการแจ้งเคลม  #################################*/}
          <Col span={9} pull={6}>
            <div
              style={{
                border: "0.5px",
                borderStyle: "solid",
                borderColor: "#CCCCCC",
                margin: 15,
                marginTop: -3,
              }}
            >
              <div className={"column"} style={{ margin: 10 }}>
                <h3>ข้อมูลการแจ้งเคลม</h3>
              </div>

              <div
                className={"column"}
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                {user.url ? (
                  <img
                    src={user.url}
                    alt="new"
                    style={{ width: "6cm", height: "9cm", marginBottom: 25 }}
                    onClick={() => this.clickImage(user.url)}
                  />
                ) : (
                  <div
                    style={{ width: "6cm", height: "9cm", marginBottom: 25 }}
                  ></div>
                )}
              </div>
            </div>
          </Col>
          {/* #################################   รายละเอียดการเคลม  #################################*/}
          <Col span={9} pull={6}>
            <div
              style={{
                border: "0.5px",
                borderStyle: "solid",
                borderColor: "#CCCCCC",
                margin: 15,
                marginTop: -3,
              }}
            >
              <div style={{ margin: 15, marginLeft: 15 }}>
                <div className={"row-between"} style={{ marginTop: 20 }}>
                  <font style={styles.font}>เลขที่การเคลม</font>
                  <font style={styles.font}>{claim_id}</font>
                </div>
                <div className={"row-between"} style={{ marginTop: 20 }}>
                  <font style={styles.font}>รหัสลงทะเบียนสินค้า</font>
                  <font style={styles.font}>{product.product_id}</font>
                </div>
                <div className={"row-between"} style={{ marginTop: 20 }}>
                  <font style={styles.font}>ชื่อสินค้า</font>
                  <font style={styles.font}>{product.product_name}</font>
                </div>
                <div className={"row-between"} style={{ marginTop: 20 }}>
                  <font style={styles.font}>รุ่น</font>
                  <font style={styles.font}>{user.brandmobile}</font>
                </div>
                <div className={"row-between"} style={{ marginTop: 20 }}>
                  <font style={styles.font}>วันที่แจ้งเคลม</font>
                  <font style={styles.font}>
                    {moment(user.claimdate).format("DD-MM-YYYY HH:mm")}
                  </font>
                </div>
                <div className={"row-between"} style={{ marginTop: 20 }}>
                  <font style={styles.font}>อาการเสีย</font>
                  <font style={styles.font}>{user.description}</font>
                </div>
                <div className={"row-between"} style={{ marginTop: 20 }}>
                  <font style={styles.font}>รหัสสินค้า</font>
                  <font style={styles.font}>{item_code}</font>
                </div>
                <div className={"row-between"} style={{ marginTop: 20 }}>
                  <font style={styles.font}>ประเภทการเคลม</font>
                  <font style={styles.font}>{this.renderTypeClaim(type)}</font>
                </div>
                {type !== "ONLINE" && type !== "COMPANY" ? (
                  <>
                    {/* <div className={"row-between"} style={{ marginTop: 20 }}>
                      <font style={styles.font}>ช่องทางการลงทะเบียน</font>
                      <font style={styles.font}>
                        <font
                          style={styles.font}
                        >{`${claim.warranty_branch_name || ""}`}</font>
                      </font>
                    </div> */}

                    <div className={"row-between"} style={{ marginTop: 20 }}>
                      <font style={styles.font}>ร้าน/สาขาเคลม</font>
                      <font style={styles.font}>
                        <font style={styles.font}>{`${claim.claim_branch_name || ""}`}</font>
                      </font>
                    </div>
                    <div className={"row-between"} style={{ marginTop: 20 }}>
                      <font style={styles.font}>วันที่จองเคลม</font>
                      <font style={styles.font}>
                        <font style={styles.font}>{`${
                          !!claim.book_date ? claim.book_date : ""
                        }`}</font>
                      </font>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div className={"row-between"} style={{ marginTop: 20 }}>
                  <font style={styles.font}>หมายเหตุ</font>
                  <font>{user.note}</font>
                </div>

                <div
                  className={"column"}
                  style={{
                    marginTop: 10,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <font style={{ fontSize: "0.4cm", marginTop: 15 }}>
                    แจ้งเคลมมาแล้ว {fn.dateDiff(user.claimdate)} วัน
                  </font>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={6} push={18}>
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "flex-end",
                margin: 15,
                marginTop: -30,
                // height: "11cm",
                height: "auto",
              }}
            >
              {type ? (
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    width: "30cm",
                    marginBottom: 20,
                  }}
                >
                  <BT
                    type="primary"
                    style={{ width: "6vw", height: "20vw" }}
                    onClick={() => this.gotoEdit(claim_id)}
                  >
                    แก้ไข
                  </BT>

                  <BT
                    type="primary"
                    style={{ width: "6vw", height: "20vw" }}
                    onClick={() => this.rejectModal()}
                    disabled={
                      status === 3 ||
                      status === 4 ||
                      status === 99 ||
                      type === "STORE" ||
                      type === "FOCUS"
                        ? true
                        : false
                    }
                  >
                    ไม่อนุมัติ
                  </BT>
                </div>
              ) : (
                ""
              )}
            </div>
            <div
              className={"column"}
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginTop: -30,
              }}
            >
              {type ? (
                <BT
                  type="primary"
                  style={{
                    width: "12vw",
                    height: "20vw",
                    backgroundColor: color.dplus_main,
                  }}
                  onClick={() => this.showModal()}
                  disabled={!handler || type === "STORE" || type === "FOCUS"}
                >
                  อนุมัติ
                </BT>
              ) : (
                ""
              )}
            </div>
          </Col>
          {/* #################################   ข้อมูลส่วนตัว  #################################*/}
          {claim.type !== "COMPANY" ? (
            ""
          ) : (
            <>
              <Col span={18} pull={6}>
                <div
                  style={{
                    border: "0.5px",
                    borderStyle: "solid",
                    borderColor: "#CCCCCC",
                    margin: 15,
                  }}
                >
                  <div className={"column"} style={{ margin: 10 }}>
                    <h3>ข้อมูลการโอนเงิน</h3>
                  </div>

                  {pay.url_pay ? (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{
                          flex: 1,
                          justifyContent: "center",
                          marginBottom: 10,
                        }}
                      >
                        {/* {pay.url_pay ? <img src={pay.url_pay} alt="new" style={{ width: '6cm', height: '7cm', marginBottom: 50 }} /> : <div></div>} */}
                        <div
                          className={"column"}
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: 35,
                          }}
                        >
                          {pay.url_pay ? (
                            <img
                              src={pay.url_pay}
                              alt="new"
                              style={{
                                width: "6cm",
                                height: "7cm",
                                marginBottom: 50,
                              }}
                              onClick={() => this.clickImage(pay.url_pay)}
                            />
                          ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          )}
                        </div>
                      </div>
                      <div style={{ flex: 1, marginLeft: -10 }}>
                        {/* <div
                          className={"row-between"}
                          style={{ marginTop: 20, marginLeft: -20 }}
                        >
                          <font style={styles.font}>ธนาคาร</font>
                          <font style={styles.font}>{pay.bank_pay || "-"}</font>
                        </div> */}
                        <div
                          className={"row-between"}
                          style={{ marginTop: 20, marginLeft: -20 }}
                        >
                          <font style={styles.font}>วันที่โอน</font>
                          <font style={styles.font}>{pay.date_pay}</font>
                        </div>
                        <div
                          className={"row-between"}
                          style={{ marginTop: 20, marginLeft: -20 }}
                        >
                          <font style={styles.font}>เวลาที่โอน</font>
                          <font style={styles.font}>{pay.time_pay}</font>
                        </div>
                        <div
                          className={"row-between"}
                          style={{ marginTop: 20, marginLeft: -20 }}
                        >
                          <font style={styles.font}>จำนวนเงิน</font>
                          <font style={styles.font}>{pay.money_pay}</font>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className={"column"}
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 30,
                      }}
                    >
                      <Empty
                        style={{
                          width: "6cm",
                          height: "7cm",
                          marginBottom: 50,
                        }}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                      />
                    </div>
                  )}
                </div>
              </Col>
            </>
          )}
        </Row>
      </div>
    );
  }
}

export default withRouter(DetailClaimScene);
