import React, { Component } from "react";
import { observer } from "mobx-react";
import autobind from "autobind-decorator";
import { BtnGoback } from "../../../components/Button";
import { Icon, Input, Row, Col, Button as BT, Modal, Select, } from "antd";
import { GET, POST, FOCUS_DETAILCLAIM, ACTIVE_CLAIM__BRANCH, GET_OPTION_BRANCH, FOUCUS_WARRANTYALL,GET_WARRANTY_BY_WARRANTYNO_OR_CLAIMNO } from "../../../service_focus";
import { Font } from "../../../styles/styled";
import { ThemeProvider } from "styled-components";
import { checkstatus, convertStatus } from "../../../functions/functions";

const styles = {
  font: {
    fontSize: "0.4cm",
    marginTop: 3,
    marginBottom: 5,
    marginLeft: 5,
  },
};


@observer
class ActiveClaimScene extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      hiddenform: true,
      errordecode: true,
      errorclaim: false,
      errorwarranty: false,
      claimdoc: "",
      warrantydoc: "",
      unactive_code: "",
      branch: "",
      option_branch: [],
      no_warranty: "",
      line_id: "",
      name: "",
      address: "",
      product: "",
      telephone: "",
      modelmobile: "",
      status: "",
      warranty_colect: "",
      warranty_colect_color: "",
      error_color_warranty: "",
      errorwarranty_detail: "",
      errorwarranty: "",
      claim_colect: "",
      claim_colect_color: "",
      error_color_claim: "",
      disabledClaim: false,
      disabledWarranty: false

    };
  }

  getOptionStore = async () => {
    try {
      const res = await GET(GET_OPTION_BRANCH);
      const { success, result } = res;
      if (success) {
        const option = result.map((el) => {
          return {
            value: `${el.store_no}|${el.branch_no}|${el.branch_name}|${el.shipto_id}|${el.shipto_address}|${el.store_id}`,
            label: el.branch_name,
          };
        });

        this.setState({
          option_branch: option,
          dataOption: result
        });

      }
    } catch (error) {
      Modal.error({ title: error.message });
    }
  };

  @autobind
  goBack() {
    this.props.history.goBack();
  }

  @autobind
  async confirm() {
    try {

      const { claimdoc, branch, unactive_code, warrantydoc, line_id, status } = this.state
      if (status == 99 && claimdoc) {
        return Modal.error({ title: "ไม่สามารถตัดรับประกันได้!!", content: " เนื่องจากอยู่ในสถานะไม่อนุมัติ" })
      }
      const store_no = branch.split("|")[0]
      const branch_no = branch.split("|")[1]
      const branch_name = branch.split("|")[2]
      const shipto_id = branch.split("|")[3]
      const shipto_address = branch.split("|")[4]
      const store_id = branch.split("|")[5]
      const payload = {
        no_claim: claimdoc,
        store_no,
        branch_no,
        branch_name,
        shipto_id,
        shipto_address,
        unactive_code,
        no_warranty: warrantydoc,
        line_id,
        store_id
      }
      console.log({ payload })
      const res = await POST(ACTIVE_CLAIM__BRANCH, payload)
      if (res.success) {
        Modal.success({ title: res.result })
        this.clear('')
      }
    } catch (error) {
      Modal.error({ title: error.message });
    }



  }

  @autobind
  async onSearch(event) {
    if (event.key === "Enter") {
      const { claimdoc, warrantydoc, disabledClaim,disabledWarranty } = this.state
      try {
        let  no_claim = 0
        let  no_warranty_ = 0
        let error_search = { claim_colect: "close-circle", error_color_claim: "red" }
        if(claimdoc !== "" && !disabledClaim){
          no_claim = claimdoc
          this.setState({ claim_colect: 'loading' })
        }

        if(warrantydoc !== "" && !disabledWarranty){
          no_warranty_ = warrantydoc
          error_search = { claim_colect: "close-circle", error_color_claim: "red" }
        }

        if (claimdoc !== "" && !disabledClaim) {
          this.setState({ claim_colect: 'loading' })
          const res = await POST(FOCUS_DETAILCLAIM, {no_claim:claimdoc});
          const { success, message, result } = res
          if (!success) {
            Modal.error({
              title: "แจ้งเตือน",
              content: message,
            })

            this.setState({...error_search})
            return
          }
          const { user, address, product, no_warranty } = result;
          this.setState({
            name: user.name,
            address: address.address_name,
            product: product.product_name,
            status: user.status,
            warrantydoc: no_warranty,
            telephone: user.telephone,
            modelmobile: product.modelmobile,
            status_detail: checkstatus(user.status),
            claim_colect: "check-circle",
            claim_colect_color: "certain-category-icon-colect",
            error_color_claim: "green",
            errorclaim_detail: "",
            line_id: user.line_id
          });
        } else {
          this.setState({ warranty_colect: 'loading' })
          const res = await POST(GET_WARRANTY_BY_WARRANTYNO_OR_CLAIMNO, { no_warranty : +warrantydoc,no_claim:0});
          const { success, message, result } = res
          if (!success) {
            Modal.error({
              title: "แจ้งเตือน",
              content: message,
            })
            this.setState({ warranty_colect: "close-circle", error_color_warranty: "red" })
            return
          }
          const { name, nameproduct, tel, modelmobile, no_warranty, no_claim, status } = result[0];
          console.log({ name, nameproduct, tel, modelmobile })
          this.setState({
            name,
            product: nameproduct,
            status: convertStatus(status),
            status_detail: checkstatus(convertStatus(status)),
            telephone: tel,
            modelmobile,
            warrantydoc: no_warranty,
            claimdoc: no_claim,
            warranty_colect: "check-circle",
            warranty_colect_color: "certain-category-icon-colect",
            error_color_warranty: "green",
            errorwarranty_detail: "",
          });
        }
        this.getOptionStore()

      } catch (error) {
        console.log(error);
      }
    }
  }

  @autobind
  onChange(e, ref) {
    if (ref === 'warrantydoc') {
      this.setState({ disabledClaim: true })
    }
    if (ref === 'claimdoc') {
      this.setState({ disabledWarranty: true })
    }

    this.setState({ [ref]: e, })

  }

  @autobind
  clear(ref) {

    if (ref === 'btnClear') {
      this.setState({
        shipping_id: '',
        shipping_name: '',
        shipping_type: '',
        claimdoc: '',
      })
    }
    else if (ref === 'btnConfirm') {
      this.setState({ claimdoc: '' })
    }

    this.setState({
      name: "",
      telephone: "",
      brandmobile: "",
      modelmobile: "",
      address: "",
      product: "",
      claimdoc: "",
      branch: "",
      warrantydoc: "",
      errorclaim_detail: "",
      claim_colect: "",
      claim_colect_color: "",
      error_color_claim: "",
      errorclaim: false,
      decode_colect: "",
      decode_colect_color: "",
      error_color_decode: "",
      status_detail: "",
      warranty_colect: "",
      warranty_colect_color: "",
      error_color_warranty: "",
      errorwarranty_detail: "",
      errorwarranty: "",
      disabledClaim: false,
      disabledWarranty: false
    });
  }

  render() {
    const {
      claimdoc,
      claim_colect,
      claim_colect_color,
      errorclaim,
      unactive_code,
      errorclaim_detail,
      branch,
      option_branch,
      warrantydoc,
      hiddenform,
      name,
      address,
      product,
      status,
      telephone,
      modelmobile,
      warranty_colect,
      warranty_colect_color,
      error_color_warranty,
      errorwarranty_detail,
      errorwarranty,
      disabledClaim,
      disabledWarranty
    } = this.state;

    const theme = {
      hidden: hiddenform,
    };
    return (
      <div labelCol={{ span: 5 }} wrapperCol={{ span: 12 }}>
        <BtnGoback title={"กลับ"} onClick={() => this.goBack()}></BtnGoback>
        <Row>
          <Col span={12}>
            <div className={"border-claim"} style={{ margin: 15 }}>
              <div className={"column"} style={{ margin: 10 }}>
                <h3>ข้อมูลตัดรับประกัน <font color="red">(เลือกกรอกเลขที่เคลมหรือเลขที่ลงทะเบียน อย่างใดอย่างหนึ่ง)</font></h3>
              </div>
              <div className={"column-claim-cr"}>
                <p style={styles.font}>เลขที่ลงทะเบียน <font color="red">(Enter เพื่อตรวจสอบเลขที่ลงทะเบียน)</font></p>
                <Input
                  suffix={
                    <Icon
                      type={warranty_colect}
                      style={{ fontSize: "16px" }}
                      className={warranty_colect_color}
                    />
                  }
                  style={{ width: "70%" }}
                  placeholder="กรอกเลขที่ลงทะเบียน"
                  disabled={disabledWarranty}
                  ref="warrantydoc"
                  value={warrantydoc}
                  autoFocus
                  onKeyPress={(e) =>
                    this.onSearch(e)
                  }
                  onChange={(e) => this.onChange(e.target.value, "warrantydoc")}
                />

                <font
                  style={{
                    fontSize: "0.3cm",
                    color: error_color_warranty,
                    marginTop: 5,
                    marginLeft: 5,
                  }}
                  hidden={!errorwarranty}
                >
                  {errorwarranty_detail}
                </font>
              </div>
              <div className={"column-claim-cr"}>
                <p style={styles.font}>เลขที่เคลม <font color="red">(Enter เพื่อตรวจสอบเลขที่เคลม)</font></p>
                <Input
                  suffix={
                    <Icon
                      type={claim_colect}
                      style={{ fontSize: "16px" }}
                      className={claim_colect_color}
                    />
                  }
                  style={{ width: "70%" }}
                  placeholder="กรอกเลขที่เคลม"
                  ref="claimdoc"
                  value={claimdoc}
                  disabled={disabledClaim}
                  autoFocus
                  onKeyPress={(e) =>
                    this.onSearch(e)
                  }
                  onChange={(e) => this.onChange(e.target.value, "claimdoc")}
                />

                <font
                  style={{
                    fontSize: "0.3cm",
                    color: this.state.error_color_claim,
                    marginTop: 5,
                    marginLeft: 5,
                  }}
                  hidden={!errorclaim}
                >
                  {errorclaim_detail}
                </font>
              </div>
              <div className={"column-claim-cr"} style={{ marginTop: -5 }}>
                <p style={styles.font}>สาขาที่เคลม <font color="red">*</font></p>

                <Select
                  showSearch
                  style={{ width: "70%" }}
                  onChange={(value) => this.onChange(value, "branch")}
                  placeholder="กรุณาเลือกสาขาที่เคลม"
                  ref="branch"
                  optionFilterProp="children"
                  value={branch}
                  filterOption={(input, option) =>
                    option.props.children.indexOf(input) >= 0
                  }
                >
                  {option_branch.map((el, i) => {
                    return <Select.Option value={el.value}>{el.label}</Select.Option>;
                  })}


                </Select>
              </div>
              <div className={"column-claim-cr"} style={{ marginTop: -5 }}>
                <p style={styles.font}>QRcode ที่ตัดรับประกัน</p>
                <Input
                  style={{ fontSize: "16px", width: "70%" }}
                  className={claim_colect_color}
                  placeholder="QRcode ที่ตัดรับประกัน"
                  ref="unactive_code"
                  value={unactive_code}
                  onChange={(e) => this.onChange(e.target.value, "unactive_code")}
                />
              </div>
            </div>
            <div style={{ margin: 15, marginTop: 20 }}>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  margin: 15,
                }}
              >
                <BT
                  style={{
                    width: "10vw",
                    height: "20vw",
                    background: "#FFFFFF",
                  }}
                  onClick={() => this.clear("btnClear")}
                  hidden={(!claimdoc && !warrantydoc) || !branch}
                >
                  เคลียร์ข้อมูล
                </BT>
                <BT
                  className="btn-active"
                  disabled={(!claimdoc && !warrantydoc) || !branch}
                  onClick={() => this.confirm()}
                >
                  ตัดรับประกัน
                </BT>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className={"border-claim"} style={{ margin: 15 }}>
              <div className={"column"} style={{ margin: 10 }}>
                <h3>ข้อมูลเอกสาร</h3>
              </div>

              <ThemeProvider theme={theme}>
                <div
                  className={"column"}
                  style={{ margin: 15, marginTop: -10, marginLeft: 25 }}
                >
                  <div className={"row-between"} style={{ marginTop: 15 }}>
                    <Font text="เลขที่ลงทะเบียน" />
                    <Font text={warrantydoc} />
                  </div>
                  <div className={"row-between"} style={{ marginTop: 15 }}>
                    <Font text="เลขที่เคลม" />
                    <Font text={claimdoc} />
                  </div>
                  <div className={"row-between"} style={{ marginTop: 15 }}>
                    <Font text="ชื่อ-นามสกุล" />
                    <Font text={name} />
                  </div>
                  <div className={"row-between"} style={{ marginTop: 15 }}>
                    <Font text="หมายเลขโทรศัพท์" />
                    <Font text={telephone} />
                  </div>
                  <div className={"row-between"} style={{ marginTop: 15 }}>
                    <Font text="ที่อยู่" />
                    <Font text={address } />
                  </div>
                  <div className={"row-between"} style={{ marginTop: 15 }}>
                    <Font text="ชื่อสินค้า" />
                    <Font text={product} />
                  </div>

                  <div className={"row-between"} style={{ marginTop: 15 }}>
                    <Font text="รุ่นมือถือ" />
                    <Font text={modelmobile} />
                  </div>
                  <div
                    className={"row-between"}
                    style={{
                      marginTop: 15,
                      marginBottom: 25,
                      color: status === 4 ? "green" : "",
                    }}
                  >
                    <Font text="สถานะ" />
                    <Font text={this.state.status_detail} />
                  </div>
                </div>
              </ThemeProvider>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ActiveClaimScene;
