import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Icon, Upload, Input, Row, Col, Button as BT, DatePicker, Select, Modal, Spin, Form, message, Radio } from 'antd'
import autobind from 'autobind-decorator';
import { POST, RIZZ_GETUSERID, RIZZ_UPSERTCLAIM, UPLOAD } from '../../../service'
import moment from 'moment'
import InputAddress from 'react-thailand-address-autocomplete'
import { BtnGoback } from '../../../components/Button'


const fn = require('./../../../functions/functions')

@Form.create({ name: 'CreateClaimScene' })
@observer
class CreateClaimScene extends Component {
    constructor(props) {
        super(props)
        this.props.form.setFieldsValue({ line_id: '', name: '', telephone: '', other: '' })

        this.state = {
            claimdate: moment(new Date()),
            loading: false,
            product_id: '',
            register_date: moment(new Date()),
            imageUrl: '',
            dataSource: [],
            lineid_colect: '',
            data: [],
            status: 0,
            note: '',
            _address: [],
            radio: 1

        }
    }


    componentDidMount() {

    }


    @autobind
    async getDetaByLine() {
        const { line_id } = this.state
        console.log(line_id)
        this.setState({
            loading: true
        })
        try {
            const res = await POST(RIZZ_GETUSERID, { line_id })
            const customer = res.result.customer
            if (res.success) {
                console.log('length', res.result.customer.length)
                if (customer !== '') {
                    console.log('if', customer)
                    this.setState({
                        line_id,
                        name: customer.name,
                        telephone: customer.telephone,
                        data: res.result.product,
                        _address: res.result.address,
                        full_address: '',
                        lineid_colect: 'check-circle',
                        lineid_colect_color: 'certain-category-icon-colect',
                        loading: false


                    })
                    this.props.form.setFieldsValue({ line_id, _address: res.result.address, data: res.result.product, name: customer.name, telephone: customer.telephone, full_address: '' })
                }
                else {
                    console.log('else')
                    this.setState({
                        lineid_colect: 'close-circle',
                        lineid_colect_color: 'certain-category-icon-uncolect',
                        loading: false

                    })
                }

            }


        } catch (error) {

        }
    }

    @autobind
    async registerClaim() {
        console.log('register claim')
        let type_sending = true
        const { file, name, telephone, province, district, subdistrict, zipcode, other, description, product_id, status, line_id, claimdate, note, addressId } = this.state
        let arr = { file, name, telephone, province, district, subdistrict, zipcode, other, description, product_id, status, line_id, claimdate, note, addressId, type_sending }
        let formdata = fn.append(arr)

        this.setState({
            loading: true
        })
        try {
            const res = await UPLOAD(RIZZ_UPSERTCLAIM, formdata)
            if (res.success) {
                this.setState({
                    loading: false
                })
                this.clear()
                Modal.success({
                    title: 'บันทึกข้อมูลสำเร็จ',
                    content: `ชุดเอกสารลงทะเบียน ${res.result}`,
                });
            }

        } catch (error) {
            Modal.error({
                title: 'ลงทะเบียนไม่สำเร็จ',
                content: { error },
            });
        }
    }
    handleChange = info => {
        console.log('file', info.file.originFileObj)
        fn.getBase64(info.file.originFileObj, imageUrl =>
            this.setState({
                file: info.file.originFileObj,
                imageUrl,
                loading: false,
            }),
        );

    };

    @autobind
    onChange(e, ref) {
        this.setState({ [ref]: e })
        this.props.form.setFieldsValue({ [ref]: e })
        console.log(ref)
        console.log(e)
        if (ref === 'line_id') {
            this.setState({
                lineid_colect: '',
                name: '',
                telephone: '',
                product_name: ''
            })
            this.props.form.setFieldsValue({

                product_name: '',
                name: '',
                telephone: '',

            })

        }
    }

    onChangeSelect = (value) => {
        console.log(`selected ${value}`);
        const product = this.state.data.find(el => el.register_id === value)
        console.log(product)
        this.setState({
            product_name: product.product_type,
            product_id: product.register_id
        })
    }
    onChangeAdd = (value) => {
        console.log(`selectedAddress ${value}`);
        const address = this.state._address.find(el => el.addressId === value)
        const { other, district, province, zipcode, amphure, addressId } = address
        this.setState({
            other: address.other,
            district: amphure,
            subdistrict: district,
            province, zipcode,
            addressId

        })
        this.props.form.setFieldsValue({ other, district: amphure, subdistrict: district, province, zipcode })

    }
    onSearchProduct = val => {
        console.log('search:', val);
        this.setState({
            other: val

        })
        this.props.form.setFieldsValue({ other: val })

    }
    @autobind
    onKeyPressSearch(event) {
        if (event.key === 'Enter') {
            this.getDetaByLine()
        }
    }
    @autobind
    onSearch() {
        const { line_id } = this.state
        const item = this.state.line.find(el => el.userId === line_id);
        console.log(item)
        if (item) {
            this.setState({

                lineid_colect: 'check-circle',
                lineid_colect_color: 'certain-category-icon-colect'
            })
        }
        else {
            this.setState({
                lineid_colect: 'close-circle',
                lineid_colect_color: 'certain-category-icon-uncolect'
            })
        }
    }

    onSelectAddress = fullAddress => {
        const { subdistrict, district, province, zipcode } = fullAddress
        this.props.form.setFieldsValue({ district, subdistrict, province, zipcode })
        this.setState({
            subdistrict,
            district,
            zipcode,
            province

        })
    }
    @autobind
    onChangeAddress(e, ref) {
        console.log('e.name', [e.target.name])
        console.log(ref, e.target.value)
        this.setState({ [ref]: e.target.value })
    }
    @autobind
    clear() {
        this.props.form.setFieldsValue({
            line_id: '',
            product_name: '',
            claimdate: moment(new Date()),
            imageUrl: '',
            description: '',
            name: '',
            telephone: '',
            lineid_colect: '',
            lineid_colect_color: '',
            subdistrict: '',
            district: '',
            province: '',
            zipcode: '',
            other: '', full_address: ''

        })
        this.setState({
            line_id: '',
            product_name: '',
            claimdate: moment(new Date()),
            imageUrl: '',
            description: '',
            name: '',
            telephone: '',
            lineid_colect: '',
            lineid_colect_color: '',
            subdistrict: '',
            district: '',
            province: '',
            zipcode: '',
            other: '', full_address: ''


        })

    }

    @autobind
    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // Call API
                if (!fn.phonNumberValidate(this.state.telephone)) {
                    message.error('เบอร์โทรของท่านไม่ถูกต้อง');
                }
                else {
                    this.registerClaim()
                }

            }
            else {
                console.log('error')
            }
        });
    }
    onChangeRedio = e => {
        console.log('radio checked', e.target.value);
        if (e.target.value === 2) {
            this.setState({
                addressId: ''
            })
        }
        this.setState({
            radio: e.target.value,
            subdistrict: '',
            district: '',
            province: '',
            zipcode: '',
            other: ''
        });
        this.props.form.setFieldsValue({ other: '', district: '', subdistrict: '', province: '', zipcode: '' })

    };


    @autobind
    goBack() {
        this.props.history.goBack()

    }
    @autobind
    checkdata() {
        const { province, district, subdistrict, zipcode } = this.state
        console.log(province, district, subdistrict, zipcode)
    }

    render() {
        const { radio, _address, full_address, zipcode, district, subdistrict, province, loading, data, other, claimdate, product_name, imageUrl, description, name, line_id, telephone, lineid_colect, lineid_colect_color } = this.state
        console.log(_address.length)
        const uploadButton = (
            <div>
                <Icon style={{ width: '6cm', height: '7cm' }} type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        if (loading) {
            return <div className="example">
                <Spin />
            </div>
        }

        const { getFieldDecorator } = this.props.form;
        getFieldDecorator('CreateClaimScene', { initialValue: [] })
        return (
            <Form labelCol={{ span: 5 }} wrapperCol={{ span: 12 }} onSubmit={this.handleSubmit}>
                <div>
                    <BtnGoback title={'กลับ'} onClick={() => this.goBack()}  ></BtnGoback>
                    <Row>

                        <Col span={10} push={14}>
                            <div className={'border-claim'} style={{ margin: 15 }}>
                                <div className={'column-claim-cr'} >
                                    <font style={{ fontSize: '0.4cm' }}>ข้อมูลการเคลม</font>
                                </div>
                                <div className={'column-claim-cr'} style={{ marginTop: -20 }}>

                                    <Form.Item label="เลือกสินค้าที่ต้องการเคลม">
                                        {getFieldDecorator('product_name', {

                                            rules: [{ required: true, message: 'เลือกสินค้าที่ต้องการเคลม' }],
                                        })(<Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            placeholder="เลือกสินค้าที่ต้องการเคลม"
                                            optionFilterProp="children"
                                            onChange={this.onChangeSelect}
                                            //onSearch={this.onSearchProduct}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            value={product_name}
                                        >
                                            {data.map(item => <Select.Option value={item.register_id}><img src={item.url} alt="av" style={{ width: '1cm', height: '0.5cm' }} />  {item.product_type}</Select.Option>)}

                                        </Select>)}
                                    </Form.Item>

                                </div>
                                <div className={'column-claim-cr'} style={{ marginTop: -30 }} >


                                    <Form.Item label="อาการเสีย">
                                        {getFieldDecorator('description', {

                                            rules: [{ required: true, message: 'กรุณาระบุอาการเสีย' }],
                                        })(<Input style={{ width: '95%' }} defaultValue={description}
                                            value={description}
                                            onChange={e => this.onChange(e.target.value, 'description')}
                                        />)}

                                    </Form.Item>
                                </div>
                                <div className={'column-claim-cr'} style={{ marginTop: -15 }}  >
                                    <font style={{ fontSize: '0.4cm' }}>วันที่แจ้งเคลม</font>

                                    <DatePicker style={{ width: '80%', marginTop: 7 }}
                                        placeholder={'กรุเลือกวันที่แจ้งเคลม'}
                                        value={claimdate}
                                        format={'DD/MM/YYYY'}
                                        onChange={(date) => this.onChange(date, 'claimdate')}
                                    />
                                </div>
                                <div className={'column-claim-cr'} >


                                </div>
                                <div className={'column-claim-cr'} style={{ justifyContent: 'center', alignItems: 'center', marginTop: -40 }}>

                                    <Form.Item label="รูปภาพสินค้า">
                                        {getFieldDecorator('imageUrl', {

                                            rules: [{ required: true, message: 'รูปภาพสินค้า' }],
                                        })(<Upload
                                            name="avatar"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            onChange={this.handleChange}
                                        >
                                            {imageUrl ? <img src={imageUrl} alt="new" style={{ width: '6cm', height: '7cm' }} /> : uploadButton}
                                        </Upload>)}
                                    </Form.Item>

                                </div>

                            </div>

                        </Col>

                        <Col span={14} pull={10}>
                            <div className={'border-claim'} style={{ margin: 15 }}>
                                <div className={'column-claim-cr'}  >
                                    <font style={{ fontSize: '0.4cm' }}>ยื่นเรื่องเคลม</font>

                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: -22 }}>
                                    <div style={{ flex: 1, }}>
                                        <div className={'column-claim-cr'} style={{ marginTop: 5 }} >
                                            <Form.Item label="Line ผู้ใช้">
                                                {getFieldDecorator('line_id', {
                                                    rules: [{ required: true, message: 'กรุณาใส่ Line ผู้ใช้' }],
                                                })(<Input suffix={<Icon type={lineid_colect} style={{ fontSize: '16px' }} className={lineid_colect_color} />} style={{ width: '100%' }}

                                                    value={line_id}
                                                    onKeyPress={e => this.onKeyPressSearch(e)}
                                                    onChange={e => this.onChange(e.target.value, 'line_id')}
                                                />)}
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div style={{ flex: 1 }}>
                                        <div className={'column-claim-cr'} style={{ marginTop: 5 }} >
                                            <Form.Item label="ชื่อ-นามสกุล">
                                                {getFieldDecorator('name', {
                                                    rules: [{ required: false, message: 'กรุณาใส่ ชื่อ-นามสกุล' }],
                                                })(<Input style={{ width: '95%' }} defaultValue={name}
                                                    value={name}
                                                    readonly="readonly"
                                                    onChange={e => this.onChange(e.target.value, 'name')}
                                                />)}

                                            </Form.Item>
                                        </div>
                                    </div>


                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: -50 }}>

                                    <div style={{ flex: 1 }}>
                                        <div className={'column-claim-cr'} >

                                            <Form.Item label="เบอร์โทรศัพท์">
                                                {getFieldDecorator('telephone', {
                                                    rules: [{ required: false, message: 'กรุณาระบุเบอร์โทร' }],
                                                })(<Input style={{ width: '100%' }} defaultValue={telephone}
                                                    value={telephone}
                                                    readonly="readonly"
                                                    onChange={e => this.onChange(e.target.value, 'telephone')}
                                                />)}

                                            </Form.Item>

                                        </div>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <div className={'column-claim-cr'} style={{ marginTop: 70 }}>
                                            {lineid_colect === 'check-circle' ? (<Radio.Group onChange={this.onChangeRedio} value={radio}>
                                                <Radio value={1}>ที่อยู่เดิม</Radio>
                                                {_address.length < 3 ? (<Radio value={2} >เพิ่มที่อยู่ใหม่</Radio>)
                                                    : (<div></div>)}
                                            </Radio.Group>) : (<div></div>)}
                                        </div>
                                    </div>
                                </div>

                                <div className={'column-claim-cr'} style={{ marginTop: -30 }}>
                                    {radio === 1 || _address.length >= 3 ? (<Form.Item label="เลือกที่อยู่">
                                        {getFieldDecorator('full_address', {
                                            rules: [{ required: true, message: 'กรุณากรอกที่อยู่' }],
                                        })(<Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            placeholder="เลือกสินค้าที่ต้องการเคลม"
                                            optionFilterProp="children"
                                            //onChange={this.onChangeOther}
                                            onChange={this.onChangeAdd}
                                            //onChange={e => this.onChange(e.target.value, 'other')}
                                            onSearch={this.onSearchProduct}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }

                                            value={full_address}
                                        >
                                            {_address.map(address => <Select.Option value={address.addressId}>{address.fulladdress}</Select.Option>)}

                                        </Select>)}
                                    </Form.Item>) : (<div></div>)}



                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: -50 }}>
                                    <div style={{ flex: 1 }}>
                                        <div className={'column-claim-cr'} >
                                            <Form.Item label="ตำบล">
                                                {getFieldDecorator('subdistrict', {
                                                    rules: [{ required: true, message: 'กรุณาระบุตำบล' }],
                                                })(<InputAddress style={{ width: '100%' }} address="subdistrict" value={subdistrict}

                                                    onChange={e => this.onChangeAddress(e, 'subdistrict')}
                                                    onSelect={this.onSelectAddress} />)}

                                            </Form.Item>


                                        </div>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <div className={'column-claim-cr'} >

                                            <Form.Item label="อำเภอ">
                                                {getFieldDecorator('district', {
                                                    rules: [{ required: true, message: 'กรุณาเลือกอำเภอ' }],
                                                })(<InputAddress style={{ width: '100%' }} address="district" value={district}

                                                    onChange={e => this.onChangeAddress(e, 'district')}
                                                    onSelect={this.onSelectAddress} />)}

                                            </Form.Item>

                                        </div>
                                    </div>

                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: -50 }}>
                                    <div style={{ flex: 1 }}>
                                        <div className={'column-claim-cr'} >

                                            <Form.Item label="จังหวัด">
                                                {getFieldDecorator('province', {
                                                    rules: [{ required: true, message: 'กรุณาเลือกจังหวัด' }],
                                                })(<InputAddress className={'outline-focus'} style={{ width: '100%' }} address="province" value={province}

                                                    onChange={e => this.onChangeAddress(e, 'province')}
                                                    onSelect={this.onSelectAddress} />)}

                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <div className={'column-claim-cr'} >
                                            <Form.Item label="รหัสไปรษณีย์">
                                                {getFieldDecorator('zipcode', {
                                                    rules: [{ required: true, message: 'กรุณาเลือกรหัสไปรษณี' }],
                                                })(<InputAddress style={{ width: '100%' }} address="zipcode" value={zipcode}

                                                    onChange={e => this.onChangeAddress(e, 'zipcode')}
                                                    onSelect={this.onSelectAddress} />)}

                                            </Form.Item>

                                        </div>
                                    </div>



                                </div>
                                <div className={'column-claim-cr'} style={{ marginTop: -30 }}>
                                    <Form.Item label="เพิ่มเติม">
                                        {getFieldDecorator('other', {
                                            rules: [{ required: true, message: 'กรุณาระบุที่อยู่บ้านเลขที่ ถนนที่ตั้ง' }],
                                        })(<Input style={{ width: '100%' }} defaultValue={other}
                                            value={other}
                                            onChange={e => this.onChange(e.target.value, 'other')}
                                        />)}

                                    </Form.Item>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: -15 }}>

                                    <div style={{ display: 'flex', flex: 1, margin: 20, marginLeft: 30, justifyContent: 'flex-end' }}>
                                        <BT style={{ width: '10vw', height: '20vw', background: '#FFFFFF', color: 'Black' }} onClick={() => this.clear()} hidden={!line_id}   >
                                            เคลียร์ข้อมูล
                                        </BT>
                                        <BT htmlType="submit" style={{ width: '10vw', height: '20vw', background: '#EE220C', color: '#FFFFFF' }}   >
                                            ยื่นเรื่อง
                                        </BT>


                                    </div>

                                </div>


                            </div>
                        </Col>



                    </Row>

                </div >
            </Form>
        )
    }
}

export default CreateClaimScene
