import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Row, Col, Timeline, Icon, Button as BT, Modal, Spin, Input, message } from 'antd'
import autobind from 'autobind-decorator';
import moment from 'moment'
import { Button, BtnGoback } from '../../../components/Button';
import { POST, RIZZ_DETAILCLAIMBYID, RIZZ_UPDATESTATUS } from '../../../service'
import { columnTableClaim, color, image } from '../../../resource';
import { ModalReject } from '../../../components/Modal'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
const fn = require('../../../functions/functions')

const { confirm } = Modal;
const styles = {
    font: {
        fontSize: '0.4cm',
        marginTop: 3
    },
    border: {
        border: '0.5px',
        borderStyle: 'solid',
        borderColor: '#CCCCCC',


    },
    border_part1: {
        border: '0.5px',
        borderStyle: 'solid',
        borderColor: '#CCCCCC',
        marginTop: 15
    }


}

@observer
class DetailClaimScene extends Component {
    constructor(props) {
        super(props)
        const query = new URLSearchParams(this.props.location.search);
        this.state = {
            claim_doc: query.get('id'),
            loading: true,
            user: [],
            address: [],
            product: [],
            visible: false,
            reject_detail: '',
            vb_reject: false,
            isLoadingReject: false,
            isOpen: false,


        }
    }


    componentDidMount() {
        //console.log(this.state.claim_id)

        this.onFetchClaimById()
        // console.log(this.state.data)
    }

    @autobind
    async onFetchClaimById() {

        const { claim_doc } = this.state
        console.log(claim_doc)
        try {
            let res = await POST(RIZZ_DETAILCLAIMBYID, { claim_doc })
            if (res.success) {
                console.log(res.result)

                this.setState({
                    loading: false,
                    user: res.result.detailclaim,
                    address: res.result.detailclaim.address,
                    product: res.result.detailclaim.product,
                    status: res.result.detailclaim.status,
                    claim_id: res.result.detailclaim.claim_id,
                    reject_detail: res.result.detailclaim.reject_detail,
                    register_url: res.result.detailclaim.register_url



                })
                console.log(this.state.data.detailclaim.name)
            }
        } catch (error) {
            console.log(error)
        }
    }

    @autobind
    showModal() {
        this.setState({
            visible: true,
        });
    }
    autobind
    rejectModal() {

        this.setState({
            vb_reject: true
        })
    }
    @autobind
    onChange(e, ref) {
        console.log('reject_detail', e)
        this.setState({
            [ref]: e
        })
    }
    @autobind
    async handleOk() {
        const { claim_doc, status, reject_detail } = this.state
        this.setState({ loading: true, status: 1 })
        console.log('status', status)
        try {
            let res = await POST(RIZZ_UPDATESTATUS, { claim_doc, status: 1, reject_detail })
            if (res.success) {
                this.onFetchClaimById()
                this.setState({
                    visible: false,
                })
            }

        } catch (error) {
            console.log(error)
        }
    }
    @autobind
    handleCancel() {
        this.setState({
            visible: false,
        });
    }
    @autobind
    gotoEdit(claim_doc) {
        // console.log(claim_id)
        this.props.history.push(`/rizz/claim_edit?id=${claim_doc}`)
    }
    @autobind
    goBack() {
        this.props.history.goBack()

    }
    @autobind
    checkdeta() {
        const { reject_detail } = this.state
        console.log(reject_detail)
    }
    @autobind
    async handlereject() {
        const { claim_doc, status, reject_detail } = this.state
        if (reject_detail === '') {
            message.error('กรุณาใส่เหตุผลที่ไม่อนุมัติ');
        } else {
            this.setState({
                isLoadingReject: true,

            })
            try {
                let res = await POST(RIZZ_UPDATESTATUS, { claim_doc, status: 99, reject_detail })
                if (res.success) {
                    this.onFetchClaimById()
                    this.setState({
                        vb_reject: false,
                        isLoadingReject: false,
                    })
                }
            } catch (error) {

            }
        }

    }
    @autobind
    cancelreject() {
        this.setState({
            vb_reject: false,
        })
    }
    @autobind
    clickImage(src_image) {
        console.log(src_image)
        this.setState({
            zoomImage: src_image,
            isOpen: true
        })
    }

    render() {
        const { isOpen, reject_detail, claim_doc, claim_id, user, address, product, status, visible, loading, register_url } = this.state;
        console.log('claim_doc', claim_doc)
        console.log('claim_id', claim_id)
        const handler = status === 1 || status === 2 ? false : true
        console.log('status', status)
        if (loading) {
            return <div className="example" style={{ width: '100%' }}>
                <Spin />
            </div>
        }
        return (

            <div>
                <BtnGoback title={'กลับ'} onClick={() => this.goBack()}  ></BtnGoback>
                <Modal
                    title={<p style={{ textAlign: 'center' }}>เหตุผลที่ไม่อนุมัติ</p>}
                    visible={this.state.vb_reject}
                    onOk={this.handlereject}
                    onCancel={this.cancelreject}
                    width={'30vw'}
                    confirmLoading={this.state.isLoadingReject}
                    cancelText={<font style={{ fontSize: '1vw' }}>ยกเลิก</font>}
                    okText={<font style={{ fontSize: '1vw' }}>ยืนยัน</font>}
                    okButtonProps={{
                        style: {
                            backgroundColor: color.rizz_primary,
                            color: '#fff',
                            borderColor: color.rizz_primary
                        }
                    }}
                >
                    <ModalReject
                        obj={{ reject_detail }}
                        onChange={(e, ref) => this.onChange(e, ref)}
                    />
                </Modal>
                <Modal
                    title="ยืนยันการรับงาน"
                    visible={visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width={'30vw'}
                    cancelText={<font style={{ fontSize: '1vw' }}>ยกเลิก</font>}
                    okText={<font style={{ fontSize: '1vw' }}>ยืนยัน</font>}
                >
                    <p>กรุณากดยืนยันเพื่อรับงาน {claim_id}</p>

                </Modal>
                {isOpen && (
                    <Lightbox
                        mainSrc={this.state.zoomImage}

                        onCloseRequest={() => this.setState({ isOpen: false })}

                    />
                )}
                {/* <Edit address={address.zipcode}></Edit> */}
                {/* #################################   สถานะ  #################################*/}
                <Row>
                    <Col span={6} push={18}>
                        <div style={{ border: '0.5px', borderStyle: 'solid', borderColor: '#FFFFFF', marginLeft: '0.8vw' }}>
                            <div className={'column'} style={{ margin: 10, justifyContent: 'center', alignItems: 'center' }} >
                                <h3>สถานะ</h3>
                            </div>
                            <div className={'column'} style={{ margin: 10, justifyContent: 'center', alignItems: 'center' }} >
                                <Timeline>
                                    <Timeline.Item dot={<Icon type="check-circle" style={{ fontSize: status === 0 ? '26px' : '20px' }} />} color={status === 0 ? 'red' : 'black'} style={{ marginTop: 20 }}>
                                        <font className={'text-status'}>รอรับเรื่อง</font>
                                    </Timeline.Item>
                                    <Timeline.Item dot={<Icon type="check-circle" style={{ fontSize: status === 1 ? '26px' : '20px' }} />} color={status === 1 ? 'red' : 'black'} style={{ marginTop: 15 }}>
                                        <font className={'text-status'}>รับเรื่องแล้ว</font>
                                    </Timeline.Item>
                                    <Timeline.Item dot={<Icon type="check-circle" style={{ fontSize: status === 2 ? '26px' : '20px' }} />} color={status === 2 ? 'green' : 'black'} style={{ marginTop: 15 }}>
                                        <font className={'text-status'}>จัดส่งเรียบร้อย</font>
                                    </Timeline.Item>
                                    <Timeline.Item dot={<Icon type="check-circle" style={{ fontSize: status === 99 ? '26px' : '20px' }} />} color={status === 99 ? 'red' : 'black'} style={{ marginTop: 15 }}>
                                        <font className={'text-status'}>ไม่อนุมัติ</font>
                                    </Timeline.Item>
                                    {/* <Timeline.Item dot={<Icon type="check-circle" style={{ fontSize: status === 2 ? '26px' : '20px' }} />} color={status === 2 ? 'green' : 'black'} style={{ marginTop: 15 }}> จัดส่งเรียบร้อย</Timeline.Item> */}
                                    {/* <Timeline.Item dot={<Icon type="check-circle" style={{ fontSize: status === 99 ? '26px' : '20px' }} />} color={status === 99 ? 'red' : 'black'} style={{ marginTop: 15 }}> ไม่อนุมัติ</Timeline.Item> */}
                                </Timeline>
                            </div>

                        </div>
                    </Col>
                    {/* #################################   ข้อมูลส่วนตัว  #################################*/}
                    <Col span={18} pull={6}>
                        <div style={{ border: '0.5px', borderStyle: 'solid', borderColor: '#CCCCCC', margin: 15 }}>
                            <div className={'column'} style={{ margin: 10 }} >
                                <h3>ข้อมูลส่วนตัว</h3>
                            </div>

                            <div className={'column'} style={{ margin: 15, marginTop: -15, marginLeft: 25 }} >
                                <div className={'row-between'} >
                                    <font style={styles.font}>Line Name</font>
                                    <font style={styles.font}>{user.line_name}</font>
                                </div>
                                <div className={'row-between'} style={{ marginTop: 10 }}>
                                    <font style={styles.font}>ชื่อ-นามสกุล</font>
                                    <font style={styles.font}>{user.name}</font>
                                </div>
                                <div className={'row-between'} style={{ marginTop: 10 }} >
                                    <font style={styles.font}>หมายเลขโทรศัพท์</font>
                                    <font style={styles.font}>{user.telephone}</font>
                                </div>
                                <div className={'row-between'} style={{ marginTop: 10 }} >
                                    <div >
                                        <font style={styles.font}>ที่อยู่</font>

                                    </div>
                                    <div style={{ display: 'flex' }}>

                                        <div>
                                            <font style={{ ...styles.font, marginLeft: 10 }}>ต.</font>
                                            <font style={{ ...styles.font, marginLeft: 3 }}>{address.amphure}</font>
                                        </div>
                                        <div>
                                            <font style={{ ...styles.font, marginLeft: 10 }}>อ.</font>
                                            <font style={{ ...styles.font, marginLeft: 3 }}>{address.district}</font>
                                        </div>
                                        <div >
                                            <font style={{ ...styles.font, marginLeft: 10 }}>จ.</font>
                                            <font style={{ ...styles.font, marginLeft: 3 }}>{address.province}</font>
                                        </div>
                                        <div>
                                            <font style={{ ...styles.font, marginLeft: 10 }}>รหัสไปรษณีย์</font>
                                            <font style={{ ...styles.font, marginLeft: 3 }}>{address.zipcode}</font>

                                        </div>
                                    </div>
                                </div>
                                <div className={'row-between'} style={{ marginTop: 10 }} >
                                    <font style={styles.font}>เพิ่มเติม</font>
                                    <font style={styles.font}>{address.other}</font>
                                </div>

                            </div>
                        </div>

                    </Col>
                </Row>
                {/* #################################   button  #################################*/}
                <Row>
                    <Col span={6} push={18}>




                    </Col>
                    {/* #################################   ข้อมูลการแจ้งเคลม  #################################*/}
                    <Col span={9} pull={6}>
                        <div style={{ border: '0.5px', borderStyle: 'solid', borderColor: '#CCCCCC', margin: 15, marginTop: -3 }}>
                            <div className={'column'} style={{ margin: 10 }} >
                                <h3>ข้อมูลการลงทะเบียน</h3>
                            </div>
                            <div className={'column'} style={{ justifyContent: 'center', alignItems: 'center' }}  >
                                <img src={register_url ? register_url : image.rizz_logo} onClick={() => this.clickImage(register_url ? register_url : image.rizz_logo)}
                                    alt="new" style={{ width: '6cm', height: '7cm', marginBottom: 50 }} />
                            </div>
                        </div>
                    </Col>
                    <Col span={9} pull={6}>
                        <div style={{ border: '0.5px', borderStyle: 'solid', borderColor: '#CCCCCC', margin: 15, marginTop: -3 }}>
                            <div className={'column'} style={{ margin: 10 }} >
                                <h3>ข้อมูลการแจ้งเคลม</h3>
                            </div>
                            <div className={'column'} style={{ justifyContent: 'center', alignItems: 'center' }}  >
                                <img src={user.url} onClick={() => this.clickImage(user.url)}
                                    alt="new" style={{ width: '6cm', height: '7cm', marginBottom: 50 }} />
                            </div>
                        </div>
                    </Col>
                    {/* #################################   รายละเอียดการเคลม  #################################*/}

                </Row>
                <Row>

                    <Col span={6} push={18}>
                        <div style={{ flex: 1, display: 'flex', alignItems: 'center', margin: 15, marginTop: -3 }}>
                            <div style={{ flex: 1, display: 'flex', margin: 10, justifyContent: 'center' }} >
                                {/* <Button title={'แก้ไข'} onPress={() => this.gotoEdit(claim_doc)} /> */}
                                <BT type="primary" style={{ width: '6vw', height: '20vw' }} onClick={() => this.gotoEdit(claim_doc)} >
                                    แก้ไข
                                 </BT>
                                <BT type="primary" style={{ width: '6vw', height: '20vw' }} onClick={() => this.showModal()} disabled={!handler}>
                                    ดำเนินการ
                                 </BT>
                            </div>

                        </div>
                        <div className={'column'} style={{ justifyContent: 'center', alignItems: 'center', marginTop: -10 }}>
                            {/* <Button title={'ไม่อนุมัตื'} style={{ width: '12vw', height: '20vw' }} onPress={() => this.rejectModal()} /> */}
                            <BT type="primary" style={{ width: '12vw', height: '20vw', backgroundColor: 'red', }} onClick={() => this.rejectModal()} disabled={status === 2 || status === 99 ? true : false}  >
                                ไม่อนุมัติ
                            </BT>
                        </div>
                    </Col>
                    <Col span={18} pull={6}>
                        <div style={{ border: '0.5px', borderStyle: 'solid', borderColor: '#CCCCCC', margin: 15, marginTop: -3 }}>
                            <div style={{ margin: 15, marginLeft: 15 }} >
                                <div className={'row-between'} style={{ marginTop: 20 }}>
                                    <font style={styles.font}>เลขที่การเคลม</font>
                                    <font style={styles.font}>{claim_id}</font>
                                </div>
                                <div className={'row-between'} style={{ marginTop: 20 }}>
                                    <font style={styles.font}>รหัสลงทะเบียนสินค้า</font>
                                    <font style={styles.font}>{product.product_id}</font>
                                </div>
                                <div className={'row-between'} style={{ marginTop: 20 }}>
                                    <font style={styles.font}>ชื่อสินค้าผลิตภัณฑ์</font>
                                    <font style={styles.font}>{product.product_name}</font>
                                </div>
                                <div className={'row-between'} style={{ marginTop: 20 }} >
                                    <font style={styles.font}>วันที่แจ้งเคลม</font>
                                    <font style={styles.font}>{moment(user.claimdate).format('DD-MM-YYYY HH:mm')}</font>
                                </div>
                                <div className={'row-between'} style={{ marginTop: 20 }} >
                                    <font style={styles.font}>อาการเสีย</font>
                                    <font style={styles.font}>{user.description}</font>
                                </div>

                                <div className={'row-between'} style={{ marginTop: 20 }}>
                                    <font style={styles.font}>เคลมข้ามรุ่น</font>

                                </div>
                                <div className={'row-between'} style={{ marginTop: 20 }}>
                                    <font style={styles.font}>หมายเหตุ</font>
                                    <font>{user.note}</font>
                                </div>
                                <div className={'column'} style={{ marginTop: 10, justifyContent: 'center', alignItems: 'center' }}>
                                    <font style={{ fontSize: '0.4cm', marginTop: 5 }}>แจ้งเคลมมาแล้ว {fn.dateDiff(user.claimdate)} วัน</font>
                                </div>
                            </div>

                        </div>
                    </Col>
                </Row>

            </div >

        )
    }


}



export default DetailClaimScene