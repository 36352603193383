import React, { Component } from 'react'
import { observer } from 'mobx-react';
import { FocuscolumnTableProducts, color } from '../../../resource';
import { GET,POST, FOCUS_GET_PRODUCTS_WARRANTY, FOCUS_UPDATE_PRODUCTS_WARRANTY } from '../../../service_focus'
import autobind from 'autobind-decorator';
import  { ModalEditProduct,ModalAddProduct }  from '../../../components/Modal'
import { EditFilled, MobileOutlined, PlusCircleOutlined, StopOutlined } from '@ant-design/icons';
import {
  Form,
  Icon,
  Upload,
  Input,
  Row,
  Col,
  Content,
  Button as BT,
  DatePicker,
  Spin,
  Modal,
  message,
  Select,
  Table
} from 'antd';

@observer
class ProductsScene extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            isLoadingData: true,
            isModalVisible: false,
            ModalAddVisible:false

        }
    }

    componentDidMount() {
        this.getProductsWarranty()
    }

    openModal = (product_id,product_image_url,product_name,product_status) => {

        this.setState({isModalVisible:true,product_id,product_image_url,product_name,product_status,product_image:''})
    }

    openModalAdd = () =>{
        this.setState({ModalAddVisible:true})
    }

    checkSizeImage = (e) =>{
        const imgType = e.target.files[0].type
        const imgSize = e.target.files[0].size
        const imgFile = e.target.files[0];
	    const imgurl = URL.createObjectURL(imgFile);
        const isLt1M = imgSize / 1024 / 1024 < 1;
        
        const isJpgOrPng = imgType === 'image/jpeg' || imgType === 'image/png';
        if (!isJpgOrPng) {
            message.error('ไฟล์รูปต้องเป็น JPG/PNG เท่านั้น!');
        }else{
            if (!isLt1M) {
                message.error('ไฟล์รูปของขนาดเกิน 1MB!');
            }else{
                console.log('file.type',e.target.files[0])
                this.setState({product_image_url:imgurl, product_image:imgFile})
            }
        }   
    }

    onChange = (ref, e) =>{
        switch (ref) {
            case 'img':
                this.checkSizeImage(e)
                break;
            case 'product_name':
                this.setState({product_name:e.target.value})
                break;
            case 'product_status':
                this.setState({product_status: !!e?'1':'0' })
                break;
                
            default:
                break;
        }
    }

    
    getProductsWarranty = async()=>{
      
        try {
            const res = await GET(FOCUS_GET_PRODUCTS_WARRANTY)
            let dataProducts = []
            if(res.success){
                console.log('dataproducts',res.result.product)
              
                dataProducts = res.result.product.map((val,i)=>{
                    const { product_id,product_image_url,product_name,product_status} = val

                    console.log({val})
                   
                    return {
                        no: i+1,
                        ...val,
                        edit_product: <React.Fragment><BT className='btn-edit' onClick={()=>this.openModal(product_id,product_image_url,product_name,product_status)}><EditFilled />{'แก้ไข'}</BT>
                        <BT className='btn-map' onClick={()=>this.openModal(product_image_url,product_name,product_status)}><MobileOutlined />{'Map รุ่นมือถือ'}</BT></React.Fragment>
                    }

                })

                this.setState({dataProducts, isLoadingData:false})
            }
            
        } catch (error) {
            
        }
    }

    updateProduct = async() =>{
        let {product_id,product_name, product_status,product_image} = this.state
        console.log({product_id,product_name, product_status,product_image})
    }

    render() {
        const {isLoadingData, dataProducts,isModalVisible, product_image_url,
            product_name, product_status,product_id,ModalAddVisible} = this.state

            console.log('product_status', product_status)
            console.log({product_id})
        return (
            <React.Fragment>
                <div>
                <BT className='btn-add' onClick={()=>this.openModalAdd()}><PlusCircleOutlined />{'เพิ่มสินค้ารับประกัน'}</BT>
                {/*###################### TABLE ######################*/}
                <Table
                        title={()=><h2>{'รายการสินค้ารับประกัน'}</h2>}
                        // rowSelection={rowSelection}
                        columns={FocuscolumnTableProducts}
                        rowClassName={(record, i) => i % 2 === 1 ? 'even' : ''}
                        dataSource={dataProducts}
                        //expandedRowRender={record => this.renderExpand(record)}
                        bordered
                        pagination={{ pageSize: 15 }}
                        loading={isLoadingData}
                        size='small'
                />
                </div>
                <ModalEditProduct 
                    openModal={isModalVisible}
                    closeModal={()=> this.setState({isModalVisible:false})}
                    product_image_url = {product_image_url}
                    product_name = {product_name}
                    product_status = {product_status}
                    onChange = {this.onChange}
                    updateProduct={this.updateProduct}
                />
                <ModalAddProduct 
                    openModal={ModalAddVisible}
                    closeModal={()=> this.setState({ModalAddVisible:false})}
                    product_image_url = {product_image_url}
                    product_name = {product_name}
                    product_status = {product_status}
                    onChange = {this.onChange}
                    updateProduct={this.updateProduct}
                />
        </React.Fragment>
         
        )
    }
}
export default ProductsScene;