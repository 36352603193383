import React, { Component } from "react";
import { observer } from "mobx-react";
import { Table, Input,DatePicker } from "antd";
import { FocuscolumnTableHistory } from "../../../resource";
import autobind from "autobind-decorator";
import { GET, FOUCUS_GETHISTORY } from "../../../service_focus";
import dayjs from "dayjs";
import moment from "moment"

let defaultData = [];

@observer
class HistoryScene extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingData: true,
      searchText: "",
      start_date: moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      end_date: moment()
        .endOf("month")
        .format("YYYY-MM-DD"),
      user_role:
        !!JSON.parse(localStorage.getItem("DkdL8OVPdp")) &&
        JSON.parse(localStorage.getItem("DkdL8OVPdp")).role,
    };
  }

  componentDidMount() {
    this.onFetchHistory();
  }

  @autobind
  async onFetchHistory() {
    const { user_role,start_date,end_date } = this.state;
    try {
      const res = await GET(`${FOUCUS_GETHISTORY}?role=${user_role}&startDate=${start_date}&endDate=${end_date}`);
      let data = [];
      if (res.success) {
        data = res.result.map((el, i) => {
          const { data, customer } = el;
          return {
            no: i + 1,
            ...customer,
            telephone: customer.tel,
            last_date: dayjs(customer.dateclaim)
              .add(543, "y")
              .format("DD/MM/YYYY"),
            data,
          };
        });
      }
      defaultData = data;

      this.setState({ isLoadingData: false, data });
    } catch (error) {
      console.log(error);
    }
  }

  @autobind
  onChange(e, ref) {
    this.setState({ [ref]: e });
  }

  @autobind
  onKeyPressSearch(event) {
    if (event.key === "Enter") {
      this.onSearch();
    }
  }

  @autobind
  onSearch() {
    const { searchText } = this.state;
    if (!searchText) {
      return this.setState({ data: defaultData });
    }

    const d = defaultData.filter((el) => {
      return Object.values(el).some((val) => {
        return val
          ? val
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          : false;
      });
    });

    this.setState({ data: d });
  }

  @autobind
  onChangeRangePicker(dates) {
   
    const start = moment(dates[0]).format("YYYY-MM-DD");
    const end = moment(dates[1]).format("YYYY-MM-DD");
    console.log({dates})
    this.setState({
      start_date: start,
      end_date: end,
      isLoadingData: true,
    },()=>this.onFetchHistory());
    
  }

  render() {
    const { data, isLoadingData, searchText,start_date,end_date } = this.state;

    return (
      <div>
        <div className={"column"}>
          {/*###################### HEAD ######################*/}
          <div className={"row-between"}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Input.Search
                style={{
                  width: "20vw",
                  borderRadius: "10vw",
                  paddingLeft: "1vw",
                }}
                placeholder={"ค้นหาข้อมูล"}
                value={searchText}
                onKeyPress={(e) => this.onKeyPressSearch(e)}
                onSearch={() => this.onSearch()}
                onChange={(e) => this.onChange(e.target.value, "searchText")}
              />
            </div>
          </div>
          <div className={"row-between"}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "center",
              }}
            >
              <DatePicker.RangePicker
                placeholder={["วันที่เริ่มต้น", "วันที่สิ้นสุด"]}
                format="YYYY-MM-DD"
                defaultValue={[
                  moment(start_date),
                  moment(end_date),
                ]}
                onChange={this.onChangeRangePicker}
                size="default"
              />

              {/* <Button title={'ยืนยัน'} type={'focus'} onPress={() => this.goCreate()} /> */}
            </div>
          </div>
          {/*###################### TABLE ######################*/}
          <Table
            title={() => <h2>ประวัติการเคลม</h2>}
            // rowSelection={rowSelection}
            columns={FocuscolumnTableHistory}
            rowClassName={(record, i) => (i % 2 === 1 ? "even" : "")}
            dataSource={data}
            expandedRowRender={(record) => this.renderExpand(record)}
            bordered
            pagination={{ pageSize: 15 }}
            loading={isLoadingData}
            size="small"
          />
        </div>
      </div>
    );
  }

  renderExpand(record) {
    return record.data.map((el, i) => {
      return (
        <div
          style={{ padding: "1vw", display: "flex", alignItems: "center" }}
          key={`${el}${i}`}
        >
          <img
            src={el.picclaim}
            style={{ width: 100, height: 100 }}
            className={"contain"}
          />
          <div style={{ marginLeft: "1vw" }}>
            <div style={{ display: "flex", marginBottom: "-12px" }}>
              <p style={{ margin: 0, width: "100px" }}>เลขที่การเคลม</p>
              <p>{el.no_claim}</p>
            </div>
            <div style={{ display: "flex", marginBottom: "-12px" }}>
              <p style={{ margin: 0, width: "100px" }}>สินค้า</p>
              <p>{el.nameproduct}</p>
            </div>
            <div style={{ display: "flex", marginBottom: "-12px" }}>
              <p style={{ margin: 0, width: "100px" }}>วันที่แจ้งเคลม</p>
              <p>
                {dayjs(el.dateclaim)
                  .add(543, "y")
                  .format("DD/MM/YYYY")}
              </p>
            </div>
            <div style={{ display: "flex", marginBottom: "-12px" }}>
              <p style={{ margin: 0, width: "100px" }}>อาการเสีย</p>
              <p>{el.detail}</p>
            </div>
            <div style={{ display: "flex", marginBottom: "-12px" }}>
              <p style={{ margin: 0, width: "100px" }}>EMS จัดส่ง</p>
              <p>{el.tracking}</p>
            </div>
            <div style={{ display: "flex", marginBottom: "-12px" }}>
              <p style={{ margin: 0, width: "100px" }}>ประเภทการเคลม</p>
              <p>{el.type_store}</p>
            </div>
          </div>
        </div>
      );
    });
  }
}

export default HistoryScene;
