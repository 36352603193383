export const optionCategory = [
    {
        value:'A',
        label:'Cat-A'
    },
    {
        value:'D',
        label:'Cat-D'
    },
    {
        value:'P',
        label:'Cat-P'
    },
    {
        value:'L',
        label:'Cat-L'
    },
    {
        value:'U',
        label:'Cat-U'
    },
    {
        value:'S',
        label:'Cat-S'
    },
    {
        value:'Y',
        label:'Cat-Y'
    },
    // {
    //     value:'M1',
    //     label:'Cat-M1'
    // },
    // {
    //     value:'M19',
    //     label:'Cat-M19'
    // },
    // {
    //     value:'DL',
    //     label:'Cat-DL'
    // },
    // {
    //     value:'TD',
    //     label:'Cat-TD'
    // },
    // {
    //     value:'F',
    //     label:'Cat-F'
    // },
    // {
    //     value:'M',
    //     label:'Cat-M'
    // }
]

