import React, { Component } from "react";
import { observer } from "mobx-react";
import autobind from "autobind-decorator";
import {
  Row,
  Col,
  Timeline,
  Icon,
  Button as BT,
  Modal,
  Spin,
  Input,
  Form,
  message,
} from "antd";
import moment from "moment";
import { BtnGoback } from "../../../components/Button";
import { image } from "../../../resource";
import {
  POST,
  RIZZ_DETAILCLAIMBYID,
  UPLOAD,
  RIZZ_UPSERTCLAIM,
} from "../../../service";
import InputAddress from "react-thailand-address-autocomplete";
import Lightbox from "react-image-lightbox";
const fn = require("../../../functions/functions");
const styles = {
  font: {
    fontSize: "0.4cm",
    marginTop: 3,
  },
  border: {
    border: "0.5px",
    borderStyle: "solid",
    borderColor: "#CCCCCC",
  },
  border_part1: {
    border: "0.5px",
    borderStyle: "solid",
    borderColor: "#CCCCCC",
    marginTop: 15,
  },
};
@Form.create({ name: "EditClaimScene" })
@observer
class EditClaimScene extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    this.state = {
      claim_doc: query.get("id"),
      loading: true,
      visible: false,
      address: [],
      product: [],
    };
  }

  componentDidMount() {
    this.onFetchClaimById();
  }
  @autobind
  async onFetchClaimById() {
    const { claim_doc } = this.state;
    console.log("claim_doc", claim_doc);
    try {
      let res = await POST(RIZZ_DETAILCLAIMBYID, { claim_doc });
      if (res.success) {
        const data = res.result.detailclaim;
        console.log(data);
        this.setState({
          loading: false,
          status: res.result.detailclaim.status,
          ...data,
          ...data.address,
          ...data.product,
          subdistrict: data.address.amphure,
          district: data.address.district,
          addressId: data.address.address_id,
          register_url: res.result.detailclaim.register_url,
        });
        console.log(data);
      }
    } catch (error) {
      // console.log(error)
    }
  }
  @autobind
  showModal() {
    this.setState({
      visible: true,
    });
  }
  @autobind
  onChange(e, ref) {
    this.setState({ [ref]: e });
    console.log(e);
  }

  _handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    console.log(file);
    reader.onloadend = () => {
      this.setState({
        file: file,
        url: reader.result,
      });
    };

    reader.readAsDataURL(file);
  }
  @autobind
  checkValidate() {
    const {
      name,
      telephone,
      province,
      district,
      subdistrict,
      other,
      description,
      product_name,
    } = this.state;
    if (
      !name ||
      !telephone ||
      !province ||
      !district ||
      !subdistrict ||
      !other ||
      !description ||
      !product_name
    ) {
      console.log(
        name,
        telephone,
        province,
        district,
        subdistrict,
        other,
        description
      );
      message.error("กรุณากรอกข้อมูลให้ครบถ้วน");
    } else {
      this.onUpdate();
    }
  }
  @autobind
  async onUpdate() {
    let type_sending = false;
    const {
      file,
      name,
      telephone,
      province,
      district,
      subdistrict,
      zipcode,
      other,
      description,
      note,
      product_id,
      claim_doc,
      status,
      line_id,
      claimdate,
      addressId,
      product_name,
    } = this.state;
    let arr = {
      file,
      name,
      telephone,
      province,
      district,
      subdistrict,
      zipcode,
      other,
      description,
      note,
      product_id,
      claim_doc,
      status,
      line_id,
      claimdate,
      addressId,
      type_sending,
      product_name,
    };
    let formdata = fn.append_edit(arr);
    this.setState({ loading: true });
    console.log("file", formdata);
    try {
      let res = await UPLOAD(RIZZ_UPSERTCLAIM, formdata);
      console.log(res);
      if (res.success) {
        this.setState({ loading: false });
        this.goBack();
      }
    } catch (error) {
      console.log(error);
    }
  }
  @autobind
  goBack() {
    this.props.history.goBack();
  }
  onSelect = (fullAddress) => {
    const { subdistrict, district, province, zipcode } = fullAddress;
    this.setState({
      subdistrict,
      district,
      province,
      zipcode,
    });
  };
  @autobind
  onChangeAddress(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  @autobind
  clickImage(src_image) {
    console.log(src_image);
    this.setState({
      zoomImage: src_image,
      isOpen: true,
    });
  }

  render() {
    const {
      isOpen,
      product_id,
      claim_doc,
      claim_id,
      status,
      visible,
      loading,
      line_name,
      name,
      telephone,
      other,
      url,
      product_name,
      claimdate,
      description,
      note,
      register_url,
    } = this.state;

    const { getFieldDecorator } = this.props.form;
    getFieldDecorator("EditClaimScene", { initialValue: [] });
    if (loading) {
      return (
        <div className="example" style={{ width: "100%" }}>
          <Spin />
        </div>
      );
    }
    return (
      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 12 }}
        onSubmit={this.handleSubmit}
      >
        <div>
          <BtnGoback title={"กลับ"} onClick={() => this.goBack()}></BtnGoback>
          <Modal
            title="ยืนยันการรับงาน"
            visible={visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            width={"30vw"}
            cancelText={<font style={{ fontSize: "1vw" }}>ยกเลิก</font>}
            okText={<font style={{ fontSize: "1vw" }}>ยืนยัน</font>}
          >
            <p>กรุณากดยืนยันเพื่อรับงาน {claim_id}</p>
          </Modal>
          {isOpen && (
            <Lightbox
              mainSrc={this.state.zoomImage}
              onCloseRequest={() => this.setState({ isOpen: false })}
            />
          )}
          {/* <Edit address={address.zipcode}></Edit> */}
          {/* #################################   สถานะ  #################################*/}
          <Row>
            <Col span={6} push={18}>
              <div
                style={{
                  border: "0.5px",
                  borderStyle: "solid",
                  borderColor: "#FFFFFF",
                  margin: 15,
                }}
              >
                <div
                  className={"column"}
                  style={{
                    margin: 10,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h3>สถานะ</h3>
                </div>
                <div
                  className={"column"}
                  style={{
                    margin: 10,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Timeline>
                    <Timeline.Item
                      dot={
                        <Icon
                          type="check-circle"
                          style={{ fontSize: status === 0 ? "26px" : "20px" }}
                        />
                      }
                      color={status === 0 ? "red" : "black"}
                      style={{ marginTop: 20 }}
                    >
                      <font className={"text-status"}>รอรับเรื่อง</font>
                    </Timeline.Item>

                    <Timeline.Item
                      dot={
                        <Icon
                          type="check-circle"
                          style={{ fontSize: status === 1 ? "26px" : "20px" }}
                        />
                      }
                      color={status === 1 ? "red" : "black"}
                      style={{ marginTop: 15 }}
                    >
                      <font className={"text-status"}>รับเรื่องแล้ว</font>
                    </Timeline.Item>
                    <Timeline.Item
                      dot={
                        <Icon
                          type="check-circle"
                          style={{ fontSize: status === 2 ? "26px" : "20px" }}
                        />
                      }
                      color={status === 2 ? "green" : "black"}
                      style={{ marginTop: 15 }}
                    >
                      <font className={"text-status"}>จัดส่งเรียบร้อย</font>
                    </Timeline.Item>
                    <Timeline.Item
                      dot={
                        <Icon
                          type="check-circle"
                          style={{ fontSize: status === 99 ? "26px" : "20px" }}
                        />
                      }
                      color={status === 99 ? "red" : "black"}
                      style={{ marginTop: 15 }}
                    >
                      <font className={"text-status"}>ไม่อนุมัติ</font>
                    </Timeline.Item>
                    {/* <Timeline.Item dot={<Icon type="check-circle" style={{ fontSize: status === 2 ? '26px' : '20px' }} />} color={status === 2 ? 'green' : 'black'} style={{ marginTop: 20 }}>จัดส่งเรียบร้อย</Timeline.Item> */}
                    {/* <Timeline.Item dot={<Icon type="check-circle" style={{ fontSize: status === 99 ? '26px' : '20px' }} />} color={status === 99 ? 'red' : 'black'} style={{ marginTop: 15 }}> ไม่อนุมัติ</Timeline.Item> */}
                  </Timeline>
                </div>
              </div>
            </Col>
            {/* #################################   ข้อมูลส่วนตัว  #################################*/}
            <Col span={18} pull={6}>
              <div
                style={{
                  border: "0.5px",
                  borderStyle: "solid",
                  borderColor: "#CCCCCC",
                  margin: 15,
                }}
              >
                <div className={"column"} style={{ margin: 10 }}>
                  <h3>ข้อมูลส่วนตัว</h3>
                </div>

                <div
                  className={"column"}
                  style={{ margin: 15, marginTop: -15, marginLeft: 25 }}
                >
                  <div className={"row-between"}>
                    <font style={styles.font}>Line Name</font>

                    <font style={styles.font}>{line_name}</font>
                  </div>
                  <div className={"row-between"} style={{ fontSize: "1cm" }}>
                    <font style={styles.font}>ชื่อ-นามสกุล</font>
                    <font style={styles.font}>{name}</font>
                  </div>
                  <div className={"row-between"}>
                    <font style={styles.font}>หมายเลขโทรศัพท์</font>
                    <font style={styles.font}>{telephone}</font>
                  </div>
                  <div className={"row-between"}>
                    <div>
                      <font style={styles.font}>ที่อยู่</font>
                    </div>
                    <div className={"column"}>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {/* <font style={styles.font}>จ.</font> */}
                          {/* <font style={{ ...styles.font, marginLeft: 3 }}>{province}</font> */}

                          <InputAddress
                            style={{ marginRight: 10 }}
                            address="subdistrict"
                            value={this.state.subdistrict}
                            placeholder={"แขวง/ตำบล"}
                            onChange={(e) => this.onChangeAddress(e)}
                            onSelect={this.onSelect}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {/* <font style={{ ...styles.font, marginLeft: 10 }}>ต.</font> */}
                          {/* <font style={{ ...styles.font, marginLeft: 3 }}>{distict}</font> */}

                          <InputAddress
                            address="district"
                            value={this.state.district}
                            placeholder={"เขต/อำเภอ"}
                            onChange={(e) => this.onChangeAddress(e)}
                            onSelect={this.onSelect}
                          />
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {/* <font style={{ ...styles.font, marginLeft: 10 }}>อ.</font> */}

                          <InputAddress
                            style={{ marginRight: 10 }}
                            address="province"
                            value={this.state.province}
                            placeholder={"จังหวัด"}
                            onChange={(e) => this.onChangeAddress(e)}
                            onSelect={this.onSelect}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {/* <font style={{ ...styles.font, marginLeft: 10 }}>รหัสไปรษณีย์</font> */}
                          <InputAddress
                            address="zipcode"
                            value={this.state.zipcode}
                            placeholder={"รหัสไปรษณีย์"}
                            onChange={(e) => this.onChangeAddress(e)}
                            onSelect={this.onSelect}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={"row-between"}>
                    <font style={styles.font}>เพิ่มเติม</font>
                    {/* <font style={styles.font}>{other}</font> */}
                    <Input.TextArea
                      style={{ width: "30%", alignItems: "right" }}
                      defaultValue={other}
                      value={other}
                      onChange={(e) => this.onChange(e.target.value, "other")}
                      autosize={{ minRows: 1, maxRows: 2 }}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          {/* #################################   button  #################################*/}
          <Row>
            <Col span={6} push={18}></Col>
            {/* #################################   ข้อมูลการแจ้งเคลม  #################################*/}
            <Col span={9} pull={6}>
              <div
                style={{
                  border: "0.5px",
                  borderStyle: "solid",
                  borderColor: "#CCCCCC",
                  margin: 15,
                  marginTop: -3,
                  height: "10cm",
                }}
              >
                <div className={"column"} style={{ margin: 10 }}>
                  <h3>ข้อมูลการลงทะเบียน</h3>
                </div>
                <div
                  className={"column"}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 35,
                  }}
                >
                  <img
                    src={register_url ? register_url : image.rizz_logo}
                    onClick={() =>
                      this.clickImage(
                        register_url ? register_url : image.rizz_logo
                      )
                    }
                    alt="new"
                    style={{ width: "6cm", height: "7cm", marginBottom: 20 }}
                  />
                  {/* <input className="fileInput" style={{ marginLeft: 30 }}

                                        type="file"
                                        onChange={(e) => this._handleImageChange(e)} /> */}
                </div>
              </div>
            </Col>
            {/* #################################   รายละเอียดการเคลม  #################################*/}
            <Col span={9} pull={6}>
              <div
                style={{
                  border: "0.5px",
                  borderStyle: "solid",
                  borderColor: "#CCCCCC",
                  margin: 15,
                  marginTop: -3,
                  height: "10cm",
                }}
              >
                <div className={"column"} style={{ margin: 10 }}>
                  <h3>ข้อมูลการแจ้งเคลม</h3>
                </div>
                <div
                  className={"column"}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 35,
                  }}
                >
                  <img
                    src={url}
                    onClick={() => this.clickImage(url)}
                    alt="new"
                    style={{ width: "6cm", height: "7cm", marginBottom: 20 }}
                  />
                  <input
                    className="fileInput"
                    style={{ marginLeft: 30 }}
                    type="file"
                    onChange={(e) => this._handleImageChange(e)}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={6} push={18}>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "flex-end",
                  margin: 15,
                  height: "9.5cm",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    width: "30cm",
                    marginBottom: 20,
                  }}
                >
                  <BT
                    style={{ width: "100px", height: "80px" }}
                    type="danger"
                    onClick={() => this.checkValidate()}
                  >
                    บันทึก
                  </BT>
                </div>
              </div>
            </Col>

            <Col span={18} pull={6}>
              <div
                style={{
                  border: "0.5px",
                  borderStyle: "solid",
                  borderColor: "#CCCCCC",
                  margin: 15,
                  marginTop: -3,
                }}
              >
                <div style={{ margin: 15, marginLeft: 15 }}>
                  <div className={"row-between"}>
                    <font style={styles.font}>เลขที่การเคลม</font>
                    <font style={styles.font}>{claim_id}</font>
                  </div>
                  <div className={"row-between"} style={{ marginTop: 10 }}>
                    <font style={styles.font}>รหัสลงทะเบียนสินค้า</font>
                    <font style={styles.font}>{product_id}</font>
                  </div>
                  <div className={"row-between"} style={{ marginTop: 10 }}>
                    <font style={styles.font}>ชื่อสินค้าผลิตภัณฑ์</font>
                    {/* <font style={styles.font}>{product_name}</font> */}
                    <Input
                      style={{ width: "50%", alignItems: "right" }}
                      defaultValue={product_name}
                      value={product_name}
                      onChange={(e) =>
                        this.onChange(e.target.value, "product_name")
                      }
                      // autosize={{ minRows: 1, maxRows: 1 }}
                    />
                  </div>
                  <div className={"row-between"} style={{ marginTop: 10 }}>
                    <font style={styles.font}>วันที่แจ้งเคลม</font>
                    <font style={styles.font}>
                      {moment(claimdate).format("DD-MM-YYYY HH:mm")}
                    </font>
                  </div>
                  <div className={"row-between"} style={{ marginTop: 10 }}>
                    <font style={styles.font}>อาการเสีย</font>
                    {/* <font style={styles.font}>{description}</font> */}
                    <Input.TextArea
                      style={{ width: "50%", alignItems: "right" }}
                      defaultValue={description}
                      value={description}
                      onChange={(e) =>
                        this.onChange(e.target.value, "description")
                      }
                      autosize={{ minRows: 1, maxRows: 2 }}
                    />
                  </div>

                  <div className={"row-between"} style={{ marginTop: -10 }}>
                    <font style={{ ...styles.font }}>
                      หมายเหตุ(เคลมข้ามรุ่น)
                    </font>
                    <Input.TextArea
                      style={{ width: "50%", alignItems: "right" }}
                      defaultValue={note}
                      value={note}
                      onChange={(e) => this.onChange(e.target.value, "note")}
                      autosize={{ minRows: 1, maxRows: 2 }}
                    />
                  </div>
                  <div
                    className={"column"}
                    style={{
                      marginBottom: 5,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <font style={{ fontSize: "0.5cm", marginTop: 70 }}>
                      แจ้งเคลมมาแล้ว {fn.dateDiff(claimdate)} วัน
                    </font>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    );
  }
}

export default EditClaimScene;
