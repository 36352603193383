import React, { useState } from "react";
import { Button, Modal, Row, Col, InputNumber, Select, Spin } from "antd";
import { optionCategory } from "../Constant";
import { Label } from "../../../../styles/styled";
import moment from "moment";
import ExportJsonExcel from "js-export-excel";
import {
  POST,
  GEN_QR_CODE_WARRANTY,
  DOWNLOAD_QR_CODE_WARRANTY,
} from "../../../../service_focus";

export const GenQRCodeModal = ({ visible, onClose, actionBtn }) => {
  const [payload, setPayload] = useState({
    category: "",
    qty: 1,
  });

  const [visibleLoading, setVisibleLoading] = useState(false);
  const [tipText, setTipText] = useState("กำลัง Gen QRCode");

  const onChangeValue = (value, name) => {
    switch (name) {
      case "category":
        payload.category = value;
        break;
      case "qty":
        payload.qty = value;
        break;
      default:
        break;
    }
  };

  const onSubmit = async () => {
    if (!payload.category) {
      return Modal.error({ title: "กรุณาเลือกข้อมูลให้ครบถ้วน" });
    }
    console.log({ payload });
    try {
      setVisibleLoading(true);
      const res = await POST(GEN_QR_CODE_WARRANTY, payload);
      const { success, result } = res;

      if (success) {
        setVisibleLoading(false);
        Modal.success({ title: result, onOk: () => onClose() });
      }
    } catch (error) {
      Modal.error({ title: error.message });
    }
  };

  const onCheckAction = () => {
    if (actionBtn === "GEN") {
      onSubmit();
    } else {
      onloadQRCOde();
    }
  };

  const onloadQRCOde = async () => {
    if (!payload.category) {
      return Modal.error({ title: "กรุณาเลือกข้อมูลให้ครบถ้วน" });
    }

    try {
      setTipText("กำลัง Download QRCode");
      setVisibleLoading(true);
      const res = await POST(DOWNLOAD_QR_CODE_WARRANTY, payload);
      const { success, message, result } = res;

      if (success) {
        setVisibleLoading(false);
        onDownloadExcel(result);
      }
    } catch (error) {
      Modal.error({ title: error.message });
    }
  };

  const onDownloadExcel = (data) => {

    const column = ["code", "category", "link", "decode"];
    let option = {};
    let dataTable = [];
    if(data.length > 0){
      dataTable = data.map((el, i) => {
        return el
      });

    }

    option.fileName = `${moment().format("YYYYMMDD_mmss")}`;
    option.datas = [
      {
        sheetData: dataTable,
        sheetName: ["รายงาน"],
        sheetHeader: column,
      },
    ];

    const toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  };
  return (
    <>
      <Modal
        title={"Gen QRCode"}
        visible={visible}
        onOk={onCheckAction}
        onCancel={onClose}
        width={"720px"}
      >
        <Row style={{ marginBottom: "1em" }} gutter={16}>
          <Col span={12}>
            <Label style={{ width: "100%" }}>
              หมวดหมู่ <span style={{ color: "red" }}>* </span>
            </Label>
            <Select
              required
              onSelect={(e) => onChangeValue(e, "category")}
              style={{ width: "100%" }}
              placeholder={"กรุณาเลือกหมวดหมู่"}
            >
              {optionCategory.map((e, i) => (
                <Select.Option key={i} value={e.value}>
                  {e.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={12}>
            <Label style={{ width: "100%" }}>
              จำนวน
              <span style={{ color: "red" }}>{"* (ไม่เกินครั้งละ 5000)"}</span>
            </Label>
            <InputNumber
              min={1}
              max={5000}
              defaultValue={1}
              onChange={(e) => onChangeValue(e, "amount")}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
      </Modal>
      <Modal
        visible={visibleLoading}
        width={"390px"}
        height={"100px"}
        footer={null}
        closeIcon={null}
      >
        <Spin tip={tipText} size="large">
          <div className="content" style={{ padding: "44px" }} />
        </Spin>
      </Modal>
    </>
  );
};
