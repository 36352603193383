import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Form, Icon, Input, Row, Col, Button as BT, DatePicker, Spin, Modal, message, Button } from 'antd'
import autobind from 'autobind-decorator';
import moment from 'moment'
import { POST, GET, FOCUS_CHECKTEL, FOCUS_RESETLINEID } from '../../../service_focus'
import { BtnGoback } from '../../../components/Button'
const fn = require('../../../functions/functions')

@Form.create({ name: 'resetLineScene' })
@observer
class resetLineScene extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            product_name: '',
            register_date: moment(new Date()),
            imageUrl: '',
            name: '',
            telephone: '',
            lineid_colect: '',
            warranty_month: 12,
            checkNick: false,
            error_hidden: true,
            line_error: 'พบข้อมูลผู้ใช้',
            error_color: 'red',
            line_id: '',
            user_id: '',
            btnReset: true,


        }
    }


    componentDidMount() {

    }
    @autobind
    onChange(e, ref) {
        this.setState({
            [ref]: e,

        })
    }

    @autobind
    onKeyPressSearch(event, type) {
        if (event.key === 'Enter') {
            type === 'telephone' && this.onSearch(event.target.value)
        }
    }

    @autobind
    changeFormatLineId(id) {
        let res = id.slice(0,-10)
        let ja = [...res,'xxxxxxxxxx'].join('')
        return ja
    }

    @autobind
    async onSearch(no) {
        try {
            const r = await GET(FOCUS_CHECKTEL + no)
            if(r.success === true) {
                console.log('resza1: ', r.result)
                if( r.result.has_customer === true) {
                    this.setState({
                        lineid_colect: 'check-circle',
                        lineid_colect_color: 'certain-category-icon-colect',
                        error_hidden: false,
                        error_color: 'green',
                        name: r.result.name,
                        line_id: r.result.line_id === null || r.result.line_id === '' || r.result.line_id === undefined ? 
                        '':this.changeFormatLineId(r.result.line_id),
                        user_id: r.result.user_id,
                        btnReset: false,
                    })
                } else if ( r.result.has_customer === false ) {
                    Modal.error({
                        title: 'เกิดข้อผิดพลาด',
                        content: 'ไม่พบข้อมูลหมายเลขโทรศัพท์นี้' 
                    });
                    this.clear()
                    this.setState({
                        lineid_colect: 'close-circle',
                        lineid_colect_color: 'certain-category-icon-uncolect',
                        error_hidden: false,
                        error_color: 'red'
                    })
                }
            } else if (r.success === false) {
                this.clear()
                Modal.error({
                    title: 'เกิดข้อผิดพลาด',
                    content: '' 
                });
                this.setState({
                    lineid_colect: 'close-circle',
                    lineid_colect_color: 'certain-category-icon-uncolect',
                    error_hidden: false,
                    error_color: 'red'
                })
                this.clear()
            }
        } catch (err) {
            this.clear()
            this.setState({
                lineid_colect: 'close-circle',
                lineid_colect_color: 'certain-category-icon-uncolect',
                error_hidden: false,
                error_color: 'red'
            })
            Modal.error({
                title: 'เกิดข้อผิดพลาด',
                content: `${err.message}`,
            });
        }
    }

    changeFormatDate = (date) => {
		let dateTemp = date.split('-')
		return `${dateTemp[2]}/${dateTemp[1]}/${dateTemp[0]}`
    }
    @autobind
    async resetLine() {
        const { user_id, telephone } = this.state;
        try {
            let obj = {
                user_id,
                telephone
            }
            const r = await POST(FOCUS_RESETLINEID, obj)
            if (r.success === true) {
                this.onSearch(telephone)
                Modal.success({
                    title: 'ดำเนินการสำเร็จ',
                    content: r.result 
                });
            } else if (r.success === false) {
                Modal.error({
                    title: 'เกิดข้อผิดพลาด',
                    content: '' 
                });
            }
        } catch(err) {
            Modal.error({
                title: 'เกิดข้อผิดพลาด',
                content: {err}
            });
        }
    }

    @autobind
    clear() {
        this.setState({
            name: '',
            line_id: '',
            user_id: '',
            telephone: '',
            btnReset: true,
        })
    }
    @autobind
    phonValidate() {
        fn.phonNumberValidate(this.state.telephone)
    }
    @autobind
    goBack() {
        this.props.history.goBack()

    }


    render() {
        const { loading,lineid_colect,lineid_colect_color,telephone, name, line_id, btnReset } = this.state

        if (loading) {
            return <div className="example" style={{ width: '100%' }}>
                <Spin />
            </div>
        }
        const { getFieldDecorator } = this.props.form;
        getFieldDecorator('resetLineScene', { initialValue: [] })
        return (
            <Form labelCol={{ span: 5 }} wrapperCol={{ span: 12 }} onSubmit={this.handleSubmit}>
                <div >
                    <Row style={{ marginBottom: '20px'}}> 
                        <BtnGoback title={'กลับ'} onClick={() => this.goBack()} ></BtnGoback>
                    </Row>
                    <Col span={12} className="border-claim" style={{ padding: '20px' }}>
                    <Row>
                        <Col span={24}>
                        <div style={{ display: 'flex', flexDirection: 'row'}}>
                                    <div style={{ flex: 1 }}>
                                        <div style={{ marginLeft: '20px'}} >
                                            <Form.Item label="เบอร์โทรศัพท์">
                                                {/* {getFieldDecorator('telephone', {
                                                    rules: [{ required: true, message: 'กรุณาระบุเบอร์โทร' }],
                                                })( */}
                                                <Input suffix={<Icon type={lineid_colect} style={{ fontSize: '16px' }} className={lineid_colect_color} />} style={{ width: '95%' }} defaultValue={telephone}
                                                    placeholder="เบอร์โทร"
                                                    value={telephone}
                                                    maxLength={10}
                                                    onKeyPress={e => this.onKeyPressSearch(e, 'telephone')}
                                                    onChange={e => this.onChange(e.target.value, 'telephone')}
                                                />
                                                {/* )} */}

                                            </Form.Item>
                                            {/* <div className={'column-claim-cr'} style={{ marginTop: -20, marginLeft: 5 }} >
                                                <font 
                                                    style={{ fontSize: '0.3cm', color: this.state.error_color }} 
                                                    hidden={error_hidden} 
                                                >
                                                    {line_error}
                                                </font>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div style={{ flex: 1, }}>
                                        <div style={{ marginLeft: '20px'}} >
                                            <Form.Item label="ชื่อ-นามสกุล">
                                                {/* {getFieldDecorator('name', {
                                                    rules: [{ required: true, message: 'กรุณาใส่ ชื่อ-นามสกุล' }],
                                                })( */}
                                                <Input style={{ width: '95%' }}
                                                    // defaultValue={name}
                                                    value={name}
                                                    disabled={true}
                                                    // onChange={e => this.onChange(e.target.value, 'name')}
                                                />
                                                {/* )} */}
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} 
                            style={{ display: 'flex'}}
                        >
                            <div style={{ flex: 1, }}>
                                <div style={{ marginLeft: '20px'}} >
                                    <Form.Item label="ไอดีไลน์">
                                        <Input style={{ width: '95%' }} 
                                            value={line_id}
                                            disabled={true}
                                            onChange={e => this.onChange(e.target.value, 'line_id')}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} 
                            style={{ display: 'flex', justifyContent: 'flex-end' }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row', marginRight: '30px' }}>
                                <Button onClick={this.clear}>
                                    เคลียข้อมูล
                                </Button>
                                <Button onClick={this.resetLine} disabled={btnReset} style={ !btnReset?{ backgroundColor: 'green', color: 'white' }:{}}>
                                    รีเซ็ตไอดีไลน์
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    </Col>
                </div >
            </Form>
        )
    }
}
// Form.create({ name: 'dynamic_rule' })(resetLineScene);
export default resetLineScene
