import React, { Component } from "react";
import { observer } from "mobx-react";
import autobind from "autobind-decorator";
import {
  Row,
  Col,
  Timeline,
  Icon,
  Button as BT,
  Modal,
  Spin,
  Input,
  Form,
  message,
  TimePicker,
  DatePicker,
  Select,
} from "antd";
import moment from "moment";
import { BtnGoback } from "../../../components/Button";
import {
  GET,
  POST,
  UPLOAD,
  FOCUS_DETAILCLAIM,
  FOCUS_EDITCLAIMBYID,
  FOCUS_EDITPAYBYID,
  FOCUS_GETPAYMENT,
  FOCUS_INSERTPAYMENT,
  FOCUS_GETALLPRODUCT,
  FOCUS_GET_OPTION_CHENNEL_CALIM,
  FOCUS_STORE_BY_TYPE,
} from "../../../service_focus";
import InputAddress from "react-thailand-address-autocomplete";
import { color } from "../../../resource";
const fn = require("../../../functions/functions");
const styles = {
  font: {
    fontSize: "0.4cm",
    marginTop: 3,
  },
  border: {
    border: "0.5px",
    borderStyle: "solid",
    borderColor: "#CCCCCC",
  },
  border_part1: {
    border: "0.5px",
    borderStyle: "solid",
    borderColor: "#CCCCCC",
    marginTop: 15,
  },
};
@Form.create({ name: "EditClaimScene" })
@observer
class EditClaimScene extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    this.state = {
      claim_doc: query.get("id"),
      loading: true,
      visible: false,
      address: [],
      product: [],
      datetime: "",
      optionTypeClaim: [],
      optionStore: [],
      optionBranch: [],
      dataStoreAll: [],
      book_date: new Date(),
    };
  }

  componentDidMount() {
    this.onFetchClaimById();
    this.getOptionType();
  }
  @autobind
  async onFetchClaimById() {
    const { claim_doc } = this.state;
    try {
      let res = await POST(FOCUS_DETAILCLAIM, { no_claim: claim_doc });
      let product = await GET(FOCUS_GETALLPRODUCT);

      if (res.success) {
        let data = res.result;
        let pd = product.result;
        let payment = await GET(`${FOCUS_GETPAYMENT}/${data.no_warranty}`);
        this.setState(
          {
            loading: false,
            status: data.user.status,
            ...data.user,
            ...data.address,
            ...data.product,
            ...data.pay,
            subdistrict: data.address.amphure,
            district: data.address.district,
            addressId: data.address.address_id,
            datetime: moment(new Date(data.pay.date_pay)),
            no_warranty: data.no_warranty,
            user_id: data.user.user_id,
            payment: payment.result.length === 1 ? 1 : 0,
            timepayment: data.pay.time_pay,
            item_code: data.product.itemcode,
            money_pay: data.pay.money_pay || 50,
            dd_product: pd.product,
            dd_brand: pd.brand,
            dd_model: pd.model,
            type_store: data.claim.type,
            book_date: !!data.claim.book_date
              ? moment(data.claim.book_date)
              : null,
            store_id: !!data.claim.claim_store_id
              ? +data.claim.claim_store_id
              : "",

            // branch_no: "",
          },
          () => this.getStoreByType(data.claim.type)
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  @autobind
  async getOptionType() {
    try {
      let res = await GET(FOCUS_GET_OPTION_CHENNEL_CALIM);
      if (res.success) {
        const dataOption = res.result.map((val, i) => {
          return { value: val.type_store, label: val.channel_claim_name };
        });
        this.setState({ optionTypeClaim: dataOption });
      }
    } catch (error) {
      console.log(error);
    }
  }

  @autobind
  async getStoreByType(type_store) {
    try {
      let res = await GET(`${FOCUS_STORE_BY_TYPE}/${type_store}`);
      if (res.success) {
        const dataStore = res.result.map((val, i) => {
          return { value: val.store_id, label: val.branch_name };
        });
        this.setState({ optionStore: dataStore, dataStoreAll: res.result });
      }
    } catch (error) {
      console.log(error);
    }
  }

  @autobind
  showModal() {
    this.setState({
      visible: true,
    });
  }

  @autobind
  onChange(e, ref) {
    if (ref === "type_store") {
      this.setState({ store_id: "" });
      this.getStoreByType(e);
    }
    this.setState({ [ref]: e });
  }

  _handleImageChange(e, type = "product") {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      type === "product"
        ? this.setState({
            file: file,
            url: reader.result,
          })
        : this.setState({
            file_pay: file,
            url_pay: reader.result,
          });
    };

    reader.readAsDataURL(file);
  }
  @autobind
  checkValidate() {
    const {
      name,
      telephone,
      province,
      district,
      subdistrict,
      type_store,
    } = this.state;
    if (
      !name || !telephone || type_store === "COMPANY"
        ? !province && !district && !subdistrict
        : ""
    ) {
      message.error("กรุณากรอกข้อมูลให้ครบถ้วน444");
    } else {
      this.onUpdate();
    }
  }
  @autobind
  async onUpdate() {
    const {
      telephone,
      file,
      file_pay,
      pay_image,
      province,
      district,
      subdistrict,
      zipcode,
      other,
      description,
      note,
      claim_doc,
      no_warranty,
      user_id,
      payment,
      item_code,
      money_pay,
      product_name,
      brand_name,
      model_name,
      type_store,
      book_date,
      store_id,
      ...pay
    } = this.state;
    let arr = {
      telephone,
      file,
      file_pay,
      province,
      district,
      subdistrict,
      zipcode,
      other,
      description,
      note,
      claim_doc,
      no_warranty,
      user_id,
      item_code,
      money_pay,
      product_name,
      brand_name,
      model_name,
      type_store,
      book_date,
      store_id,
      ...pay,
    };

    let formdata_claim = fn.append_focus_claim(arr);
    let formdata_pay = fn.append_focus_pay(arr);

    this.setState({ loading: true });

    try {
      //check tb pay ment[]n ////
      let res = await Promise.all([
        UPLOAD(FOCUS_EDITCLAIMBYID, formdata_claim),
        payment === 1
          ? UPLOAD(FOCUS_EDITPAYBYID, formdata_pay)
          : file_pay && payment === 0
          ? UPLOAD(FOCUS_INSERTPAYMENT, formdata_pay)
          : "",
      ]);

      if (
        type_store === "ONLINE"
          ? res[0].success && res[1].success
          : res[0].success
      ) {
        this.setState({ loading: false });
        this.goBack();
      } else {
        this.setState({ loading: false });
        message.error("ระบบผิดพลาดกรุณาลองใหม่อีกครั้ง");
      }
    } catch (error) {
      console.log(error);
    }
  }

  @autobind
  goBack() {
    this.props.history.goBack();
  }
  onSelect = (fullAddress) => {
    const { subdistrict, district, province, zipcode } = fullAddress;
    this.setState({
      subdistrict,
      district,
      province,
      zipcode,
    });
  };
  @autobind
  onChangeAddress(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  @autobind
  onChangeTime(time) {
    this.setState({
      time_pay: time,
    });
  }

  render() {
    const {
      dd_product,
      dd_brand,
      dd_model,
      product_id,
      claim_doc,
      claim_id,
      status,
      visible,
      loading,
      name,
      telephone,
      other,
      url,
      product_name,
      brand_name,
      model_name,
      item_code,
      claimdate,
      description,
      note,
      timepayment,
      money_pay,
      optionTypeClaim,
      type_store,
      optionStore,
      store_id,
      optionBranch,
      branch_no,
      book_date,
      ...pay
    } = this.state;


    const uploadButton = (
      <div
        style={{
          width: "6cm",
          height: "7cm",
          marginTop: 20,
          borderColor: "#EFF2F5",
        }}
      ></div>
    );
    const { getFieldDecorator } = this.props.form;
    getFieldDecorator("EditClaimScene", { initialValue: [] });
    if (loading) {
      return (
        <div className="example" style={{ width: "100%" }}>
          <Spin />
        </div>
      );
    }
    return (
      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 12 }}
        onSubmit={this.handleSubmit}
      >
        <div>
          <BtnGoback title={"กลับ"} onClick={() => this.goBack()}></BtnGoback>
          <Modal
            title="ยืนยันการรับงาน"
            visible={visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            width={"30vw"}
            cancelText={<font style={{ fontSize: "1vw" }}>ยกเลิก</font>}
            okText={<font style={{ fontSize: "1vw" }}>ยืนยัน</font>}
          >
            <p>กรุณากดยืนยันเพื่อรับงาน {claim_id}</p>
          </Modal>
          {/* <Edit address={address.zipcode}></Edit> */}
          {/* #################################   สถานะ  #################################*/}
          <Row>
            <Col span={6} push={18}>
              <div
                style={{
                  border: "0.5px",
                  borderStyle: "solid",
                  borderColor: "#FFFFFF",
                  margin: 15,
                }}
              >
                <div
                  className={"column"}
                  style={{
                    margin: 10,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h3>สถานะ</h3>
                </div>
                <div
                  className={"column"}
                  style={{
                    margin: 10,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Timeline>
                    <Timeline.Item
                      dot={
                        <Icon
                          type="check-circle"
                          style={{
                            fontSize: status === 1 ? "26px" : "20px",
                            color: status === 1 ? color.dplus_main : "black",
                          }}
                        />
                      }
                      style={{ marginTop: 20 }}
                    >
                      <font className={"text-status"}>รอชำระเงิน</font>
                    </Timeline.Item>
                    <Timeline.Item
                      dot={
                        <Icon
                          type="check-circle"
                          style={{
                            fontSize: status === 2 ? "26px" : "20px",
                            color: status === 2 ? color.dplus_main : "black",
                          }}
                        />
                      }
                      style={{ marginTop: 15 }}
                    >
                      <font className={"text-status"}>รอการตรวจสอบ</font>
                    </Timeline.Item>
                    <Timeline.Item
                      dot={
                        <Icon
                          type="check-circle"
                          style={{
                            fontSize: status === 3 ? "26px" : "20px",
                            color: status === 3 ? color.dplus_main : "black",
                          }}
                        />
                      }
                      style={{ marginTop: 15 }}
                    >
                      <font className={"text-status"}>ชำระเงินเรียบร้อย</font>
                    </Timeline.Item>
                    <Timeline.Item
                      dot={
                        <Icon
                          type="check-circle"
                          style={{
                            margintop: 5,
                            fontSize: status === 4 ? "26px" : "20px",
                            color: status === 4 ? "green" : "black",
                          }}
                        />
                      }
                      style={{ marginTop: 15 }}
                    >
                      <font className={"text-status"}>จัดส่งเรียบร้อย</font>
                    </Timeline.Item>
                    <Timeline.Item
                      dot={
                        <Icon
                          type="check-circle"
                          style={{
                            margintop: 5,
                            fontSize: status === 99 ? "26px" : "20px",
                            color: status === 99 ? color.dplus_main : "black",
                          }}
                        />
                      }
                      style={{ marginTop: 15 }}
                    >
                      <font className={"text-status"}>ไม่อนุมัติ</font>
                    </Timeline.Item>
                  </Timeline>
                </div>
              </div>
            </Col>
            {/* #################################   ข้อมูลส่วนตัว  #################################*/}
            <Col span={18} pull={6}>
              <div
                style={{
                  border: "0.5px",
                  borderStyle: "solid",
                  borderColor: "#CCCCCC",
                  margin: 15,
                }}
              >
                <div className={"column"} style={{ margin: 10 }}>
                  <h3>ข้อมูลส่วนตัว</h3>
                </div>

                <div
                  className={"column"}
                  style={{ margin: 15, marginTop: -15, marginLeft: 25 }}
                >
                  <div className={"row-between"} style={{ fontSize: "1cm" }}>
                    <font style={styles.font}>
                      <font style={{ color: "red" }}>* </font>ชื่อ-นามสกุล
                    </font>
                    <font style={styles.font}>{name}</font>
                  </div>
                  <div className={"row-between"}>
                    <font style={styles.font}>
                      <font style={{ color: "red" }}>* </font>หมายเลขโทรศัพท์
                    </font>
                    <font style={styles.font}>{telephone}</font>
                  </div>
                  <div className={"row-between"}>
                    <div>
                      <font style={styles.font}>
                        {type_store == "COMPANY" ? (
                          <font style={{ color: "red" }}>* </font>
                        ) : (
                          ""
                        )}
                        ที่อยู่
                      </font>
                    </div>
                    <div className={"column"}>
                      <div style={{ display: "flex", marginBottom: "1em" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <InputAddress
                            style={{ marginRight: 10, width: "95%" }}
                            address="subdistrict"
                            value={this.state.subdistrict}
                            placeholder={"แขวง/ตำบล"}
                            onChange={(e) => this.onChangeAddress(e)}
                            onSelect={this.onSelect}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <InputAddress
                            style={{ width: "95%" }}
                            address="district"
                            value={this.state.district}
                            placeholder={"เขต/อำเภอ"}
                            onChange={(e) => this.onChangeAddress(e)}
                            onSelect={this.onSelect}
                          />
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {/* <font style={{ ...styles.font, marginLeft: 10 }}>อ.</font> */}

                          <InputAddress
                            style={{ marginRight: 10, width: "95%" }}
                            address="province"
                            value={this.state.province}
                            placeholder={"จังหวัด"}
                            onChange={(e) => this.onChangeAddress(e)}
                            onSelect={this.onSelect}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {/* <font style={{ ...styles.font, marginLeft: 10 }}>รหัสไปรษณีย์</font> */}
                          <InputAddress
                            style={{ width: "95%" }}
                            address="zipcode"
                            value={this.state.zipcode}
                            placeholder={"รหัสไปรษณีย์"}
                            onChange={(e) => this.onChangeAddress(e)}
                            onSelect={this.onSelect}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={"row-between"}>
                    <font style={styles.font}>เพิ่มเติม</font>
                    {/* <font style={styles.font}>{other}</font> */}
                    <Input.TextArea
                      style={{ width: "30%", alignItems: "right" }}
                      defaultValue={other}
                      value={other}
                      onChange={(e) => this.onChange(e.target.value, "other")}
                      autosize={{ minRows: 1, maxRows: 2 }}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          {/* #################################   button  #################################*/}
          <Row>
            <Col span={6} push={18}></Col>
            {/* #################################   ข้อมูลการแจ้งเคลม  #################################*/}
            <Col span={9} pull={6}>
              <div
                style={{
                  border: "0.5px",
                  borderStyle: "solid",
                  borderColor: "#CCCCCC",
                  margin: 15,
                  marginTop: -3,
                }}
              >
                <div className={"column"} style={{ margin: 10 }}>
                  <h3>ข้อมูลการแจ้งเคลม</h3>
                </div>
                <div
                  className={"column"}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 35,
                  }}
                >
                  <img
                    src={url}
                    alt="new"
                    style={{ width: "6cm", height: "7cm", marginBottom: 20 }}
                  />
                  <input
                    className="fileInput"
                    style={{ marginLeft: 30 }}
                    type="file"
                    onChange={(e) => this._handleImageChange(e)}
                  />
                </div>
              </div>
            </Col>
            {/* #################################   รายละเอียดการเคลม  #################################*/}
            <Col span={9} pull={6}>
              <div
                style={{
                  border: "0.5px",
                  borderStyle: "solid",
                  borderColor: "#CCCCCC",
                  margin: 15,
                  marginTop: -3,
                }}
              >
                <div style={{ margin: 15, marginLeft: 15 }}>
                  <div className={"row-between"}>
                    <font style={styles.font}>เลขที่การเคลม</font>
                    <font style={styles.font}>{claim_doc}</font>
                  </div>
                  <div className={"row-between"} style={{ marginTop: 10 }}>
                    <font style={styles.font}>รหัสลงทะเบียนสินค้า</font>
                    <font style={styles.font}>{product_id}</font>
                  </div>
                  <div className={"row-between"} style={{ marginTop: 10 }}>
                    <font style={styles.font}>ชื่อสินค้าผลิตภัณฑ์</font>
                    <font style={styles.font}>{product_name}</font>
                  </div>
                  <div className={"row-between"} style={{ marginTop: 10 }}>
                    <font style={styles.font}>
                      <font style={{ color: "red" }}>* </font>ชื่อสินค้า
                    </font>
                    <Select
                      showSearch
                      style={{ width: "65%" }}
                      placeholder="เลือกรุ่นมือถือ"
                      optionFilterProp="children"
                      onChange={(e) => this.onChange(e, "product_name")}
                      //onSearch={this.onSearchProduct}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      value={product_name}
                    >
                      {dd_product.map((item) => (
                        <Select.Option value={item.product_name}>
                          {item.product_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                  <div className={"row-between"} style={{ marginTop: 10 }}>
                    <font style={styles.font}>
                      <font style={{ color: "red" }}>* </font>ยี่ห้อ
                    </font>
                    <Select
                      showSearch
                      style={{ width: "65%" }}
                      placeholder="เลือกยี่ห่อ"
                      optionFilterProp="children"
                      onChange={(e) => this.onChange(e, "brand_name")}
                      //onSearch={this.onSearchProduct}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      value={brand_name}
                    >
                      {dd_brand.map((item) => (
                        <Select.Option value={item.brand_name}>
                          {item.brand_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                  <div className={"row-between"} style={{ marginTop: 10 }}>
                    <font style={styles.font}>
                      <font style={{ color: "red" }}>* </font>รุ่น
                    </font>
                    <Select
                      showSearch
                      style={{ width: "65%" }}
                      placeholder="เลือกรุ่น"
                      optionFilterProp="children"
                      onChange={(e) => this.onChange(e, "model_name")}
                      //onSearch={this.onSearchProduct}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      value={model_name}
                    >
                      {dd_model.map((item) => (
                        <Select.Option value={item.model_name}>
                          {item.model_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>

                  <div className={"row-between"} style={{ marginTop: 10 }}>
                    <font style={styles.font}>วันที่แจ้งเคลม</font>
                    <font style={styles.font}>
                      {moment(claimdate).format("DD-MM-YYYY HH:mm")}
                    </font>
                  </div>
                  <div className={"row-between"} style={{ marginTop: 10 }}>
                    <font style={styles.font}>
                      <font style={{ color: "red" }}>* </font>อาการเสีย
                    </font>
                    {/* <font style={styles.font}>{description}</font> */}
                    <Input.TextArea
                      style={{ width: "65%", alignItems: "right" }}
                      defaultValue={description}
                      value={description}
                      onChange={(e) =>
                        this.onChange(e.target.value, "description")
                      }
                      autosize={{ minRows: 1, maxRows: 2 }}
                    />
                  </div>
                  <div className={"row-between"} style={{ marginTop: 10 }}>
                    <font style={{ ...styles.font }}>รหัสสินค้า</font>
                    <Input.TextArea
                      style={{ width: "65%", alignItems: "right" }}
                      defaultValue={item_code}
                      value={item_code ? item_code : ""}
                      onChange={(e) =>
                        this.onChange(e.target.value, "item_code")
                      }
                      autosize={{ minRows: 1, maxRows: 2 }}
                    />
                  </div>

                  {/* เพิ่มช่องทางการเคลม */}
                  <div className={"row-between"} style={{ marginTop: 10 }}>
                    <font style={styles.font}>
                      <font style={{ color: "red" }}>* </font>ประเภทการเคลม
                    </font>
                    <Select
                      showSearch
                      style={{ width: "65%" }}
                      placeholder="เลือกประเภทการเคลม"
                      optionFilterProp="children"
                      onChange={(e) => this.onChange(e, "type_store")}
                      //onSearch={this.onSearchProduct}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      value={type_store}
                    >
                      {optionTypeClaim.map((item) => (
                        <Select.Option value={item.value}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                  {type_store !== "COMPANY" ? (
                    <>
                      <div className={"row-between"} style={{ marginTop: 10 }}>
                        <font style={styles.font}>
                          <font style={{ color: "red" }}>* </font>ร้าน/สาขาเคลม
                        </font>
                        <Select
                          showSearch
                          style={{ width: "65%" }}
                          placeholder="เลือกช่องทางการเคลม"
                          optionFilterProp="children"
                          onChange={(e) => this.onChange(e, "store_id")}
                          //onSearch={this.onSearchProduct}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          value={store_id}
                        >
                          <Select.Option value={""}>
                            {"---กรุณาเลือกร้านค้า/สาขา----"}
                          </Select.Option>
                          {optionStore.map((item) => (
                            <Select.Option value={item.value}>
                              {item.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                      {type_store === "FOCUS" ? (
                        <div
                          className={"row-between"}
                          style={{ marginTop: 10 }}
                        >
                          <font style={styles.font}>
                            <font style={{ color: "red" }}>* </font>
                            วันที่จองเคลม
                          </font>
                          <DatePicker
                            style={{ width: "65%" }}
                            placeholder={"กรุณาเลือกวันจัดส่ง"}
                            value={book_date}
                            format={"DD/MM/YYYY"}
                            onChange={(date) =>
                              this.onChange(date, "book_date")
                            }
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : null}

                  {/* เพิ่มช่องทางการเคลม */}

                  <div className={"row-between"} style={{ marginTop: 10 }}>
                    <font style={{ ...styles.font }}>หมายเหตุ</font>
                    <Input.TextArea
                      style={{ width: "65%", alignItems: "right" }}
                      defaultValue={note}
                      value={note}
                      onChange={(e) => this.onChange(e.target.value, "note")}
                      autosize={{ minRows: 1, maxRows: 2 }}
                    />
                  </div>
                  <div
                    className={"column"}
                    style={{
                      marginBottom: 5,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <font style={{ fontSize: "0.5cm", marginTop: 60 }}>
                      แจ้งเคลมมาแล้ว {fn.dateDiff(claimdate)} วัน
                    </font>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={6} push={18}>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "flex-end",
                  margin: 15,
                  // height: "11cm",
                  height: "auto",
                  marginTop: 30,
                }}
              >
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    width: "30cm",
                  }}
                >
                  <BT
                    style={{
                      width: "100px",
                      height: "100px",
                      backgroundColor: color.dplus_main,
                    }}
                    type="primary"
                    onClick={() => this.checkValidate()}
                  >
                    บันทึก
                  </BT>
                </div>
              </div>
            </Col>
            {/* #################################   ข้อมูลส่วนตัว  #################################*/}
            {type_store !== "COMPANY" ? (
              ""
            ) : (
              <>
                <Col span={18} pull={6}>
                  <div
                    style={{
                      border: "0.5px",
                      borderStyle: "solid",
                      borderColor: "#CCCCCC",
                      margin: 15,
                    }}
                  >
                    <div className={"column"} style={{ margin: 10 }}>
                      <h3>ข้อมูลการโอนเงิน</h3>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        margin: 15,
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          justifyContent: "center",
                          marginLeft: 30,
                          marginBottom: 10,
                        }}
                      >
                        {/* {pay.url_pay ? <img src={pay.url_pay} alt="new" style={{ width: '6cm', height: '7cm', marginBottom: 50 }} /> : <div></div>} */}
                        <div
                          className={"column"}
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: 35,
                          }}
                        >
                          {pay.url_pay ? (
                            <img
                              src={pay.url_pay}
                              alt="new"
                              style={{
                                width: "6cm",
                                height: "7cm",
                                marginBottom: 20,
                              }}
                            />
                          ) : (
                            uploadButton
                          )}
                          <input
                            className="fileInput"
                            style={{ marginLeft: 30, marginTop: 5 }}
                            type="file"
                            onChange={(e) => this._handleImageChange(e, "slip")}
                          />
                        </div>
                      </div>
                      <div style={{ flex: 1, marginLeft: 10 }}>
                        <div
                          className={"row-between"}
                          style={{ marginTop: 20 }}
                        >
                          <font style={styles.font}>ธนาคาร</font>
                          <Input.TextArea
                            style={{ width: "50%", alignItems: "right" }}
                            defaultValue={pay.bank_pay}
                            value={pay.bank_pay}
                            onChange={(e) =>
                              this.onChange(e.target.value, "bank_pay")
                            }
                            autosize={{ minRows: 1, maxRows: 2 }}
                          />
                        </div>
                        <div
                          className={"row-between"}
                          style={{ marginTop: 20 }}
                        >
                          <font style={styles.font}>วันที่โอน</font>
                          <DatePicker
                            value={moment(pay.date_pay || new Date())}
                            onChange={(date) => this.onChange(date, "date_pay")}
                            format="DD/MM/YYYY"
                          />
                        </div>
                        <div
                          className={"row-between"}
                          style={{ marginTop: 20 }}
                        >
                          <font style={styles.font}>เวลาที่โอน</font>
                          <TimePicker
                            onChange={this.onChangeTime}
                            format="HH:mm:ss"
                            value={moment(
                              pay.time_pay || new Date(),
                              "HH:mm:ss"
                            )}
                          />
                        </div>
                        <div
                          className={"row-between"}
                          style={{ marginTop: 20 }}
                        >
                          <font style={styles.font}>จำนวนเงิน</font>
                          <Input.TextArea
                            style={{ width: "50%", alignItems: "right" }}
                            defaultValue={money_pay}
                            value={money_pay}
                            onChange={(e) =>
                              this.onChange(e.target.value, "money_pay")
                            }
                            autosize={{ minRows: 1, maxRows: 1 }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </>
            )}
          </Row>
        </div>
      </Form>
    );
  }
}

export default EditClaimScene;
