import React, { Component } from "react";
import { observer } from "mobx-react";
import WrapperComponents from "../../../components/hoc/WrapperComponents";
import dayjs from "dayjs";
import moment from "moment";
import { Table, Button as BT, Modal, Input, message, DatePicker } from "antd";
import { columnTableClaim, color } from "../../../resource";
import autobind from "autobind-decorator";
import {
  GET,
  RIZZ_GETCLAIM,
  POST,
  RIZZ_INSERT_EMS,
  RIZZ_DETAILCLAIMBYID,
  RIZZ_UPDATESTATUS,
} from "../../../service";
import { encryptParameter } from "../../../functions/functions";
import { Button } from "../../../components/Button";
import { ModalEMS } from "../../../components/Modal";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { ThemeConsumer } from "styled-components";
let defaultData = [];

@WrapperComponents()
@observer
class ClaimScene extends Component {
  constructor(props) {
    super(props);
    const date = new Date();
    date.setDate(date.getDate() - 5);

    this.state = {
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      isLoadingData: true,
      visible: false,
      shipping_date: moment(),
      ems: "",
      data: [],
      searchText: "",
      claim_doc: "",
      isLoadingEMS: false,
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      isOpen: false,
      isExpandable: true,
      obj_image: {},
    };
  }

  componentDidMount() {
    this.onFetchClaim();
  }

  @autobind
  async onFetchClaim(
    date_start = this.state.start_date,
    date_end = this.state.end_date
  ) {
    try {
      let body = {
        date_start: date_start,
        date_end: date_end,
      };

      const res = await POST(RIZZ_GETCLAIM, body);
      console.log(res);

      if (res.success) {
        const data = res.result.map((el, i) => {
          const { customer, product, shipping, status } = el;
          const diff = dayjs(product.date_end_warranty).diff(dayjs(), "d");
          if (el.status === 0) {
            console.log(el.claim_doc, product.product_name);
          }
          return {
            ...el,
            no: i + 1,
            key: el.claim_doc,
            name: customer.name,
            telephone: customer.telephone,
            product_name: product.product_name,
            create_at: dayjs(el.create_at)
              .add(543, "y")
              .format("DD/MM/YYYY"),
            time_warranty: diff <= 0 ? 0 : diff,
            shipping_date: shipping.shipping_date
              ? dayjs(shipping.shipping_date)
                  .add(543, "y")
                  .format("DD/MM/YYYY")
              : "",
            confirm_ems:
              status === 1 || status === 2
                ? shipping.ems
                  ? shipping.ems
                  : "กรอกเลขพัสดุ"
                : "",
          };
        });
        defaultData = data;

        this.setState({
          data: defaultData,
          isLoadingData: false,
          visible: false,
          ems: "",
          isLoadingEMS: false,
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({ isLoadingData: false });
    }
  }

  start = () => {
    let claim_doc = this.state.selectedRowKeys;
    this.setState({ loading: true });

    setTimeout(() => {
      this.setState(
        {
          selectedRowKeys: [],
          loading: false,
        },
        () =>
          this.props.history.push({
            pathname: "/rizz/billshipping",
            state: {
              id: encryptParameter({ claim_id: claim_doc }),
            },
          })
      );
    }, 1000);
  };

  onSelectChange = (selectedRowKeys) => {
    console.log(selectedRowKeys);
    this.setState({ selectedRowKeys });
  };

  /**
   * ฟังก์ชั่นสำหรับวิ่งไปหน้า Detail Claim
   */
  @autobind
  goDetail(record) {
    this.props.history.push(`/rizz/claim_detail?id=${record.claim_doc}`);
  }
  @autobind
  goCreate() {
    this.props.history.push("/rizz/claim_create");
  }
  @autobind
  clickImage(src_image) {
    console.log(src_image);
    this.setState({
      zoomImage: src_image,
      isOpen: true,
    });
  }

  @autobind
  insertEMS(record, type) {
    const {
      claim_doc,
      shipping: { shipping_date, ems },
    } = record;
    console.log(type);
    if (type === 0) {
      this.setState({ visible: true, claim_doc, type });
    } else {
      console.log(moment(shipping_date));
      this.setState({
        visible: true,
        claim_doc,
        type,
        shipping_date: moment(shipping_date),
        ems,
      });
    }
  }
  @autobind
  insertUnactiveCode(record, type) {
    const {
      claim_doc,
      shipping: { shipping_date, ems },
    } = record;
    console.log("test");
  }

  @autobind
  handleCancel() {
    this.setState({
      visible: false,
      claim_doc: "",
      type: 0,
      shipping_date: moment(),
      ems: "",
    });
  }

  @autobind
  async handleOk() {
    this.setState({ isLoadingEMS: true }, async () => {
      try {
        const { ems, shipping_date, claim_doc } = this.state;
        if (!claim_doc) {
          message.error("กรุณาใส่ข้อมูลให้ครบ");
          return;
        }

        await POST(RIZZ_INSERT_EMS, {
          ems,
          shipping_date,
          claim_id: claim_doc,
        });
        this.onFetchClaim();
      } catch (error) {
        this.setState({ visible: false, ems: "" });
      }
    });
  }

  @autobind
  onChange(e, ref) {
    console.log(e);
    this.setState({ [ref]: e });
  }

  @autobind
  onKeyPressSearch(event) {
    if (event.key === "Enter") {
      this.onSearch();
    }
  }

  @autobind
  onSearch() {
    const { searchText } = this.state;
    if (!searchText) {
      return this.setState({ data: defaultData });
    }

    const d = defaultData.filter((el) => {
      return Object.values(el).some((val) => {
        return val
          ? val
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          : false;
      });
    });

    this.setState({ data: d });
  }
  @autobind
  onChangeRangePicker(dates) {
    let start = moment(dates[0]).format("YYYY-MM-DD");
    let end = moment(dates[1]).format("YYYY-MM-DD");
    console.log(start);
    console.log(end);
    this.setState({
      selectedRowKeys: [],
      start_date: start,
      end_date: end,
      isLoadingData: true,
    });
    this.onFetchClaim(start, end);
  }
  @autobind
  async openExpand(expanded, recode) {
    // console.log(recode)
    const { claim_doc } = recode;

    let res = await POST(RIZZ_DETAILCLAIMBYID, { claim_doc });
    console.log(res);
    if (res.success) {
      let obj_image = this.state.obj_image;
      obj_image = { ...obj_image, [claim_doc]: res.result.detailclaim.url };
      this.setState({
        //  claim_image: res.result.url,
        obj_image,
      });
    }
  }
  @autobind
  async approveClaim() {
    const { data, selectedRowKeys } = this.state;
    let result = [];
    let notcolect = [];
    console.log(selectedRowKeys);
    result = selectedRowKeys.map((sl) => {
      let r = data.filter((el) => el.claim_doc === sl);
      if (r[0]) {
        return {
          claim_doc: r[0].claim_doc,
          status: r[0].status,
          claim_id: r[0].claim_id,
        };
      }
    });
    console.log(result);
    result.map((el) => {
      if (el.status !== 0) {
        console.log(el.status);
        notcolect.push(el.claim_id);
      }
    });
    console.log(notcolect);
    console.log(notcolect.length);
    if (notcolect.length > 0) {
      message.error(`สถานะงานเคลม :${notcolect[0]} ไม่สามารถอนุมัติได้`);
    } else {
      this.setState({ isLoadingData: true });
      try {
        let res;
        await Promise.all(
          result.map(async (el) => {
            res = await POST(RIZZ_UPDATESTATUS, {
              claim_doc: el.claim_doc,
              status: 1,
              reject_detail: "",
            });
          })
        );
        console.log(res);
        if (res.success) {
          this.onFetchClaim();
          // this.setState({
          //     isLoadingData: false,
          // })
        }
      } catch (error) {
        message.error("ระบบขัดคล่องกรุณาลองใหม่ภายหลัง");
      }
    }
  }

  render() {
    const {
      loading,
      selectedRowKeys,
      data,
      isLoadingData,
      shipping_date,
      ems,
      searchText,
      isOpen,
    } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    return (
      <div>
        {/*###################### MODAL EMS ######################*/}
        <Modal
          title={<p style={{ textAlign: "center" }}>กรุณาใส่เลข EMS</p>}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={"30vw"}
          confirmLoading={this.state.isLoadingEMS}
          cancelText={<font style={{ fontSize: "1vw" }}>ยกเลิก</font>}
          okText={<font style={{ fontSize: "1vw" }}>ยืนยัน</font>}
          okButtonProps={{
            style: {
              backgroundColor: color.rizz_primary,
              color: "#fff",
              borderColor: color.rizz_primary,
            },
          }}
        >
          <ModalEMS
            obj={{ shipping_date, ems }}
            onChange={(e, ref) => this.onChange(e, ref)}
          />
        </Modal>
        {isOpen && (
          <Lightbox
            mainSrc={this.state.zoomImage}
            onCloseRequest={() => this.setState({ isOpen: false })}
          />
        )}

        <div className={"column"}>
          {/*###################### HEAD ######################*/}
          <div className={"row-between"}>
            <div style={{ display: "flex" }}>
              <BT
                type="primary"
                onClick={this.start}
                disabled={!hasSelected}
                loading={loading}
              >
                บิลใบส่งสินค้า
              </BT>
              <span style={{ marginLeft: 8 }}>
                {hasSelected ? `เลือก ${selectedRowKeys.length} รายการ` : ""}
              </span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Input.Search
                style={{
                  width: "20vw",
                  borderRadius: "10vw",
                  paddingLeft: "1vw",
                }}
                placeholder={"ค้นหาข้อมูล"}
                value={searchText}
                onKeyPress={(e) => this.onKeyPressSearch(e)}
                onSearch={(value) => this.onSearch()}
                onChange={(e) => this.onChange(e.target.value, "searchText")}
              />
              <Button title={"ยืนเรื่องเคลม"} onPress={() => this.goCreate()} />
            </div>
          </div>
          <div className={"row-between"}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "center",
              }}
            >
              <DatePicker.RangePicker
                //value={moment(new Date())}
                placeholder={["วันที่เริ่มต้น", "วันที่สิ้นสุด"]}
                format="YYYY-MM-DD"
                defaultValue={[
                  moment(this.state.start_date),
                  moment(this.state.end_date),
                ]}
                onChange={this.onChangeRangePicker}
                size="default"
              />
            </div>
          </div>
          <div className={"row-between"}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "center",
              }}
            >
              <span style={{ marginRight: 8 }}>
                {hasSelected ? `เลือก ${selectedRowKeys.length} รายการ` : ""}
              </span>

              <BT
                type="primary"
                style={{
                  backgroundColor: color.rizz_primary,
                  borderColor: color.rizz_primary,
                }}
                onClick={this.approveClaim}
                disabled={!hasSelected}
                loading={loading}
              >
                อนุมัติเรื่องเคลม
              </BT>
            </div>
          </div>

          {/*###################### TABLE ######################*/}
          <Table
            title={() => <h2>การเคลมสินค้า</h2>}
            rowSelection={rowSelection}
            columns={columnTableClaim(
              (record) => this.goDetail(record),
              (record, type) => this.insertEMS(record, type),
              (record, type) => this.insertEMS(record, type)
            )}
            rowClassName={(record, i) => (i % 2 === 1 ? "even" : "")}
            dataSource={data}
            expandedRowRender={(record) => this.renderExpand(record)}
            // rowExpandable={this.renderExpand()}
            onExpand={(expanded, record) => this.openExpand(expanded, record)}
            bordered
            pagination={{ pageSize: 15 }}
            loading={isLoadingData}
            size="small"
          />
        </div>
      </div>
    );
  }
  renderExpand(record) {
    console.log("renderExpand");
    console.log();
    const { obj_image } = this.state;
    const { description, address, claim_doc } = record;
    console.log(obj_image);
    return (
      <div
        style={{ padding: "1vw", display: "flex", alignItems: "center" }}
        key={1}
      >
        {obj_image && (
          <img
            src={obj_image[claim_doc]}
            style={{ width: 100, height: 100 }}
            onClick={() => this.clickImage(obj_image[claim_doc])}
            className={"contain"}
          />
        )}
        <div style={{ marginLeft: "1vw" }}>
          <div style={{ display: "flex", marginBottom: "-12px" }}>
            <p style={{ margin: 0, width: "100px" }}>อาการเสีย</p>
            <p>{description}</p>
          </div>

          <div style={{ display: "flex", marginBottom: "-12px" }}>
            <p style={{ margin: 0, width: "100px" }}>ที่อยู่จัดส่ง</p>
            <p>{` ${address.other} ${address.province} ${address.district} ${address.amphure} ${address.zipcode}`}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default ClaimScene;
