import React, { Component } from "react";
import { observer } from "mobx-react";
import QRCode, { childContextTypes } from "qrcode.react";
import { FocusColumnTableDealer, mockData } from "../../../resource";
import {
  EditOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  ModalDownloadQR,
  ModalAddDealer,
  ModalEditDealer,
} from "../../../components/Modal";
import {
  Button as BT,
  Modal,
  message,
  Table,
  Row,
  Col,
  Form,
  Input,
} from "antd";
import {
  POST,
  GET,
  UPLOAD,
  FOCUS_GET_STORE_LIST,
  FOCUS_GET_OPTION_TYPESTORE,
  FOCUS_SEARCH_STORE_NO,
  FOCUS_INSERT_DEALER,
  FOCUS_EDIT_DEALER,
} from "../../../service_focus";
import {
  formAddDealer,
  formEditDealer,
  formFilterDealer,
} from "../../../resource/constant";
const fn = require("../../../functions/functions");

class ManageDealerScene extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataDealer: [],
      openModalQR: false,
      valueQR: {},
      titleQR: {},
      store_no: "",
      store_name: "",
      isLoadingData: true,
      openModalEdit: false,
      openModalAdd: false,
      optionTypeStore: [],
      formDataDealer: formAddDealer,
      formEditDealer,
      formFilterDealer,
      dataAddDealer: {},
      allowClear: false,
      dataEditDealer: [],
    };
  }

  async componentDidMount() {
    let api = [this.getDataDealer(), this.getOptionTypeStore()];
    await Promise.all(api);
  }

  getOptionTypeStore = async () => {
    try {
      let res = await GET(FOCUS_GET_OPTION_TYPESTORE);
      if (res.success) {
        const result = res.result;
        const dataOption = result.map((val, i) => {
          return { value: val.type_store, label: val.description };
        });

        this.setState({ optionTypeStore: dataOption });
      }
    } catch (error) {
      console.log(error);
    }
  };

  getDataDealer = async () => {
    const { formFilterDealer } = this.state;
    let obj = {};
    Array.isArray(formFilterDealer) &&
      formFilterDealer.forEach((val) => {
        obj[val.fieldName] = val.value;
      });

    this.setState({ loading: true });
    try {
      let res = await POST(FOCUS_GET_STORE_LIST, obj);
      if (res.success) {
        const result = res.result;
        const data = result.map((val, i) => {
          return {
            no: i + 1,
            ...val,
            qrStore: (
              <QRCode
                value={val.store_name}
                size={25}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  this.setState({
                    openModalQR: true,
                    valueQR: {
                      store_id: val.store_id,
                      store_name: val.store_name,
                      store_no: val.store_no,
                      branch_no: val.branch_no,
                      branch_name: val.branch_name,
                    },
                    titleQR: {
                      storeName: val.store_name,
                      branchName: val.branch_name,
                    },
                  })
                }
              />
            ),
            action: (
              <EditOutlined
                style={{ color: "#faad14" }}
                onClick={() => this.modalEditDealer(val)}
              />
            ),
          };
        });
        this.setState({ dataDealer: data, isLoadingData: false });
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleSelect = (value) => {
    const { formDataDealer } = this.state;
    formDataDealer.map((val, i) => {
      if (val.fieldName === "type_store") {
        val.value = value;
      }
    });
    this.setState({ formDataDealer });
  };

  modalEditDealer = (val) => {
    const { formEditDealer } = this.state;
    formEditDealer.map((props, i) => {
      switch (props.fieldName) {
        case "store_no":
          props.value = val["store_no"];
          break;
        case "store_name":
          props.value = val["store_name"];
          break;
        case "branch_name":
          props.value = val["branch_name"];
          break;
        case "store_tel":
          props.value = val["store_tel"];
          break;
        case "type_store":
          props.value = val["type_store"];
          break;
        case "store_status":
          props.value = val["store_status"];
          break;
        case "sale_no":
          props.value = val["sale_no"];
          break;
        case "store_id":
          props.value = val["store_id"];
          break;
        case "password":
          props.value = val["password"];
          break;
        default:
          break;
      }
    });
    this.setState({ openModalEdit: true, formEditDealer });
  };

  handleSearchStoreNo = async (value) => {
    const { formDataDealer } = this.state;
    console.log({ value });
    if (!value) {
      message.error("กรุณากรอกรหัสร้านค้า");
    } else {
      try {
        let res = await GET(`${FOCUS_SEARCH_STORE_NO}/${value}`);
        if (res.success) {
          const result = res.result;

          formDataDealer.map((val, i) => {
            switch (val.fieldName) {
              case "store_no":
                val.value = result.store_no;
                val.textAlert = result.message;
                break;
              case "store_name":
                val.value = result.store_name;
                break;
              case "store_tel":
                val.value = result.store_tel;
                break;
              case "type_store":
                val.value = result.type_store;
                break;
              case "store_status":
                val.value = result.store_status;
                break;
              case "sale_no":
                val.value = result.sale_no;
                break;
              case "branch_no":
                val.value = +result.branch_no + 1;
                break;

              default:
                break;
            }
            val.disabled = false;
          });

          console.log("{ result }", result);
        } else {
          formDataDealer.map((val, i) => {
            if (val.fieldName === "store_no") {
              val.value = value;
              val.textAlert = res.message;
            } else if (
              val.fieldName === "branch_no" ||
              val.fieldName === "store_status"
            ) {
              val.value = 1;
              val.disabled = false;
            } else {
              val.value = "";
              val.disabled = false;
            }
          });
        }
        this.setState({ formDataDealer });
      } catch (error) {
        console.log(error);
      }
    }
  };

  onChangeFormDealer = (ref, fieldName, e) => {
    const { formDataDealer, formEditDealer, formFilterDealer } = this.state;
    console.log({ e });

    if (ref === "add") {
      formDataDealer.map((val, i) => {
        if (val.fieldName === fieldName) {
          if (fieldName === "store_status") {
            val.value = e ? 1 : 0;
          } else {
            val.value = e.target.value;
          }
        }
      });
    } else if (ref === "edit") {
      formEditDealer.map((val, i) => {
        if (val.fieldName === fieldName) {
          if (fieldName === "store_status") {
            val.value = e ? 1 : 0;
          } else {
            val.value = e.target.value;
          }
        }
      });
    } else {
      formFilterDealer.map((val, i) => {
        if (val.fieldName === fieldName) {
          val.value = e.target.value;
        }
      });
    }

    this.setState({ formDataDealer, formEditDealer, formFilterDealer });
  };

  clearData = () => {
    const { formDataDealer } = this.state;
    formDataDealer.map((val, i) => {
      if (val.fieldName === "store_status") {
        val.value = 1;
      } else {
        val.value = "";
        val.textAlert = "";
        if (val.fieldName !== "store_no") {
          val.disabled = true;
        }
      }
    });
    this.setState({ formDataDealer, allowClear: true });
  };

  onSaveAddDealer = async (obj) => {
    ///////////////// save Dealer/////////////////
    console.log({ obj });

    try {
      let res = await POST(FOCUS_INSERT_DEALER, obj);
      if (res.success) {
        const result = res.result.split("::");
        this.clearData();
        Modal.success({
          title: result[0],
          content: result[1],
        });
        this.setState({ openModalAdd: false }, () => this.getDataDealer());
      }
    } catch (error) {
      Modal.error({
        title: "",
        content: error.message,
      });
    }
  };

  onSaveEditDealer = async (obj) => {
    ///////////////// Edit Dealer/////////////////
    //console.log("dataEdit", obj);

    try {
      let res = await POST(FOCUS_EDIT_DEALER, obj);
      if (res.success) {
        const result = res.result;
        this.clearData();
        Modal.success({
          title: result,
          content: "",
        });
        this.setState({ openModalEdit: false }, () => this.getDataDealer());
      }
    } catch (error) {
      Modal.error({
        title: "",
        content: error.message,
      });
    }
  };

  submitFormAddDealer = async () => {
    const { formDataDealer } = this.state;
    let obj = {};

    Array.isArray(formDataDealer) &&
      formDataDealer.forEach((val) => {
        obj[val.fieldName] = val.value;
      });

    if (!obj.store_no) {
      message.error("กรุณากรอกรหัสร้านค้า");
    } else if (!obj.store_name) {
      message.error("กรุณากรอกชือร้านค้า");
    } else if (!obj.branch_name) {
      message.error("กรุณากรอกสาขา");
    } else if (!obj.store_tel) {
      message.error("กรุณากรอกเบอร์โทรศัพท์");
    } else if (!obj.sale_no) {
      message.error("กรุณากรอกรหัส Sale");
    } else if (!obj.type_store) {
      message.error("กรุณาเลือกประเภท");
    } else {
      if (
        !fn.phonNumberValidate(obj.store_tel) &&
        obj.store_tel.toString().length !== 10
      ) {
        message.error("เบอร์โทรของท่านไม่ถูกต้อง");
      } else {
        Modal.confirm({
          title: "Comfirm",
          content: "ยืนยันเพิ่ม ร้านค้า หรือไม่?",
          onOk: async () => {
            this.onSaveAddDealer(obj);
          },
        });
      }
    }
  };

  submitFormEditDealer = async () => {
    const { formEditDealer } = this.state;
    let obj = {};

    Array.isArray(formEditDealer) &&
      formEditDealer.forEach((val) => {
        obj[val.fieldName] = val.value;
      });

    if (!obj.store_tel) {
      message.error("กรุณากรอกเบอร์โทรศัพท์");
    } else if (!obj.sale_no) {
      message.error("กรุณากรอกรหัส Sale");
    } else {
      if (
        !fn.phonNumberValidate(obj.store_tel) &&
        obj.store_tel.toString().length !== 10
      ) {
        message.error("เบอร์โทรของท่านไม่ถูกต้อง");
      } else {
        Modal.confirm({
          title: "Comfirm",
          content: "ยืนยันแก้ไข ร้านค้า หรือไม่?",
          onOk: async () => {
            this.onSaveEditDealer(obj);
          },
        });
      }
    }
  };

  render() {
    const {
      dataDealer,
      openModalQR,
      valueQR,
      titleQR,
      isLoadingData,
      openModalEdit,
      openModalAdd,
      formDataDealer,
      optionTypeStore,
      allowClear,
      formFilterDealer,
    } = this.state;

    console.log({ formDataDealer, formEditDealer, formFilterDealer });
    const style = { paddingRight: "8px" };
    return (
      <div>
        <h2>{"ข้อมูลร้านค้า"}</h2>
        <Form name="basic" layout="vertical" style={{ marginTop: "1em" }}>
          <Row gutter={24}>
            <Col span={2} style={style}></Col>
            {Array.isArray(formFilterDealer) &&
              formFilterDealer.map((val, i) => {
                return (
                  <Col span={val.span} style={style} key={i}>
                    <Form.Item label={val.label} name={val.fieldName}>
                      <Input
                        size="small"
                        value={val.value}
                        onChange={(e) =>
                          this.onChangeFormDealer("filter", val.fieldName, e)
                        }
                      />
                    </Form.Item>
                  </Col>
                );
              })}
            <Col span={4} style={style}>
              <BT className="btn-filter" onClick={() => this.getDataDealer()}>
                <SearchOutlined />
                {"ค้นหา"}
              </BT>
            </Col>
            <Col span={2} style={style}></Col>
          </Row>
        </Form>

        <BT
          className="btn-add"
          onClick={() => this.setState({ openModalAdd: true })}
        >
          <PlusCircleOutlined />
          {"เพิ่มร้านค้า"}
        </BT>
        {/*###################### TABLE ######################*/}
        <Table
          columns={FocusColumnTableDealer}
          rowClassName={(record, i) => (i % 2 === 1 ? "even" : "")}
          dataSource={dataDealer}
          bordered
          pagination={{ pageSize: 15 }}
          loading={isLoadingData}
          size="small"
        />
        <ModalDownloadQR
          isModalVisible={openModalQR}
          genQR={JSON.stringify(valueQR)}
          titleQR={titleQR}
          handleCancel={() => this.setState({ openModalQR: false })}
        />
        <ModalAddDealer
          openModal={openModalAdd}
          formDataDealer={formDataDealer}
          optionTypeStore={optionTypeStore}
          allowClear={allowClear}
          onChangeForm={this.onChangeFormDealer}
          handleSelect={this.handleSelect}
          handleOnSave={this.submitFormAddDealer}
          closeModal={() =>
            this.setState({ openModalAdd: false }, this.clearData())
          }
          handleSearchStoreNo={this.handleSearchStoreNo}
        />
        <ModalEditDealer
          openModal={openModalEdit}
          formEditDealer={formEditDealer}
          onChangeForm={this.onChangeFormDealer}
          handleOnSave={this.submitFormEditDealer}
          closeModal={() => this.setState({ openModalEdit: false })}
        />
      </div>
    );
  }
}
export default ManageDealerScene;
