import React, { Component } from "react";
import { observer } from "mobx-react";
// import WrapperComponents from "../../components/hoc/WrapperComponents";
import { Table, Button as BT, Modal, Row, Col, Input, Icon, DatePicker, Select } from "antd";
// import { Label } from "../../styles/styled";
import { color } from "../../../resource";
import { UserColumnTable } from "../../../resource/static";
import {
  GET,
  POST,
  GET_ALL_CUSTOMER,
  UPDATE_CUSTOMER,
  RESET_LINE
} from '../../../service_focus'
import "react-image-lightbox/style.css";
import moment from "moment";
// import autobind from "autobind-decorator";
// const fn = require("../../functions/functions");


// @WrapperComponents()
@observer
class CustomerScene extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isLoadingData: false,
      visible: false,
      visibleActive: false,
      search: '',
      data: [],
      optionLevel: [],
      dataSet: {}
    }
  }

  componentDidMount() {
    this.onGetAll()
  }

  async onGetAll(){
    try {

      this.setState({ isLoadingData: true})

      const search = this.state.search || ""
      const res = await GET(`${GET_ALL_CUSTOMER}?search=${search}`);
      if (res.success) {
        const data = res.result.data
        this.setState({ 
          data
        })
      }
    } catch (error) {
      console.log(error)
    }
    finally{
      this.setState({ isLoadingData: false})
    }
  }

  async handleOk(){
    try {
        this.setState({ loading: true })
        const { user_id, name, telephone } = this.state.dataSet
        const obj = {
          user_id, 
          name, 
          telephone
        }
        const r = await POST(UPDATE_CUSTOMER, obj)
        const { success } = r
        if(success){
          await this.onGetAll()
          this.setState({ visible: false, loading: false })
          Modal.success({ title: "แก้ไขสำเร็จ" })
        }

    } catch (error) {
      Modal.error({ title: error.message })
    }
   
   
  }

  onReset(record){

    if(record.line_id){
        
        const { user_id } = record
        Modal.confirm({
          title: "รีเซ็ตไลน์",
          content: `👋 ยืนยันการรีเซ็ตไลน์`,
          onOk: async () => {
            this.setState({ isLoadingData: true})
            try {
                
                const r = await POST(RESET_LINE, { user_id })
                const { success } = r
                if(success){
                  await this.onGetAll()
                  Modal.success({ title: "แก้ไขสำเร็จ" })
                }

            } catch (error) {
              Modal.error({ title: error })
              this.setState({ isLoadingData: false})
            }


          }
        })
    }

  }

  onEdit(record){
    this.setState({ visible: true })
    this.setState({ dataSet: record })
  }



  onChange(e){
    this.setState({
      dataSet: {
        ...this.state.dataSet,
        [e.target.name]: e.target.value
      }
    })
  }


  render() {
    const {
      data,
      isLoadingData,
    } = this.state;

    return (
      <div>

        <Modal
            title={
              <p style={{ textAlign: "center", marginBottom: "0px" }}>
                <Icon type='user' /> แก้ไขข้อมูล
              </p>
            }
            visible={this.state.visible}
            onOk={()=> this.handleOk()}
            onCancel={()=> { this.setState({ visible: false }) }}
            width={"35vw"}
            confirmLoading={this.state.loading}
            cancelText={<font style={{ fontSize: "1vw" }}>ยกเลิก</font>}
            okText={<font style={{ fontSize: "1vw" }}>แก้ไข</font>}
          >

        <Row style={{ marginBottom: "1em" }} gutter={16}> 
          <Col span={24} >
            <font>รหัสลูกค้า</font>
            <Input
              disabled
              value={this.state.dataSet.user_id}
            />
          </Col>
          <Col span={24}>
            <font>ชื่อลูกค้า</font>
            <Input
              required
              name="name"
              value={this.state.dataSet.name}
              onChange={(e) => this.onChange(e)}
            />
          </Col>
        </Row>
  
        <Row style={{ marginBottom: "1em" }} gutter={16}>
          <Col span={24}>
            <font>เบอร์โทร</font>
            <Input 
              required
              value={this.state.dataSet.telephone}
              name="telephone"
              maxLength={10}
              onChange={(e) => this.onChange(e)}
            />
          </Col>
   
        </Row>




        </Modal>

        <div className={"column"}>
          {/*###################### HEAD ######################*/}

          <Row style={{ marginBottom: "1em" }} gutter={16}>
            <Col span={6}> 
              <Input.Search
                  placeholder="ค้นหาข้อมูลลูกค้า" 
                  name="search" 
                  onChange={e => this.setState({ search: e.target.value })}
                  onPressEnter={()=>{this.onGetAll()}}
                  onSearch={() => this.onGetAll()}
                 
                  />  
            </Col>
            <Col span={1}><BT 
                  onClick={()=>{this.onGetAll()}}
                  type="primary"
                  style={{
                    backgroundColor: color.dplus_primary,
                    borderColor: color.dplus_primary,
                  }}
                  >ค้นหา</BT></Col>
          </Row>
 

          {/*###################### TABLE ######################*/}
          <Table
            style={{ textAlign: "cenetr" }}
            title={() => <h3>ข้อมูลลูกค้า</h3>}
            columns={UserColumnTable(
              (record) => this.onReset(record),
              (record) => this.onEdit(record)
            )}
            dataSource={data}
            bordered
            pagination={{ pageSize: 10 }}
            loading={isLoadingData}
            size="small"
            scroll={{ x: true }}
          />
        </div>
      </div>
    );
  }

}

export default CustomerScene;
