import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { ip, FOCUS_GET_KERRY_PDF } from '../../../service_focus'
import { Button } from "../../../components/Button";
import { decryptParameter } from "../../../functions/functions";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const KerryBillShippingScene = () => {
    const postUrl = `${ip}${FOCUS_GET_KERRY_PDF}`
    // const postUrl = 'https://storage.googleapis.com/print-file-sit/3ac34b86ec5048aaaa68a0ee255741ad_20241010_1_1.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=GOOG1EVLMMFACU6YODNCHZ4ZNOTS57SYENRQF2YLHSNQEAFQZQP5N7RVGNOKJ%2F20241010%2FASIA-SOUTHEAST1%2Fs3%2Faws4_request&X-Amz-Date=20241010T111727Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=c39f5a54daae6ac2c473a0cd60b97cfe14b091b577887b62a2df18ab6b70f419'

    const [pdfData, setPdfData] = useState(null);
    const [isPrint, setPrint] = useState(false);
    const [numPages, setNumPages] = useState(null);

    useEffect(() => {
        const fetchPdf = async () => {
            try {
                // const { claim_id } = JSON.parse(
                //     decryptParameter(this.props.location.state.id)
                // );
                // console.log(claim_id)
                const postData = { no_claims: ["00132247"] }
                const response = await fetch(postUrl, {
                    method: 'POST',
                    responseType: 'arraybuffer',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(postData),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                // Get the PDF as a Blob
                const pdfBlob = await response.blob();

                // Create a URL for the Blob
                const url = window.URL.createObjectURL(pdfBlob);

                // Create a temporary link element
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'document.pdf');

                // Append to the document, click it, and remove it
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                // Clean up the URL object
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Error fetching the PDF:', error);
            }
        };

        fetchPdf();
    }, []);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    if (!pdfData) {
        return <div>Loading PDF...</div>;
    }

    return (
        <div>
            <div className="printable-pdf">
                <style>
                    {`
                    @media print {
                        body { margin: 0; }
                        .printable-pdf {
                            width: 4in;
                            height: 6in;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .react-pdf__Document {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                        }
                        .react-pdf__Page {
                            max-width: 100%;
                            max-height: 100%;
                            page-break-after: always;
                        }
                        .react-pdf__Page__canvas {
                            max-width: 100% !important;
                            height: auto !important;
                        }
                    }
                `}
                </style>
                {/* <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                > */}
                <Document
                    file={{ data: pdfData }}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    {Array.from(new Array(numPages), (el, index) => (
                        <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            width={384} // 4 inches * 96 DPI
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                        />
                    ))}
                </Document>
                {/* </div> */}
            </div>
            <div
                style={{
                    textAlign: "center",
                    marginTop: "1cm",
                    marginBottom: "1cm",
                }}
            >
                <Button
                    title={"ยกเลิก"}
                    buttonStyle={{ marginRight: "12px" }}
                    type={"secondary"}
                    onPress={() => this.props.history.push('/focus/claim')}
                />
                <Button
                    title={"พิมพ์"}
                    onPress={() => window.print()}
                />
            </div>
        </div>
    );
};

export default KerryBillShippingScene;